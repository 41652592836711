import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import useNewScreen from '../../../utils/hooks/useNewScreen';
import { FolderItemData, TDashboardItemsData, TFolderInnerData } from '../../../utils/Types';
import constants from '../../../constants/filters';
import { TDialogData, TDashboardItemExt } from '../types/types';
import {
  DialogItemDataTypes, CreateFolderTypes,
  DashboardItemsDialogActionTypes, CreateFolderPresetTypes
} from '../types/constants';
import { DasboardsItemsDropdownTypes, DialogItemEntityType } from '../../DashboardsItemsDropdown/types/constants';
import { DASHBOARD_ITEM_FOLDER_EXISTS } from '../../../constants/responseStatuses';
import useSaveColumns from '../../Columns/hooks/useSaveColumns';
import { resetCurrentPage } from '../../../store/tableData/slice';
import { DEFAULT_PAGINATION_STATE, SUBSCRIPTION_TYPE, TEMPLATE_TYPE } from '../../../constants/screener';
import RootStateTypes from '../../../store/RootStateTypes';
import useWatchList from '../../../utils/hooks/useWatchList';
import { setCreatedFolder } from '../../../store/newScreen/actions';
import { setModalType } from '../../../store/watchlist/actions';
import UseDashboards from '../../../pages/Dashboards/hooks/UseDashboards';
import useWorkerItems from '../../../utils/hooks/useWorkerItems';
import { WIDGET_CHARTS_REMOVE_DASHBOARD, PSC_REMOVE_DASHBOARD } from '../../../constants/storage';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';
import { LAST_STATE_KEYS } from '../../WidgetCharts/types/WidgetChartsEnums';
import { checkRoleUser } from '../../../utils/userHelper';
import { Dashboard } from '../../../pages/Dashboards/types/DashboardTypes';

interface IDashboardItemsDialogsCallback {
  (
    closeModal: () => void,
    dataFromDialog: TDialogData | null,
    setDataCb: (data: TDialogData) => void,
    setFolderId: (id: string) => void,
    currentItemHandler: (item: TDashboardItemsData) => void
  ): {
    dialogAction: (item: TDashboardItemsData, dialogData: TDialogData, folderId?: string | null) => void;
  };
}

const DashboardItemsDialogsCallback: IDashboardItemsDialogsCallback = (
  closeModal, dataFromDialog,
  setDataCb, setFolderId, currentItemHandler
) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    createDashboard,
    removeDashboard,
    renameDashboard,
    duplicateDashboard,
  } = UseDashboards();
  const { updateNestedPscActionData, updateWidgetChartsActionData } = useLastStateUpdateStore();
  const [currentCbDialog, setCurrentCbDialog] = useState<TDialogData | null>(dataFromDialog);

  const folders = useSelector((state: RootStateTypes) => state.newScreenState.folders, shallowEqual);
  const dataPanelsFolders = useSelector(
    (state: RootStateTypes) => state.newScreenState.dataPanelsFolders, shallowEqual
  );
  const foldersWatchList = useSelector((state: RootStateTypes) => state.watchlistState.folders, shallowEqual);
  const openDataPanel = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.openDataPanel, shallowEqual
  );
  const dashboardFolders = useSelector(
    (state: RootStateTypes) => state.dashboardsState.dashboardFolders, shallowEqual
  );
  const { saveColumnsToSpecificPlace } = useSaveColumns();
  const { getWatchLists } = useWatchList();
  const adminRole = checkRoleUser(SUBSCRIPTION_TYPE.ADMIN) || checkRoleUser(SUBSCRIPTION_TYPE.EDITOR);

  const {
    createFolderAsync,
    updateFolderAsync,
    removeFolder,
    removeScreen,
    duplicatePreset,
    moveToFolder,
    getItemsPreset,
    getColumnSetsItems,
    getItems,
    successHandler,
    errorHandler,
    removeSubItemFromFolder,
    updateScreen,
    getDashboardsNew,
  } = useNewScreen(currentItemHandler);

  const {
    getDataPanel,
    getDataPanelPreset,
    getDataPanelPresetFolders,
    getDataPanelFolders,
  } = useWorkerItems();

  const addFolder = (item: TFolderInnerData, type: DialogItemDataTypes, selectedTab: DasboardsItemsDropdownTypes) => {
    const folderType: string = selectedTab === DasboardsItemsDropdownTypes.PRESET && adminRole
      ? CreateFolderPresetTypes[type] : CreateFolderTypes[type];
    createFolderAsync(item?.title, folderType)
      .then((resolve) => {
        if (resolve?.data?.type === constants.presetsScreener) {
          getItemsPreset();
        } else if (resolve?.data?.type === constants.screener) {
          getItems();
        } else if (resolve?.data?.type === constants.presetDeeplist) {
          getItemsPreset(true);
        } else if (resolve?.data?.type === constants.columns) {
          getColumnSetsItems();
        } else if (resolve?.data?.type === constants.columnsPreset) {
          getColumnSetsItems(true);
        } else if (resolve?.data?.type === constants.deepList){
          getWatchLists();
        } else if (resolve?.data?.type === constants.userDashboard){
          getDashboardsNew();
        } else if (resolve?.data?.type === constants.userDashboardPresets){
          getDashboardsNew(true);
        } else if (openDataPanel){
          getDataPanel();
          getDataPanelPreset();
          getDataPanelPresetFolders();
          getDataPanelFolders();
        }
        dispatch(setCreatedFolder(resolve?.data?.id));
        successHandler(item?.title, t('folderCreated'));
        if (currentCbDialog) {
          setFolderId(resolve?.data?.id);
          setDataCb(currentCbDialog);
          dispatch(setModalType(''));
        } else {
          closeModal();
        }
      }).catch((error) => {
        if (error.response.data.message === DASHBOARD_ITEM_FOLDER_EXISTS) {
          errorHandler(item?.title);
        }
      });
  };

  const editFolder = (folder: TFolderInnerData) => {
    updateFolderAsync(folder).then((resolve) => {
      if (resolve?.data?.type === constants.presetsScreener) {
        getItemsPreset();
      } else if (resolve?.data?.type === constants.columns || resolve?.data?.type === constants.columnsPreset) {
        getColumnSetsItems();
        getColumnSetsItems(true);
      } else if (resolve?.data?.type === constants.deepList){
        getWatchLists();
        getItemsPreset(true);
      } else if (resolve?.data?.type === constants.userDashboard){
        getDashboardsNew();
        getDashboardsNew(true);
      } else if (openDataPanel){
        getDataPanel();
        getDataPanelPreset();
        getDataPanelPresetFolders();
        getDataPanelFolders();
      } else {
        getItems();
        getItemsPreset(true);
      }
      successHandler(folder?.title, t('folderUpdate'));
      closeModal();
    }).catch((error) => {
      if (error.response.data.message === DASHBOARD_ITEM_FOLDER_EXISTS) {
        errorHandler(folder?.title);
      }
    });
  };

  const actionAdd = (item: TDashboardItemsData, dialogData: TDialogData) => {
    switch (dialogData?.entityType) {
      case DialogItemEntityType.FOLDER:
        addFolder(
          item as TFolderInnerData,
          dialogData?.itemType as DialogItemDataTypes,
          dialogData?.selectedTab as DasboardsItemsDropdownTypes
        );
        break;
      case DialogItemEntityType.ITEM:
        createDashboard(item.title, !!dialogData.selectedTab);
        closeModal();
        break;
      default:
        break;
    }
  };

  const actionEdit = (item: TDashboardItemsData, dialogData: TDialogData) => {
    switch (dialogData?.entityType) {
      case DialogItemEntityType.FOLDER:
        editFolder(item as TFolderInnerData);
        break;
      case DialogItemEntityType.ITEM:
        if (dialogData?.itemType === DialogItemDataTypes.SCREENER
        || dialogData?.itemType === DialogItemDataTypes.DEEP_LIST
          || dialogData?.itemType === DialogItemDataTypes.DATA_PANEL
          || dialogData?.itemType === DialogItemDataTypes.COLUMN_SET) {
          updateScreen(item?.id, item?.title, item?.data, item?.data?.description);
          dispatch(setModalType(''));
          closeModal();
        }
        if (dialogData?.itemType === DialogItemDataTypes.DASHBOARD) {
          renameDashboard(item.title, item.id);
          dispatch(setModalType(''));
          closeModal();
        }
        break;
      default:
        break;
    }
  };

  const actionDuplicate = (item: TDashboardItemExt, cbFolderId?: string | null) => {
    const folderId = item?.folderId || cbFolderId;
    if (item.type === TEMPLATE_TYPE.DASHBOARDS) {
      duplicateDashboard(item as never as Dashboard, item.title, folderId, closeModal);
    } else {
      duplicatePreset(item?.title, item?.data, folderId, item.type, saveColumnsToSpecificPlace, closeModal);
      dispatch(resetCurrentPage(DEFAULT_PAGINATION_STATE));
    }
  };

  const actionRemove = (item: TDashboardItemsData, dialogData: TDialogData) => {
    switch (dialogData?.entityType) {
      case DialogItemEntityType.FOLDER: {
        dispatch(setModalType(''));
        removeFolder(item?.id, item?.title);
        break;
      }
      default:
      {
        dispatch(setModalType(''));
        if (dialogData?.itemType === DialogItemDataTypes.DASHBOARD) {
          updateNestedPscActionData(PSC_REMOVE_DASHBOARD, {}, item.id);
          updateWidgetChartsActionData(WIDGET_CHARTS_REMOVE_DASHBOARD, {}, item.id, LAST_STATE_KEYS.performance);
          updateWidgetChartsActionData(WIDGET_CHARTS_REMOVE_DASHBOARD, {}, item.id, LAST_STATE_KEYS.heatmap);
          updateWidgetChartsActionData(WIDGET_CHARTS_REMOVE_DASHBOARD, {}, item.id, LAST_STATE_KEYS.bubble);
          removeDashboard(item.id);
          closeModal();
          break;
        } else removeScreen(item?.id, item?.title, dialogData.itemType);
      }
    }
    closeModal();
  };

  const actionMoveTo = (item: TDashboardItemExt) => {
    let moveToFolders;
    switch (item?.type) {
      case TEMPLATE_TYPE.WATCHLIST: {
        moveToFolders = foldersWatchList;
        break;
      }
      case TEMPLATE_TYPE.SCREENER: {
        moveToFolders = folders;
        break;
      }
      case TEMPLATE_TYPE.DATA_PANEL: {
        moveToFolders = dataPanelsFolders;
        break;
      }
      case TEMPLATE_TYPE.DASHBOARDS: {
        moveToFolders = dashboardFolders;
        break;
      }
      default: {
        moveToFolders = folders;
        break;
      }
    }
    const folder = moveToFolders.find(
      (f: FolderItemData) => f.id.toString() === item?.folderId
    ) as never as TFolderInnerData;
    const details = { folder: folder?.title, screen: item.title };
    moveToFolder(item?.id, item?.folderId, details, false);
    closeModal();
  };

  const actionRemoveFromFolder = (item: TDashboardItemsData) => {
    const details = { folderName: item?.folder?.name, subItem: item?.title };
    removeSubItemFromFolder(item?.id, details);
    closeModal();
  };

  const dialogAction = (item: TDashboardItemsData, dialogData: TDialogData, folderId? : string | null) => {
    switch (dialogData?.type) {
      case DashboardItemsDialogActionTypes.EDIT:
        actionEdit(item, dialogData);
        break;
      case DashboardItemsDialogActionTypes.DELETE:
        actionRemove(item, dialogData);
        break;
      case DashboardItemsDialogActionTypes.DUPLICATE:
        actionDuplicate(item, folderId);
        break;
      case DashboardItemsDialogActionTypes.REMOVE:
        actionRemoveFromFolder(item);
        break;
      case DashboardItemsDialogActionTypes.MOVE_TO:
        actionMoveTo(item);
        break;
      default:
        actionAdd(item, dialogData);
    }
  };

  useEffect(() => {
    setCurrentCbDialog(dataFromDialog);
  }, [dataFromDialog]);

  return {
    dialogAction,
  };
};

export default DashboardItemsDialogsCallback;
