import getInputArray from '../utilities/getInputArrayTs';
import { CustomIndicator, IPineStudyResult, LibraryPineStudy, PineJS, RawStudyMetaInfoId, StudyInputType, StudyPlotType, StudyShapesPlotPreferences, StudyStylesInfo } from '../../charting_library/charting_library';
const STRATEGY_OPTIONS = {
  COMBINATION_OF_SIGNALS: 'Combination of Signals',
  INDIVIDUAL_SIGNALS: 'Individual Signals'
};

export default function marketNavigator(mPineJS: PineJS): CustomIndicator {
  return {
    name: 'DV - Market Navigator',
    metainfo: {
      id: 'DV-marketnavigator@tv-basicstudies-1' as RawStudyMetaInfoId,
      shortDescription: 'DV - Market Navigator',
      description: 'DV - Market Navigator',
      is_price_study: true,
      isCustomIndicator: true,
      is_hidden_study: false,
      format: {
        type: 'inherit'
      },
      styles: {
        plot_0: {
          isHidden: false,
          size: 'tiny' as StudyStylesInfo['size'],
          title: 'Buy Signal'
        },
        plot_1: {
          isHidden: false,
          size: 'tiny' as StudyStylesInfo['size'],
          title: 'Sell Signal'
        }
      },
      inputs: [
        {
          defval: STRATEGY_OPTIONS.COMBINATION_OF_SIGNALS,
          id: 'strategyOption',
          name: 'Signal Options',
          options: [
            STRATEGY_OPTIONS.COMBINATION_OF_SIGNALS,
            STRATEGY_OPTIONS.INDIVIDUAL_SIGNALS
          ],
          type: 'text' as StudyInputType.Text,
        },
        {
          defval: 8,
          id: 'buyScoreThreshold',
          max: 10,
          min: 1,
          name: 'Count of Buy Signals to Display Signal',
          type: 'integer' as StudyInputType.Integer
        },
        {
          defval: 10,
          id: 'sellScoreThreshold',
          max: 14,
          min: 1,
          name: 'Count of Sell Signals to Display Signal',
          type: 'integer' as StudyInputType.Integer
        },
        {
          defval: false,
          id: 'buySignal1Enabled',
          name: 'Buy Signal 1 (Cross Above 21 EMA)',
          type: 'bool' as StudyInputType.Bool,
        },
        {
          defval: false,
          id: 'buySignal2Enabled',
          name: 'Buy Signal 2 (Trending Above 21 EMA)',
          type: 'bool' as StudyInputType.Bool,
        },
        {
          defval: false,
          id: 'buySignal3Enabled',
          name: 'Buy Signal 3 (Living Above 21 EMA)',
          type: 'bool' as StudyInputType.Bool,
        },
        {
          defval: false,
          id: 'buySignal4Enabled',
          name: 'Buy Signal 4 (Cross Above 50 SMA)',
          type: 'bool' as StudyInputType.Bool,
        },
        {
          defval: false,
          id: 'buySignal5Enabled',
          name: 'Buy Signal 5 (Cross Above 200 SMA)',
          type: 'bool' as StudyInputType.Bool,
        },
        {
          defval: false,
          id: 'buySignal6Enabled',
          name: 'Buy Signal 6 (Low Above 50 SMA)',
          type: 'bool' as StudyInputType.Bool,
        },
        {
          defval: false,
          id: 'buySignal7Enabled',
          name: 'Buy Signal 7 (Accumulation Day)',
          type: 'bool' as StudyInputType.Bool
        },
        {
          defval: false,
          id: 'buySignal8Enabled',
          name: 'Buy Signal 8 (Higher High)',
          type: 'bool' as StudyInputType.Bool
        },
        {
          defval: false,
          id: 'buySignal9Enabled',
          name: 'Buy Signal 9 (Key Reversal Bar)',
          type: 'bool' as StudyInputType.Bool
        },
        {
          defval: false,
          id: 'buySignal10Enabled',
          name: 'Buy Signal 10 (Distribution Day Fall Off)',
          type: 'bool' as StudyInputType.Bool
        },
        {
          defval: false,
          id: 'sellSignal1Enabled',
          name: 'Sell Signal 1 (Cross Below 21 EMA)',
          type: 'bool' as StudyInputType.Bool
        },
        {
          defval: false,
          id: 'sellSignal2Enabled',
          name: 'Sell Signal 2 (Trending Below 21 EMA)',
          type: 'bool' as StudyInputType.Bool
        },
        {
          defval: false,
          id: 'sellSignal3Enabled',
          name: 'Sell Signal 3 (Living Below 21 EMA)',
          type: 'bool' as StudyInputType.Bool
        },
        {
          defval: false,
          id: 'sellSignal4Enabled',
          name: 'Sell Signal 4 (Cross Below 50 SMA)',
          type: 'bool' as StudyInputType.Bool
        },
        {
          defval: false,
          id: 'sellSignal5Enabled',
          name: 'Sell Signal 5 (Cross Below 200 SMA)',
          type: 'bool' as StudyInputType.Bool
        },
        {
          defval: false,
          id: 'sellSignal6Enabled',
          name: 'Sell Signal 6 (High Below 50 SMA)',
          type: 'bool' as StudyInputType.Bool
        },
        {
          defval: false,
          id: 'sellSignal7Enabled',
          name: 'Sell Signal 7 (Distribution Day)',
          type: 'bool' as StudyInputType.Bool
        },
        {
          defval: false,
          id: 'sellSignal8Enabled',
          name: 'Sell Signal 8 (Lower Low)',
          type: 'bool' as StudyInputType.Bool
        },
        {
          defval: false,
          id: 'sellSignal9Enabled',
          name: 'Sell Signal 9 (Downside Reversal Bar)',
          type: 'bool' as StudyInputType.Bool
        },
        {
          defval: false,
          id: 'sellSignal10Enabled',
          name: 'Sell Signal 10 (Distribution Cluster)',
          type: 'bool' as StudyInputType.Bool
        },
      ],
      plots: [
        {
          id: 'plot_0',
          type: 'shapes' as StudyPlotType.Shapes
        },
        {
          id: 'plot_1',
          type: 'shapes' as StudyPlotType.Shapes
        }
      ],
      defaults: {
        inputs: {
          strategyOption: STRATEGY_OPTIONS.COMBINATION_OF_SIGNALS,
          buyScoreThreshold: 8,
          sellScoreThreshold: 8,
          buySignal1Enabled: false,
          buySignal2Enabled: false,
          buySignal3Enabled: false,
          buySignal4Enabled: false,
          buySignal5Enabled: false,
          buySignal6Enabled: false,
          buySignal7Enabled: false,
          buySignal8Enabled: false,
          buySignal9Enabled: false,
          buySignal10Enabled: false,
          sellSignal1Enabled: false,
          sellSignal2Enabled: false,
          sellSignal3Enabled: false,
          sellSignal4Enabled: false,
          sellSignal5Enabled: false,
          sellSignal6Enabled: false,
          sellSignal7Enabled: false,
          sellSignal8Enabled: false,
          sellSignal9Enabled: false,
          sellSignal10Enabled: false,
        },
        styles: {
          plot_0: {
            color: '#4CAF50',
            display: 15,
            location: 'BelowBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_triangle_up',
            textColor: '#2962ff',
            transparency: 0
          },
          plot_1: {
            color: '#FF5252',
            display: 15,
            location: 'AboveBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_triangle_down',
            textColor: '#2962ff',
            transparency: 0
          }
        },
      }
    },
    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = (context) => {
        this._context = context;
        this.daysCloseAboveEMA21 = 0
        this.daysEMA21AboveClose = 0
      };
      this.main = (context, inputs) => {
        this._context = context;

        const [strategyOption, buyScoreThreshold, sellScoreThreshold, buySignal1Enabled, buySignal2Enabled, buySignal3Enabled, buySignal4Enabled,
          buySignal5Enabled, buySignal6Enabled, buySignal7Enabled, buySignal8Enabled, buySignal9Enabled, buySignal10Enabled, sellSignal1Enabled,
          sellSignal2Enabled, sellSignal3Enabled, sellSignal4Enabled, sellSignal5Enabled, sellSignal6Enabled, sellSignal7Enabled, sellSignal8Enabled,
          sellSignal9Enabled, sellSignal10Enabled
        ] = getInputArray({
          inputs,
          length: 23
        });

        const closeSeries = context.new_unlimited_var(mPineJS.Std.close(this._context));
        const volumeSeries = context.new_unlimited_var(mPineJS.Std.volume(this._context));
        const lowSeries = context.new_unlimited_var(mPineJS.Std.low(this._context));
        const highSeries = context.new_unlimited_var(mPineJS.Std.high(this._context));

        const isDistributionDay = (mPineJS.Std.close(this._context) - mPineJS.Std.low(this._context)) / (mPineJS.Std.high(this._context) - mPineJS.Std.low(this._context)) < 0.5 && volumeSeries.get(0) > volumeSeries.get(1) ? 1 : 0;
        const distributionSeries = context.new_unlimited_var(isDistributionDay);

        let distributionCount = 0;
        for (let i = 0; i <= 6; i++) {
          distributionCount = distributionCount + (distributionSeries.get(i) === 1 ? 1 : 0);
        }
        const ema21 = mPineJS.Std.ema(closeSeries, 21, this._context);
        const sma50 = mPineJS.Std.sma(closeSeries, 50, this._context);
        const sma200 = mPineJS.Std.sma(closeSeries, 200, this._context);

        const ema21Series = context.new_unlimited_var(ema21);

        const highestHigh15 = mPineJS.Std.highest(this._context.new_unlimited_var(mPineJS.Std.high(this._context)), 15, this._context);
        const lowestLow15 = mPineJS.Std.lowest(this._context.new_unlimited_var(mPineJS.Std.low(this._context)), 15, this._context);

        this.daysCloseAboveEMA21 = closeSeries.get(0) > ema21 ? this.daysCloseAboveEMA21 + 1 : 0;
        this.daysEMA21AboveClose = closeSeries.get(0) < ema21 ? this.daysEMA21AboveClose + 1 : 0;

        const buySignal1 = (strategyOption === STRATEGY_OPTIONS.INDIVIDUAL_SIGNALS ? buySignal1Enabled : true) && closeSeries.get(0) > ema21Series.get(0) && closeSeries.get(1) < ema21Series.get(1) ? 1 : 0;
        const buySignal2 = (strategyOption === STRATEGY_OPTIONS.INDIVIDUAL_SIGNALS ? buySignal2Enabled : true) && this.daysCloseAboveEMA21 >= 5 ? 1 : 0;
        const buySignal3 = (strategyOption === STRATEGY_OPTIONS.INDIVIDUAL_SIGNALS ? buySignal3Enabled : true) && this.daysCloseAboveEMA21 >= 15 ? 1 : 0;
        const buySignal4 = (strategyOption === STRATEGY_OPTIONS.INDIVIDUAL_SIGNALS ? buySignal4Enabled : true) && closeSeries.get(0) > sma50 && closeSeries.get(1) < sma50 ? 1 : 0;
        const buySignal5 = (strategyOption === STRATEGY_OPTIONS.INDIVIDUAL_SIGNALS ? buySignal5Enabled : true) && closeSeries.get(0) > sma200 && closeSeries.get(1) < sma200 ? 1 : 0;
        const buySignal6 = (strategyOption === STRATEGY_OPTIONS.INDIVIDUAL_SIGNALS ? buySignal6Enabled : true) && mPineJS.Std.low(this._context) > sma50 ? 1 : 0;
        const buySignal7 = (strategyOption === STRATEGY_OPTIONS.INDIVIDUAL_SIGNALS ? buySignal7Enabled : true) && (mPineJS.Std.close(this._context) - mPineJS.Std.low(this._context)) / (mPineJS.Std.high(this._context) - mPineJS.Std.low(this._context)) > 0.5 && volumeSeries.get(0) > volumeSeries.get(1) ? 1 : 0;
        const buySignal8 = (strategyOption === STRATEGY_OPTIONS.INDIVIDUAL_SIGNALS ? buySignal8Enabled : true) && mPineJS.Std.high(this._context) >= highestHigh15 ? 1 : 0;
        const buySignal9 = (strategyOption === STRATEGY_OPTIONS.INDIVIDUAL_SIGNALS ? buySignal9Enabled : true) && mPineJS.Std.open(this._context) < lowSeries.get(1) && mPineJS.Std.close(this._context) > highSeries.get(1) ? 1 : 0;
        const buySignal10 = (strategyOption === STRATEGY_OPTIONS.INDIVIDUAL_SIGNALS ? buySignal10Enabled : true) && volumeSeries.get(26) < volumeSeries.get(25) && ((closeSeries.get(25) - lowSeries.get(25)) / (highSeries.get(25) - lowSeries.get(25))) < 0.5 ? 1 : 0;

        const sellSignal1 = (strategyOption === STRATEGY_OPTIONS.INDIVIDUAL_SIGNALS ? sellSignal1Enabled : true) && closeSeries.get(0) < ema21Series.get(0) && closeSeries.get(1) > ema21Series.get(1) ? 1 : 0;
        const sellSignal2 = (strategyOption === STRATEGY_OPTIONS.INDIVIDUAL_SIGNALS ? sellSignal2Enabled : true) && this.daysEMA21AboveClose >= 5 ? 1 : 0;
        const sellSignal3 = (strategyOption === STRATEGY_OPTIONS.INDIVIDUAL_SIGNALS ? sellSignal3Enabled : true) && this.daysEMA21AboveClose >= 15 ? 1 : 0;
        const sellSignal4 = (strategyOption === STRATEGY_OPTIONS.INDIVIDUAL_SIGNALS ? sellSignal4Enabled : true) && closeSeries.get(0) < sma50 && closeSeries.get(1) > sma50 ? 1 : 0;
        const sellSignal5 = (strategyOption === STRATEGY_OPTIONS.INDIVIDUAL_SIGNALS ? sellSignal5Enabled : true) && closeSeries.get(0) < sma200 && closeSeries.get(1) > sma200 ? 1 : 0;
        const sellSignal6 = (strategyOption === STRATEGY_OPTIONS.INDIVIDUAL_SIGNALS ? sellSignal6Enabled : true) && mPineJS.Std.high(this._context) < sma50 ? 1 : 0;
        const sellSignal7 = (strategyOption === STRATEGY_OPTIONS.INDIVIDUAL_SIGNALS ? sellSignal7Enabled : true) && (mPineJS.Std.close(this._context) - mPineJS.Std.low(this._context)) / (mPineJS.Std.high(this._context) - mPineJS.Std.low(this._context)) < 0.5 && volumeSeries.get(0) > volumeSeries.get(1) ? 1 : 0;
        const sellSignal8 = (strategyOption === STRATEGY_OPTIONS.INDIVIDUAL_SIGNALS ? sellSignal8Enabled : true) && mPineJS.Std.low(this._context) <= lowestLow15 ? 1 : 0;
        const sellSignal9 = (strategyOption === STRATEGY_OPTIONS.INDIVIDUAL_SIGNALS ? sellSignal9Enabled : true) && mPineJS.Std.open(this._context) > highSeries.get(1) && mPineJS.Std.close(this._context) < lowSeries.get(1) ? 1 : 0;
        const sellSignal10 = (strategyOption === STRATEGY_OPTIONS.INDIVIDUAL_SIGNALS ? sellSignal10Enabled : true) && distributionCount >= 3 ? 1 : 0;

        const buySignals = [buySignal1, buySignal2, buySignal3, buySignal4, buySignal5, buySignal6, buySignal7, buySignal8, buySignal9, buySignal10];
        const buyScore = buySignals.reduce((total, signal) => total + signal, 0);

        const sellSignals = [sellSignal1, sellSignal2, sellSignal3, sellSignal4, sellSignal5, sellSignal6, sellSignal7, sellSignal8, sellSignal9, sellSignal10];
        const sellScore = sellSignals.reduce((total, signal) => total + signal, 0);

        const buyCondition = strategyOption === STRATEGY_OPTIONS.COMBINATION_OF_SIGNALS
          ? (buyScore >= buyScoreThreshold)
          : (buySignal1 || buySignal2 || buySignal3 || buySignal4 || buySignal5 || buySignal6 || buySignal7 || buySignal8 || buySignal9 || buySignal10);

        const sellCondition = strategyOption == STRATEGY_OPTIONS.COMBINATION_OF_SIGNALS
          ? (sellScore >= sellScoreThreshold)
          : (sellSignal1 || sellSignal2 || sellSignal3 || sellSignal4 || sellSignal5 || sellSignal6 || sellSignal7 || sellSignal8 || sellSignal9 || sellSignal10)

        return [
          buyCondition ? 1 : NaN,
          sellCondition ? 1 : NaN
        ];
      };
    }
  };
}
