import { IContext, IPineSeries, PineJSStd } from "../../../charting_library/charting_library";

export function computePowerOfThree(open: number, close: number, closeSeries: IPineSeries, mPineJSStd: PineJSStd, mcontext: IContext) {

  const sma10 = mPineJSStd.sma(closeSeries, 10, mcontext);
  const sma21 = mPineJSStd.sma(closeSeries, 21, mcontext);
  const sma50 = mPineJSStd.sma(closeSeries, 50, mcontext);

  return open < Math.min(sma10, sma21, sma50) && close > Math.max(sma10, sma21, sma50);
}
