import moment from 'moment/moment';
import {
  BORDER_SETTINGS, PERIOD_SOURCES, REVISION, SOURCES,
  dataTitles
} from '../constants/statsChart';
import { ONE_HUNDRED, ONE_THOUSAND, TABLE_ITEM_SETTINGS } from '../constants/screener';
import { DEFAULT_TIMEZONE } from '../constants/account';
import { formatterString, formatter } from './convertingDataHelper';
import { MULTIPLY_PERCENT } from '../constants/numberConstants';
import { setBorderSettings, setMergeTimestampAction } from '../store/statsChart/actions';

const applyColor = (data, percent) => {
  const currentData = percent ? data.surprisePercent : data.surpriseValue;
  return currentData < 0 ? '#F55BAE' : '#3A75E8';
};

const estimateHandler = (data) => {
  return data.estimatedValue !== null ? `$${formatter(data.estimatedValue)}` : TABLE_ITEM_SETTINGS.VALUE_EMPTY;
};

const popUpDataHandler = (current, data, currentSource) => {
  const percentIsNull = data.surprisePercent !== null;
  const valueIsNull = data.surpriseValue !== null;

  return {
    actual: currentSource ? `$${formatterString(current)}` : '-',
    estimates: currentSource ? estimateHandler(data) : `$${formatterString(current)}`,
    surprisePercent: percentIsNull
      ? `${Math.round(data.surprisePercent * MULTIPLY_PERCENT)}%` : TABLE_ITEM_SETTINGS.VALUE_EMPTY,
    surpriseValue: valueIsNull
      ? `$${formatterString(data.surpriseValue)}` : TABLE_ITEM_SETTINGS.VALUE_EMPTY,
    percentColor: percentIsNull ? applyColor(data, true) : '',
    valueColor: valueIsNull ? applyColor(data, false) : ''
  };
};

export const currentBorderHandler = (borderSettings, index) => {
  if (borderSettings.negative.includes(index)) {
    return BORDER_SETTINGS.BOLD_RED;
  }

  if (borderSettings.positive.includes(index)) {
    return BORDER_SETTINGS.BOLD_BLUE;
  }

  return BORDER_SETTINGS.GRAY;
};

export const gapBorderHandler = (data, borderSettings, index) => {
  if (borderSettings.halfNegativePositive.includes(index) || borderSettings.halfPositiveNegative.includes(index)) {
    return '';
  }
  if (borderSettings.positive.includes(index) && (data[index + 1] && data[index].Earnings > data[index + 1].Earnings)) {
    return BORDER_SETTINGS.GAP;
  }
  if (borderSettings.negative.includes(index) && (data[index + 1] && data[index].Earnings < data[index + 1].Earnings)) {
    return BORDER_SETTINGS.GAP;
  }

  return '';
};

export const setCurrentColorState = (filterItem, tableBorderSettings, setColors) => {
  if (filterItem === dataTitles.Earnings) {
    setColors({
      negative: tableBorderSettings.epsNegative,
      positive: tableBorderSettings.epsPositive,
      halfPositiveNegative: tableBorderSettings.epsHalfPositiveNegative,
      halfNegativePositive: tableBorderSettings.epsHalfNegativePositive,
    });
  } else {
    setColors({
      negative: tableBorderSettings.salesNegative,
      positive: tableBorderSettings.salesPositive,
      halfPositiveNegative: tableBorderSettings.salesHalfPositiveNegative,
      halfNegativePositive: tableBorderSettings.salesHalfNegativePositive,
    });
  }
};

const currentRevisionState = (data) => {
  if ((data?.downRevisions < data?.upRevisions) || (data?.upRevisions && !data?.downRevisions)) {
    return REVISION.POSITIVE;
  }
  if ((data?.downRevisions > data?.upRevisions) || (data?.downRevisions && !data?.upRevisions)) {
    return REVISION.NEGATIVE;
  }

  return undefined;
};

const prepareDataHandler = (data, dispatch, periodSource, t) => {
  const borderColors = {
    salesPositive: [],
    salesNegative: [],
    salesHalfPositiveNegative: [],
    salesHalfNegativePositive: [],
    epsPositive: [],
    epsNegative: [],
    epsHalfPositiveNegative: [],
    epsHalfNegativePositive: [],
  };

  const result = data.reduce((acc, curr, index, array) => {
    const {
      calendarDate, source, eps, sales, marketTime, reportDate, fiscalDate
    } = curr;
    const [salesLineData, growsLineData] = acc[0];
    const [epsTableData, salesTableData] = acc[1];

    const date = moment(calendarDate * ONE_THOUSAND);
    const currentSource = source === SOURCES.HISTORY;
    const isQuarter = periodSource === PERIOD_SOURCES.SHORT_Q;
    const currentReportDate = reportDate || fiscalDate;

    const formattedQuarter = isQuarter ? `${date.year()}: ${t('quarterShort')}${date.quarter()}` : `${date.year()}`;

    const epsValue = eps.value || 0;
    const salesValue = sales.value || 0;
    const epsGrows = eps.growth * ONE_HUNDRED || 0;
    const salesGrows = sales.growth * ONE_HUNDRED || 0;

    const mergeLine = source === SOURCES.ESTIMATE && array[index - 1] && array[index - 1]?.source === SOURCES.HISTORY;

    salesLineData.push(!mergeLine ? {
      quarter: formattedQuarter,
      EarningsFuture: !currentSource ? epsValue : null,
      SalesFuture: !currentSource ? salesValue : null,
      Sales: currentSource ? salesValue : null,
      Earnings: currentSource ? epsValue : null,
    } : {
      quarter: formattedQuarter,
      EarningsFuture: epsValue,
      SalesFuture: salesValue,
      Sales: salesValue,
      Earnings: epsValue,
    });

    growsLineData.push(!mergeLine ? {
      quarter: formattedQuarter,
      EarningsFuture: !currentSource ? epsGrows : null,
      SalesFuture: !currentSource ? salesGrows : null,
      Sales: currentSource ? salesGrows : null,
      Earnings: currentSource ? epsGrows : null,
    } : {
      quarter: formattedQuarter,
      EarningsFuture: epsGrows,
      SalesFuture: salesGrows,
      Sales: salesGrows,
      Earnings: epsGrows,
    });

    epsTableData.push({
      quarter: formattedQuarter,
      Earnings: epsGrows,
      Sales: epsValue,
      Row: dataTitles.Earnings,
      estimate: source,
      timestamp: calendarDate * ONE_THOUSAND,
      popUpData: popUpDataHandler(epsValue, eps, currentSource),
      revisionState: currentRevisionState(eps),
      marketTime,
      reportDate: moment(currentReportDate * ONE_THOUSAND).tz(DEFAULT_TIMEZONE).format('MMM D, YYYY')
    });

    salesTableData.push({
      quarter: formattedQuarter,
      Earnings: salesGrows,
      Sales: salesValue,
      Row: dataTitles.Sales,
      estimate: source,
      timestamp: calendarDate * ONE_THOUSAND,
      popUpData: popUpDataHandler(salesValue, sales, currentSource),
      revisionState: currentRevisionState(sales),
      marketTime,
      reportDate: moment(currentReportDate * ONE_THOUSAND).tz(DEFAULT_TIMEZONE).format('MMM D, YYYY')
    });

    if (source === SOURCES.ESTIMATE && array[index - 1]?.source === SOURCES.HISTORY) {
      dispatch(setMergeTimestampAction(calendarDate * ONE_THOUSAND));
    }

    return acc;
  }, [[[], []], [[], []]]);

  const setBorderColorHandler = (field, index) => {
    for (let i = 1; i <= 3; i += 1) {
      borderColors[field].push(index + i);
    }
  };

  result[1][0].forEach((item, index) => {
    const currentItem = item.Earnings;
    const prevItem = result[1][0][index - 1]?.Earnings;
    const nextBeforePrevItem = result[1][0][index - 2]?.Earnings;
    const twiceNextBeforePrevItem = result[1][0][index - 3]?.Earnings;
    const nextItem = result[1][0][index + 1]?.Earnings;
    const nextAfterNext = result[1][0][index + 2]?.Earnings;
    const twiceNextAfterNext = result[1][0][index + 3]?.Earnings;

    if (
      nextItem < currentItem
        && nextAfterNext < nextItem
        && currentItem > prevItem
        && twiceNextAfterNext < nextAfterNext
        && prevItem > nextBeforePrevItem
        && nextBeforePrevItem > twiceNextBeforePrevItem
    ) {
      borderColors.epsHalfPositiveNegative.push(index);
    }

    if (nextItem > currentItem && nextAfterNext > nextItem && twiceNextAfterNext > nextAfterNext) {
      setBorderColorHandler('epsPositive', index);
    }

    if (nextItem < currentItem && nextAfterNext < nextItem && twiceNextAfterNext < nextAfterNext) {
      setBorderColorHandler('epsNegative', index);
    }
  });

  result[1][1].forEach((item, index) => {
    const currentItem = item.Earnings;
    const prevItem = result[1][1][index - 1]?.Earnings;
    const nextBeforePrevItem = result[1][1][index - 2]?.Earnings;
    const twiceNextBeforePrevItem = result[1][1][index - 3]?.Earnings;
    const nextItem = result[1][1][index + 1]?.Earnings;
    const nextAfterNext = result[1][1][index + 2]?.Earnings;
    const twiceNextAfterNext = result[1][1][index + 3]?.Earnings;

    if (
      nextItem < currentItem
        && nextAfterNext < nextItem
        && currentItem > prevItem
        && twiceNextAfterNext < nextAfterNext
        && prevItem > nextBeforePrevItem
        && nextBeforePrevItem > twiceNextBeforePrevItem
    ) {
      borderColors.salesHalfPositiveNegative.push(index);
    }

    if (
      nextItem > currentItem
        && nextAfterNext > nextItem
        && twiceNextAfterNext > nextAfterNext
        && currentItem < prevItem
        && prevItem < nextBeforePrevItem
        && nextBeforePrevItem < twiceNextBeforePrevItem
    ) {
      borderColors.salesHalfNegativePositive.push(index);
    }

    if (nextItem > currentItem && nextAfterNext > nextItem && twiceNextAfterNext > nextAfterNext) {
      setBorderColorHandler('salesPositive', index);
    }

    if (nextItem < currentItem && nextAfterNext < nextItem && twiceNextAfterNext < nextAfterNext) {
      setBorderColorHandler('salesNegative', index);
    }
  });
  dispatch(setBorderSettings(borderColors));
  return { result, borderColors };
};

export default prepareDataHandler;
