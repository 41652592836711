import {
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { debounce } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  DeepListItemData,
  ScreenerColumnData,
  ScreenerRowData,
  ScreenItemData,
  SortDataType,
  TAdditionalDataForScreener,
  TVolumeBarsData
} from '../../../utils/Types';
import { HOLDING_TYPE } from '../../Dashboards/types/DashboardEnums';
import {
  checkIsDefaultColumnSet,
  checkIsParentColumnSet,
  cleanOldDataDeeplist,
  compareAndCleanSelections,
  updateDeepListData,
  updateScreenerData
} from '../../../components/DasboardComponents/utils/utils';
import RootStateTypes from '../../../store/RootStateTypes';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';
import {
  DEFAULT_COLUMNS_INDEX,
  defaultPage,
  FIXED_COLUMNS,
  holdingsIndex,
  REVERSE_SORTING_COLUMNS,
  TABLE_SCREEN_PATH,
  TEMPLATE_TYPE
} from '../../../constants/screener';
import SocketContext from '../../../context/SocketContext';
import {
  RowItem,
  ScreenerQueryMessage,
  ScreenerQueryMessageRange,
  SocketResponse,
  SocketScreenerAllResponse
} from '../../../services/Interfaces';
import {
  compareColumnArray,
  compareDataArray,
  getDividentAndEarnings,
  setSortArray as sortHelper,
  validationFilterMarket,
  workerWithScreenerData
} from '../../../utils/helpers';
import { WS_CHANNELS, WS_PING_TYPES } from '../../../constants/socketConnection';
import { repeatItems } from '../../../utils/hooks/useRepeat';
import { messageIdGenerator } from '../../../components/ChartContainer/utils/chartingHelper';
import usePrepareLastSymbolState from '../../../utils/hooks/usePrepareLastSymbolState';
import {
  setCounterSymbols,
  setDataColumns,
  setDataRowsIndexes,
  setIndustryOfSymbols,
  setQueryScreenerId
} from '../../../store/tableData/slice';
import { IColumnItem, ISortItem } from '../../../components/Sorting/types/interfaces';
import useSelection from '../../../utils/hooks/useSelection';
import { ShortSymbol } from '../DeeplistUtils/types/AlertTypes';
import {
  setETFSymbolIndex,
  setSearchHeaderValue,
  setSearchValue,
  setVolumeDayHistory
} from '../../../store/screener/actions';
import { UNIVERSE_TYPE } from '../../../constants/watchlist';
import AccountStateType from '../../../store/account/AccountStateType';
import useSaveSort from '../../../components/Sorting/hooks/useSaveSort';
import { setGlobalHoldingSearch, setGlobalSectorIndustrySearch } from '../../../store/dashboards/slice';
import { setSortAlertArray } from '../../../components/ChartContainer/utils/alertHelper';

const screenerMessageId = messageIdGenerator();
interface IUseMainTableWorker {
  (
    clearSelections: () => void,
    isAlertTable: boolean,
    activeSymbolIndexValue: number | undefined,
    alertFilters: number[][][][] | undefined
  ): {
    tableData: ScreenerRowData[] | null,
    columnsData: ScreenerColumnData[] | null,
    additionalData: TAdditionalDataForScreener | null,
    volumeBarsData: TVolumeBarsData | null,
    setVisibleItemsTable: (visibleItems: number[]) => void,
    sortHandler: (index: number) => void,
    sortStatus: (index: number) => void,
    clearSortingHandler: () => void,
    sortData: SortDataType[] | null,
    setNewSortingData: (sortData: SortDataType[]) => void;
    loaderStatus: boolean,
    setLoader: (status: boolean) => void,
    setOpenETF: (symbol: ShortSymbol) => void,
    sectorIndustryWidgetHandler: (index: number, itemTitle: string) => void,
    setTopScreenerIndex: (index: number) => void,
    setItems: () => void,
    handleSetAllItems: () => void,
    isHoldingView: boolean,
  }
}

interface IInitSorting {
  (
    items: (DeepListItemData | ScreenItemData)[],
    userSettings: AccountStateType['userSettings'],
    setSortHandler: (value: SortDataType[] | []) => void,
    currentPage: string,
    initItem: DeepListItemData | ScreenItemData
  ): void
}

const useMainTableWorker: IUseMainTableWorker = (
  clearSelections,
  isAlertTable,
  activeSymbolIndexValue,
  alertFilters
) => {
  const dispatch = useDispatch();

  const queryScreenerRef = useRef<number | null>(null);
  const socketIdRef = useRef<string | null | undefined>(null);
  const visibleItemsTableRef = useRef<number[] | null>(defaultPage);
  const filtersRef = useRef<number[][][][]>([]);
  const sortArrayRef = useRef<SortDataType[] | null>(null);
  const columnsListRef = useRef<number[]>([]);
  const columnsIndexesRef = useRef<number[]>([]);
  const tableDataRef = useRef<ScreenerRowData[]>([]);
  const columnsRenderRef = useRef<ScreenerColumnData[]>([]);
  const globalHoldingSearchRef = useRef<boolean | null>(null);
  const topIndexRef = useRef<number>(0);
  const selectedTabRef = useRef<DeepListItemData | null>(null);
  const selectedScreenRef = useRef<ScreenItemData | null>(null);

  // request data
  const [filtersData, setFiltersData] = useState<number[][][][] | null>(null);
  const [sortArray, setSortArray] = useState<SortDataType[] | null>(null);
  const [selectedColumns, setSelectedColumns] = useState<number[] | null>(null);
  const [etfSymbolIndex, setEtfSymbolIndex] = useState<number>(-1);
  const [visibleItemsTable, setVisibleItemsTable] = useState<number[]>(defaultPage);

  // response data
  const [tableData, setTableData] = useState<RowItem[]>([]);
  const [columnsData, setColumnsData] = useState<ScreenerColumnData[]>([]);
  const [tableIndexesData, setTableIndexesData] = useState<number[]>([]);
  const [additionalData, setAdditionalData] = useState<TAdditionalDataForScreener | null>(null);
  const [volumeBarsData, setVolumeBarsData] = useState<TVolumeBarsData | null>(null);
  const [loaderStatus, setLoaderStatus] = useState<boolean>(true);
  const [tableTopIndex, setTableTopIndex] = useState<number>(0);

  const userSettings = useSelector((state: RootStateTypes) => state.accountState.userSettings, shallowEqual);
  const selectedTab = useSelector((state: RootStateTypes) => state.watchlistState.selectedTab, shallowEqual);
  const selectedScreen = useSelector((state: RootStateTypes) => state.newScreenState.selectedScreen, shallowEqual);
  const queryScreenerId = useSelector((state: RootStateTypes) => state.tableDataState.queryScreenerId, shallowEqual);
  const columnSetId = useSelector((state: RootStateTypes) => state.newScreenState.columnSetId, shallowEqual);
  const pagePath = useSelector((state: RootStateTypes) => state.screenerState.pagePath, shallowEqual);

  const symbolsList = useSelector((state: RootStateTypes) => state.screenerState.symbolsList, shallowEqual);
  const columnsList = useSelector((state: RootStateTypes) => state.screenerState.columnsList, shallowEqual);
  const presetsWatchlist = useSelector((state: RootStateTypes) => state.watchlistState.presets, shallowEqual);
  const itemsColumnSets = useSelector((state: RootStateTypes) => state.newScreenState.itemsColumnSets, shallowEqual);
  const itemsColumnSetsPresets = useSelector(
    (state: RootStateTypes) => state.newScreenState.itemsColumnSetsPreset, shallowEqual
  );
  const itemsNewScreen = useSelector((state: RootStateTypes) => state.newScreenState.itemsNewScreen, shallowEqual);
  const presetsNewScreen = useSelector((state: RootStateTypes) => state.newScreenState.presetsNewScreen, shallowEqual);
  const tabsList = useSelector((state: RootStateTypes) => state.watchlistState.tabsList, shallowEqual);
  const filterMarket = useSelector((state: RootStateTypes) => state.screenerState.filterMarket, shallowEqual);
  const alertTab = useSelector((state: RootStateTypes) => state.watchlistState.alertsTab, shallowEqual);

  const selectedColumnsState = useSelector(
    (state: RootStateTypes) => state.screenerState.selectedColumns, shallowEqual
  );
  const globalHoldingSearch = useSelector(
    (state: RootStateTypes) => state.dashboardsState.globalHoldingSearch, shallowEqual
  );
  const globalSectorIndustrySearch = useSelector(
    (state: RootStateTypes) => state.dashboardsState.globalSectorIndustrySearch, shallowEqual
  );
  const sectorIndustryItems = useSelector(
    (state: RootStateTypes) => state.screenerState.sectorIndustryItems, shallowEqual
  );
  const userSettingsLoaded = useSelector(
    (state: RootStateTypes) => state.accountState?.userSettingsLoaded, shallowEqual
  );

  const itemsAndPresetsWatchlist = [alertTab, ...tabsList, ...presetsWatchlist];
  const itemsAndPresetsScreens = [...itemsNewScreen, ...presetsNewScreen];

  const {
    Socket,
    Authorized,
    Connection,
    awakeSocketWidget
  } = useContext(SocketContext);

  const { currentSymbolHandler } = usePrepareLastSymbolState();
  const { updateStoreHandler } = useLastStateUpdateStore();
  const { setItems, setAllItems } = useSelection(pagePath === TABLE_SCREEN_PATH.DEEPLIST, isAlertTable);
  const { saveSortHandler } = useSaveSort();

  const cleanChanel = () => {
    if (queryScreenerRef.current) {
      Connection?.sendMessage(WS_CHANNELS.SCREENER_UNSUBSCRIBE,
        { messageId: messageIdGenerator(), screenerId: queryScreenerRef.current });
      dispatch(setQueryScreenerId(null));
      queryScreenerRef.current = null;
    }
  };

  const paginationHandler = (data: number[]) => {
    setVisibleItemsTable(data);
    visibleItemsTableRef.current = data;
  };

  const handleSetTopIndexTable = (index: number) => {
    setTableTopIndex(index);
  };

  const checkIsDeepListFilters = (
    filterFromTab: number[][],
    filterFromMarket: number[][],
  ) => {
    const filtersOne = filterFromTab ? [...filterFromTab] : [];
    const filtersSecond = filterFromMarket ? [...filterFromMarket] : [];

    return JSON.stringify(filtersOne) === JSON.stringify(filtersSecond);
  };

  const setLoader = (status: boolean) => setLoaderStatus(status);

  const handleSetGlobalSectorIndustrySearch = (value: boolean) => {
    dispatch(setGlobalSectorIndustrySearch(value));
  };

  const prepareBodyRequest = () => {
    if (!selectedColumns) return [...FIXED_COLUMNS];
    return [...FIXED_COLUMNS, ...selectedColumns];
  };

  // sorting handlers
  const innitSortArray: IInitSorting = (
    items,
    userData,
    setSortHandler,
    currentPage,
    initItem
  ) => {
    const selectedItemId = currentPage === TABLE_SCREEN_PATH.DEEPLIST
      ? userData.selectedTabId : userData.selectedScreenId;

    const currentItem = items.find((item) => item.id === selectedItemId) || initItem;
    const currentItemEmpty = Object.keys(currentItem).length;

    if (!currentItemEmpty && currentPage === TABLE_SCREEN_PATH.SCREENER) {
      setSortHandler(userData.screenerSorting || []);
      sortArrayRef.current = userData.screenerSorting || [];
      return;
    }

    if (currentItemEmpty && currentItem?.data && currentPage === TABLE_SCREEN_PATH.DEEPLIST && !isAlertTable) {
      setSortHandler(currentItem.data.sortData || []);
      sortArrayRef.current = currentItem.data.sortData || [];
      return;
    }

    if (currentItemEmpty && currentItem?.data && currentPage === TABLE_SCREEN_PATH.DEEPLIST && isAlertTable) {
      setSortHandler(selectedTab?.data?.sortData || []);
      sortArrayRef.current = selectedTab?.data?.sortData || [];
      return;
    }

    if (currentItemEmpty && currentItem?.data && currentPage === TABLE_SCREEN_PATH.SCREENER) {
      if (currentItem.type === TEMPLATE_TYPE.SCREENER) {
        setSortHandler(currentItem.data.sortData || []);
        sortArrayRef.current = currentItem.data.sortData || [];
        return;
      }

      if (currentItem.type === TEMPLATE_TYPE.PRESET) {
        setSortHandler(userData.presetsSorting[selectedItemId] || []);
        sortArrayRef.current = userData.presetsSorting[selectedItemId] || [];
      }
    }
  };

  const sortHandler = (index: number): void => {
    const array = sortArrayRef.current ? [...sortArrayRef.current as SortDataType[]] : [];

    const defaultSortItem = { sortValue: REVERSE_SORTING_COLUMNS.includes(index) ? 0 : 1, indexElement: index };
    const currentIndex = array.findIndex((item: SortDataType) => item.indexElement === index);

    const newSort = isAlertTable
      ? setSortAlertArray(array, defaultSortItem, currentIndex) : sortHelper(array, defaultSortItem, currentIndex);

    const selectedDbItemId = pagePath === TABLE_SCREEN_PATH.DEEPLIST
      ? selectedTabRef.current?.id : selectedScreenRef.current?.id;

    saveSortHandler(
      globalHoldingSearchRef.current
        ? newSort.filter((item: SortDataType) => item.indexElement !== holdingsIndex) : newSort,
      selectedDbItemId
    );

    setSortArray(newSort);
    sortArrayRef.current = newSort;
  };

  const setNewSortingData = (data: SortDataType[]) => {
    saveSortHandler(data);
    setSortArray(data);
    sortArrayRef.current = data;
  };

  const clearSortingHandler = () => {
    saveSortHandler([]);
    setSortArray([]);
    sortArrayRef.current = [];
  };

  const sortStatus = (index: number) => {
    const specificItem = sortArray?.find((item: ISortItem) => item.indexElement === index);

    if (specificItem) {
      const position = sortArray?.indexOf(specificItem);
      return { ...specificItem, position: position as number + 1 };
    }

    return undefined;
  };

  const prepareSortData = (
    columns: number[],
    sortData: SortDataType[]
  ) => sortData?.filter((item) => columns.includes(item.indexElement));
  // sorting handlers

  // request data init and handling
  const updateWidgetConnection = (): void => {
    const requestCondition: (DeepListItemData | ScreenItemData)[] = pagePath === TABLE_SCREEN_PATH.SCREENER
      ? itemsAndPresetsScreens : itemsAndPresetsWatchlist;

    const innitItem: DeepListItemData | ScreenItemData = pagePath === TABLE_SCREEN_PATH.SCREENER
      ? selectedScreen : selectedTab;
    if (globalHoldingSearch) return;

    innitSortArray(requestCondition, userSettings, setSortArray, pagePath, innitItem);

    if (pagePath === TABLE_SCREEN_PATH.SCREENER) {
      updateScreenerData({
        widget: null,
        itemsNewScreen,
        presetsNewScreen,
        setFiltersData,
        setSelectedColumns,
        itemsColumnSets: [...itemsColumnSets, ...itemsColumnSetsPresets],
        userSettings,
        updateStoreHandler,
        selectedScreenId: userSettings.selectedScreenId,
        selectedScreen
      });
    } else {
      updateDeepListData({
        widget: null,
        tabsList: itemsAndPresetsWatchlist,
        setFiltersData,
        setSelectedColumns,
        alertFilters,
        isAlertTable,
        itemsColumnSets: [...itemsColumnSets, ...itemsColumnSetsPresets],
        selectedTabId: userSettings.selectedTabId,
        selectedTab,
        userSettings
      });
    }
  };

  useEffect(() => {
    selectedTabRef.current = selectedTab;
    selectedScreenRef.current = selectedScreen;
  }, [selectedTab, selectedScreen]);

  useEffect(() => {
    setSelectedColumns(null);
    setTableData([]);
    setColumnsData([]);

    setLoaderStatus(true);
    if (visibleItemsTable !== defaultPage) {
      paginationHandler(defaultPage);
    }
  }, [pagePath, userSettings.selectedTabId, userSettings.selectedScreenId]);

  useEffect(() => {
    if (queryScreenerRef.current) {
      if (checkIsDeepListFilters(selectedTab?.data?.filters, filterMarket)) {
        return;
      }
      if (pagePath === TABLE_SCREEN_PATH.SCREENER && filterMarket !== filtersData) {
        paginationHandler(defaultPage);
        setFiltersData(filterMarket);
      }
    }
  }, [filterMarket]);

  useEffect(() => {
    if (selectedColumnsState?.length && queryScreenerRef.current) {
      const newColumns = selectedColumnsState.map((item: IColumnItem) => item.id);
      if (JSON.stringify(newColumns) === JSON.stringify(selectedColumns)) return;
      if (pagePath === TABLE_SCREEN_PATH.SCREENER) {
        if (userSettings.selectedScreenId) {
          if (checkIsParentColumnSet(
            null, columnSetId, itemsNewScreen,
            presetsNewScreen, [...itemsColumnSets, ...itemsColumnSetsPresets],
            userSettings, userSettings.selectedScreenId
          )) {
            setSelectedColumns(newColumns);
          }
        }
        if (!selectedScreen?.id
          && checkIsDefaultColumnSet(columnSetId, userSettings, [...itemsColumnSets, ...itemsColumnSetsPresets])
          && !userSettings.selectedScreenId
        ) {
          setSelectedColumns(newColumns);
        }
      }
      if (pagePath === TABLE_SCREEN_PATH.DEEPLIST) {
        if (userSettings.selectedTabId) {
          setSelectedColumns(newColumns);
        }
        if (!userSettings.selectedTabId) {
          const defaultTab = itemsAndPresetsWatchlist.find(
            (item: DeepListItemData) => item?.data?.subType === UNIVERSE_TYPE
          );

          if (defaultTab?.id === selectedTab?.id) {
            setSelectedColumns(newColumns);
          }
        }
      }
    }
  }, [selectedColumnsState, itemsNewScreen, presetsNewScreen, tabsList, presetsWatchlist]);

  useEffect(() => {
    if (userSettingsLoaded) {
      updateWidgetConnection();
    }
  }, [
    Authorized,
    pagePath,
    userSettingsLoaded,
    itemsColumnSets.length,
    itemsColumnSetsPresets.length,
    selectedTab,
    userSettings.selectedScreenId
  ]);
  // request data init and handling

  // etf handlers
  const getETFSymbols = (etfId: number) => {
    if (Socket?.readyState === WebSocket.OPEN && Authorized) {
      const queryMessage = { etfId, messageId: screenerMessageId };
      Connection?.sendMessage(WS_CHANNELS.SCREENER_GET_ETF, queryMessage);
    }
  };

  const setOpenETF = (etfSymbol: ShortSymbol) => {
    const index = etfSymbol?.sortIndex;
    dispatch(setETFSymbolIndex(index));
    setEtfSymbolIndex(index);

    dispatch(setGlobalHoldingSearch(true));
    getETFSymbols(index);
  };

  const resetEtfSymbol = () => {
    paginationHandler(defaultPage);

    setFiltersData(null);
    filtersRef.current = [];

    const filteredSortData = sortArrayRef.current?.filter(
      (item: SortDataType) => item.indexElement !== holdingsIndex
    );
    setSortArray(filteredSortData as SortDataType[]);
    sortArrayRef.current = filteredSortData as SortDataType[];

    setEtfSymbolIndex(-1);

    updateWidgetConnection();
  };

  useEffect(() => {
    if (!globalHoldingSearch && (etfSymbolIndex > 0)) {
      resetEtfSymbol();
    }

    globalHoldingSearchRef.current = globalHoldingSearch;
  }, [globalHoldingSearch]);
  // etf handlers

  // requests to WS
  const getWSData = () => {
    const activeColumns = prepareBodyRequest();
    const sortData = prepareSortData(activeColumns, sortArray || []);

    const visibleRange = visibleItemsTableRef.current || defaultPage;

    const queryMessage: ScreenerQueryMessage = {
      messageId: screenerMessageId,
      columns: [...new Set(activeColumns), ...DEFAULT_COLUMNS_INDEX],
      filters: validationFilterMarket(filtersData),
      sortBy: [...sortData.map((item: SortDataType) => [item.indexElement, item.sortValue])],
      range: visibleRange,
      symbolIndex: currentSymbolHandler(pagePath === TABLE_SCREEN_PATH.DEEPLIST).index
    };

    if (etfSymbolIndex >= 0) {
      queryMessage.etfSymbolIndex = etfSymbolIndex;
    }

    if (pagePath === TABLE_SCREEN_PATH.DEEPLIST && filtersData && !globalSectorIndustrySearch) {
      compareAndCleanSelections([...filtersRef.current], [...filtersData], clearSelections);
    }

    if (globalSectorIndustrySearch) {
      queryMessage.filters = filtersRef.current;
    } else {
      filtersRef.current = validationFilterMarket(filtersData);
    }

    if (queryScreenerRef.current) {
      // eslint-disable-next-line no-console
      console.log('getWSData PATCH =>');
      queryMessage.screenerId = queryScreenerRef.current as number;
      const emitter = () => Connection?.sendMessage(
        WS_CHANNELS.SCREENER_PATCH,
        queryMessage,
      );
      emitter();
      repeatItems[queryScreenerRef.current as number] = emitter;
    } else {
      // eslint-disable-next-line no-console
      console.log('getWSData SUBSCRIBE =>');

      Connection?.sendMessage(WS_CHANNELS.SCREENER_SUBSCRIBE, queryMessage);
    }
  };

  const getWSDataOnScroll = () => {
    if (Socket?.readyState === WebSocket.OPEN && Authorized) {
      if (queryScreenerRef.current) {
        const queryMessage: ScreenerQueryMessageRange = {
          messageId: screenerMessageId,
          screenerId: queryScreenerRef.current as number,
          range: visibleItemsTableRef.current as number[],
          symbolIndex: currentSymbolHandler(pagePath === TABLE_SCREEN_PATH.DEEPLIST).index
        };
        if (etfSymbolIndex >= 0) {
          queryMessage.etfSymbolIndex = etfSymbolIndex;
        }
        if (pagePath === TABLE_SCREEN_PATH.DEEPLIST && filtersData) {
          compareAndCleanSelections([...filtersRef.current], [...filtersData], clearSelections);
        }
        const emitter = () => Connection?.sendMessage(WS_CHANNELS.SCREENER_PATCH, queryMessage);
        emitter();
        repeatItems[queryScreenerRef.current as number] = emitter;
      } else {
        getWSData();
      }
    }
  };

  const getWSIndexes = () => {
    const activeColumns = prepareBodyRequest();
    const sortData = prepareSortData(activeColumns, sortArray || []);
    if (Socket?.readyState === WebSocket.OPEN && Authorized) {
      const queryMessage: ScreenerQueryMessage = {
        messageId: screenerMessageId,
        filters: validationFilterMarket(filtersData),
        sortBy: [...sortData.map((item: ISortItem) => [item.indexElement, item.sortValue])],
      };

      if (queryScreenerRef.current) {
        queryMessage.screenerId = queryScreenerRef.current as number;
        const emitter = () => Connection?.sendMessage(
          WS_CHANNELS.SCREENER_INDICES_LIST,
          queryMessage,
        );
        emitter();

        repeatItems[WS_CHANNELS.SCREENER_INDICES_LIST] = emitter;
      } else {
        Connection?.sendMessage(WS_CHANNELS.SCREENER_INDICES_LIST, queryMessage);
      }
    }
  };

  useEffect(() => {
    if (queryScreenerRef.current && Connection?.socketId !== socketIdRef.current && Authorized) {
      queryScreenerRef.current = null;
      socketIdRef.current = Connection?.socketId;
      getWSData();
      getWSIndexes();
    }
  }, [Connection, Authorized]);

  useEffect(() => {
    topIndexRef.current = tableTopIndex;

    if (queryScreenerRef.current && visibleItemsTable !== defaultPage) {
      getWSDataOnScroll();
    }
  }, [visibleItemsTable]);

  useEffect(() => {
    const debouncedGetWSIndexes = debounce(getWSIndexes, 150);
    const debouncedGetWSData = debounce(getWSData, 150);

    if (selectedColumns && filtersData && sortArray) {
      debouncedGetWSIndexes();
      debouncedGetWSData();
    }

    return () => {
      debouncedGetWSIndexes.cancel();
      debouncedGetWSData.cancel();
    };
  }, [selectedColumns, filtersData, sortArray]);
  // requests to WS

  // ws response listeners
  const prepareDataHandler = (resp: SocketScreenerAllResponse) => {
    const currentColumns = [...new Set(columnsIndexesRef.current), ...DEFAULT_COLUMNS_INDEX];
    const {
      preparedDataScreener, dataForVolumeBars, industryOfSymbolsData
    } = workerWithScreenerData(resp, columnsListRef?.current, symbolsList, currentColumns);

    const preparedAdditionalData = getDividentAndEarnings(resp, currentColumns);
    const oldData = {
      dataRows: tableDataRef?.current,
      queryScreenerId: resp?.screenerId,
      visibleItemsTable: visibleItemsTableRef?.current,
      screenerRowTopIndex: topIndexRef?.current
    };
    const newData = {
      screenerId: resp?.screenerId,
      data: preparedDataScreener?.result,
    };
    let comparedData = compareDataArray(oldData, newData);
    if (pagePath === TABLE_SCREEN_PATH.DEEPLIST) {
      comparedData = cleanOldDataDeeplist(comparedData, filtersRef.current, symbolsList);
    }
    setTableData(comparedData as RowItem[]);
    dispatch(setCounterSymbols({ data: resp?.total, screenerId: resp?.screenerId }));
    tableDataRef.current = comparedData as ScreenerRowData[];

    const newColumns = {
      screenerId: resp?.screenerId,
      data: preparedDataScreener?.columns,
    };
    const oldColumns = {
      dataColumns: currentColumns,
      queryScreenerId: resp?.screenerId,
    };
    const comparedColumns = compareColumnArray(oldColumns, newColumns);
    columnsRenderRef.current = comparedColumns;

    dispatch(setIndustryOfSymbols(industryOfSymbolsData));
    setColumnsData(comparedColumns as ScreenerColumnData[]);
    dispatch(setDataColumns({ data: comparedColumns, screenerId: resp?.screenerId }));

    setAdditionalData(preparedAdditionalData as TAdditionalDataForScreener);
    setVolumeBarsData(dataForVolumeBars as TVolumeBarsData);
    dispatch(setVolumeDayHistory(dataForVolumeBars));
    setLoaderStatus(false);
  };

  const screenerPartialResponse = (resp: SocketResponse) => {
    if (resp.errors?.length && awakeSocketWidget) {
      awakeSocketWidget();
      return;
    }

    if (resp?.screenerId === queryScreenerRef.current) {
      const dataResp = resp.data;

      if (!dataResp.length) return;

      setTableData((prevTableData) => {
        const newTableData: RowItem[] = [...prevTableData];

        for (let i = 0; i < dataResp.length; i += 2) {
          const rowIndex = dataResp[0];
          const columnIndex = dataResp[i + 1];
          const value = dataResp[i + 2];
          const row = newTableData.find(
            (rowItem) => rowItem[0].index === rowIndex
          );

          if (row) {
            const column = columnsRenderRef.current.find(
              (colItem) => colItem.index === columnIndex
            );

            if (column) {
              const columnIndexInRow = columnsRenderRef.current.findIndex(
                (colItem) => colItem.index === columnIndex
              );
              if (columnIndexInRow !== -1) {
                row[columnIndexInRow] = value;
              }
            }
          }
        }
        return newTableData;
      });
    }
  };

  const dataCallbackPatch = (resp: SocketScreenerAllResponse) => {
    if (resp.errors?.length && awakeSocketWidget) {
      awakeSocketWidget();
      return;
    }
    // eslint-disable-next-line no-console
    console.log('dataCallbackPatch =>', resp?.screenerId, queryScreenerRef.current);
    if (resp?.screenerId === queryScreenerRef.current) {
      prepareDataHandler(resp as SocketScreenerAllResponse);
    }
  };

  const dataCallback = (resp: SocketScreenerAllResponse) => {
    if (resp.errors?.length && awakeSocketWidget) {
      awakeSocketWidget();
      return;
    }

    // eslint-disable-next-line no-console
    console.log('dataCallback =>', resp?.messageId, screenerMessageId);
    if (resp?.messageId === screenerMessageId && resp?.screenerId) {
      queryScreenerRef.current = resp?.screenerId;
      socketIdRef.current = Connection?.socketId;
      dispatch(setQueryScreenerId(resp?.screenerId));
      prepareDataHandler(resp as SocketScreenerAllResponse);
    }
  };

  const screenerIndexesCallback = (result: { data: number[], messageId: number, errors?: string[] }) => {
    if (result.errors?.length && awakeSocketWidget) {
      awakeSocketWidget();
      return;
    }

    if (result?.messageId === screenerMessageId) {
      setTableIndexesData(result.data);
      dispatch(setDataRowsIndexes(result.data));
    }
  };

  const etfCallback = (resp: SocketScreenerAllResponse) => {
    if (resp.errors?.length && awakeSocketWidget) {
      awakeSocketWidget();
      return;
    }

    if (resp?.messageId !== screenerMessageId) return;
    const etfItems = resp?.etfMembers as number[];
    const etfId = resp?.etfId as number;
    const symbol = symbolsList.find((item: ShortSymbol) => item?.index === etfId)?.sym;
    setFiltersData([[[0, 0, etfItems]]] as unknown as number[][][][]);

    dispatch(setSearchHeaderValue(`${symbol} ${HOLDING_TYPE}`));
    dispatch(setSearchValue(''));

    paginationHandler(defaultPage);
    sortHandler(holdingsIndex);
  };

  const pingCallback = (resp: { screenerId: number, errors?: string[] }) => {
    if (resp.errors?.length && awakeSocketWidget) {
      awakeSocketWidget();
      return;
    }

    if (resp?.screenerId === queryScreenerRef.current) {
      Connection?.sendMessage(
        WS_CHANNELS.PONG_PERIODICAL_DATA,
        {
          periodicalId: resp.screenerId,
          type: WS_PING_TYPES.SCREENER,
          socketId: Connection?.socketId
        }
      );
    }
  };

  useEffect(() => {
    if (Socket?.readyState === WebSocket.OPEN && Authorized) {
      Connection?.messageEmitter.on(WS_CHANNELS.SCREENER_SUBSCRIBE, dataCallback);
      Connection?.messageEmitter.on(WS_CHANNELS.SCREENER_PATCH, dataCallbackPatch);
      Connection?.messageEmitter.on(WS_CHANNELS.SCREENER_RESPONSE_ALL, dataCallbackPatch);
      Connection?.messageEmitter.on(WS_CHANNELS.SCREENER_INDICES_LIST, screenerIndexesCallback);
      Connection?.messageEmitter.on(WS_CHANNELS.SCREENER_RESPONSE_PARTIAL, screenerPartialResponse);
      Connection?.messageEmitter.on(WS_CHANNELS.SCREENER_GET_ETF, etfCallback);
      Connection?.messageEmitter.on(WS_CHANNELS.PING_PERIODICAL_DATA, pingCallback);
    }
  }, [Socket, Authorized]);
  // ws response listeners

  const resetSectorIndustrySearch = () => {
    paginationHandler(defaultPage);

    setFiltersData(null);
    filtersRef.current = [];

    updateWidgetConnection();
  };

  useEffect(() => {
    if (!globalSectorIndustrySearch) {
      resetSectorIndustrySearch();
    }
  }, [globalSectorIndustrySearch]);

  useEffect(() => {
    columnsIndexesRef.current = prepareBodyRequest();
  }, [selectedColumns]);

  useEffect(() => {
    columnsListRef.current = columnsList;
  }, [columnsList]);

  const handleSetAllItems = () => {
    const foundTab = tabsList.find((item: DeepListItemData) => item.id === userSettings.selectedTabId);
    setAllItems((
      pagePath === TABLE_SCREEN_PATH.DEEPLIST && !foundTab?.parentId)
      ? foundTab?.data?.filters[0][0][2]
      : tableIndexesData);
  };

  const sectorIndustryWidgetHandler = (index: number, itemTitle: string): void => {
    const currentItemIndex = sectorIndustryItems.find((subItem: { title: string }) => subItem.title === itemTitle);

    dispatch(setSearchHeaderValue(currentItemIndex ? `${currentItemIndex.title}` : ''));
    dispatch(setSearchValue(''));

    setFiltersData([[[index, 0, [currentItemIndex ? currentItemIndex.value : 0]]]] as unknown as number[][][][]);
    filtersRef.current = [[[index, 0, [currentItemIndex ? currentItemIndex.value : 0]]]] as unknown as number[][][][];
  };

  useEffect(() => () => {
    cleanChanel();
    Connection?.messageEmitter.off(WS_CHANNELS.SCREENER_SUBSCRIBE, dataCallback);
    Connection?.messageEmitter.off(WS_CHANNELS.SCREENER_INDICES_LIST, screenerIndexesCallback);
    Connection?.messageEmitter.off(WS_CHANNELS.SCREENER_PATCH, dataCallbackPatch);
    Connection?.messageEmitter.off(WS_CHANNELS.SCREENER_RESPONSE_ALL, dataCallbackPatch);
    Connection?.messageEmitter.off(WS_CHANNELS.SCREENER_RESPONSE_PARTIAL, screenerPartialResponse);
    Connection?.messageEmitter.off(WS_CHANNELS.SCREENER_GET_ETF, etfCallback);
    Connection?.messageEmitter.off(WS_CHANNELS.PING_PERIODICAL_DATA, pingCallback);

    // eslint-disable-next-line
    // @ts-ignore
    columnsListRef.current = [];
    columnsIndexesRef.current = [];
    tableDataRef.current = [];
    columnsRenderRef.current = [];
    filtersRef.current = [];
    visibleItemsTableRef.current = defaultPage;
    sortArrayRef.current = null;
    globalHoldingSearchRef.current = null;
    topIndexRef.current = 0;
    selectedTabRef.current = null;
    selectedScreenRef.current = null;
  }, []);

  useEffect(() => {
    if (queryScreenerRef.current) {
      if (activeSymbolIndexValue !== undefined) {
        paginationHandler(defaultPage);
        setFiltersData([[[0, 0, [activeSymbolIndexValue]]]] as unknown as number[][][][]);
      } else {
        updateWidgetConnection();
      }
    }
  }, [activeSymbolIndexValue]);

  useEffect(() => {
    queryScreenerRef.current = queryScreenerId;
  }, [queryScreenerId]);

  useEffect(() => {
    if (!Connection) {
      cleanChanel();
    }
  }, [Connection]);

  return {
    tableData,
    columnsData,
    sortHandler,
    sortStatus,
    clearSortingHandler,
    sortData: sortArray,
    setNewSortingData,
    setLoader,
    handleSetAllItems,
    setItems,
    setTopScreenerIndex: handleSetTopIndexTable,
    setVisibleItemsTable: paginationHandler,
    setOpenETF,
    sectorIndustryWidgetHandler,
    volumeBarsData,
    additionalData,
    loaderStatus,
    isHoldingView: etfSymbolIndex > 0,
    handleSetGlobalSectorIndustrySearch,
    tableIndexesData
  };
};

export default useMainTableWorker;
