import moment from 'moment-timezone';
import React from 'react';
import isURL from './validationHelper';
import { DEFAULT_TIMEZONE } from '../constants/account';
import {
  PREPARE_COLOR_DATA,
  STYLE_GENERATOR_SETTINGS,
  CHECKED,
  UNCHECKED,
  TABLE_ITEM_SETTINGS,
  ONE_HUNDRED,
  ONE_THOUSAND,
  TABLE_ITEM_COLORS_SETTINGS,
  HEX_COLORS,
  PROTOCOL,
  FALSY_VALUES,
  CLICKABLE_CELLS_INDEXES,
} from '../constants/screener';
import { REVISION } from '../constants/statsChart';
import ThemeVariants from '../constants/theme';
import { ReactComponent as UpIcon } from '../assets/images/up.svg';
import { ReactComponent as DownIcon } from '../assets/images/down.svg';

const formatter = (val, round = 2) => {
  const value = Number(val);
  const valLength = value?.toFixed().toString().length;
  switch (Math.floor((valLength - 1) / 3)) {
    case 1:
      return `${+(value / 10 ** 3)?.toFixed(round)}K`;
    case 2:
      return `${+(value / 10 ** 6)?.toFixed(round)}M`;
    case 3:
      return `${+(value / 10 ** 9)?.toFixed(round)}B`;
    case 4:
      return `${+(value / 10 ** 12)?.toFixed(round)}T`;
    default:
      return `${+value?.toFixed(round)}`;
  }
};

const formatterString = (val, round = 2) => {
  const value = Number(val);
  const valLength = value?.toFixed().toString().length;

  switch (Math.floor((valLength - 1) / 3)) {
    case 1:
      return `${(value / 10 ** 3)?.toFixed(round)}K`;
    case 2:
      return `${(value / 10 ** 6)?.toFixed(round)}M`;
    case 3:
      return `${(value / 10 ** 9)?.toFixed(round)}B`;
    case 4:
      return `${(value / 10 ** 12)?.toFixed(round)}T`;
    default:
      return `${value?.toFixed(round)}`;
  }
};

export const renderItem = (flag) => {
  if (!flag.revisionState) {
    return <div />;
  }

  if (flag.revisionState === REVISION.POSITIVE) {
    return <UpIcon />;
  }

  return <DownIcon />;
};

const transformRound = (typeItem, value, isForExport = false) => {
  switch (typeItem) {
    case 'M_$':
      return isForExport ? `$${value}` : `$${formatter(value)}`;
    case '2_$':
      return `$${Number(value).toFixed(2)}`;
    default:
      return value;
  }
};

const getRound = (roundValue) => {
  let round = roundValue;
  const checkRoundValue = roundValue?.split('_');
  if (checkRoundValue.length > 1) {
    const [value] = checkRoundValue;
    round = value;
  }
  return round;
};

const handleDefault = (typeItem, value, isForExport = false) => {
  if (typeItem?.match('round')) {
    const round = typeItem?.split(':')[1];
    const roundNumber = round?.split('_')[0];
    const valueRange = `${(Number(value)?.toFixed(roundNumber))?.toLocaleString('en-US')}`;
    return transformRound(round, valueRange, isForExport);
  }
  return value;
};

const caseData = (typeItem, value, isForExport = false) => ({
  percent: () => {
    let roundNumberPercent = 0;
    roundNumberPercent = getRound(typeItem?.split(':')[1]);
    return `${roundNumberPercent
      ? (value * ONE_HUNDRED).toFixed(+roundNumberPercent)
      : Math.round(value * ONE_HUNDRED)}%`;
  },
  text: () => `${value}`,
  int: () => `${typeof value === 'number' ? (+value?.toFixed(2)).toLocaleString('en-US') : 0}`,
  float: () => `${typeof value === 'number' ? (+value?.toFixed(2)).toLocaleString('en-US') : 0}`,
  candle: () => `${Math.round(value * ONE_HUNDRED)}%`,
  M: () => (isForExport ? `${value}` : `${formatter(value)}`),
  url: () => `${value.replace(PROTOCOL.HTTP, PROTOCOL.HTTPS)}`,
  date: () => `${value === null ? TABLE_ITEM_SETTINGS.VALUE_EMPTY
    : moment(value * ONE_THOUSAND).tz(DEFAULT_TIMEZONE).format('MMM DD, YYYY')}`,
  datetime: () => `${value === null ? moment(value * ONE_THOUSAND).tz(DEFAULT_TIMEZONE).format('MMM DD, YYYY HH:MM:SS')
    : TABLE_ITEM_SETTINGS.VALUE_EMPTY}`,
  name: () => `${value}`,
  $: () => `
      ${value !== TABLE_ITEM_SETTINGS.VALUE_EMPTY ? '$' : ''}
      ${(typeof value === 'number' ? +value.toFixed(2) : value)}`,
  M_$: () => (isForExport ? `$${value}` : `$${formatter(value)}`),
  change: () => `${value}`,
  link: () => `${value}`.replace(PROTOCOL.HTTP, PROTOCOL.HTTPS),
  phone: () => `${value}`,
  email: () => `${value}`,
  bool: () => `${value ? CHECKED : UNCHECKED}`,
  NA: () => `${value === TABLE_ITEM_SETTINGS.VALUE_NA ? '-' : value}`,
  default: () => handleDefault(typeItem, value, isForExport)
});

const transformData = (typeItem, value, name, isForExport = false) => {
  const newType = typeItem?.split(':')[0] ?? typeItem;

  if (FALSY_VALUES.includes(value) || Number.isNaN(value)) {
    return `${TABLE_ITEM_SETTINGS.VALUE_EMPTY}`;
  }

  const formattedData = caseData(typeItem, value, isForExport);
  return (formattedData[newType] || formattedData.default)();
};

const openLink = (value) => {
  let url = value;
  if (url.indexOf('http') === -1 && url.indexOf('https') === -1) {
    url = `${PROTOCOL.HTTP}${url}`;
  }
  if (isURL(url)) {
    url = new URL(url);
    window.open(url, '_blank').focus();
  }

  return null;
};

const createCall = (value) => {
  window.open(`tel:${value}`).focus();
};

const sendEmail = (value) => {
  window.open(`mailto:${value}`).focus();
};

export const getHandler = (typeItem, value, sectorIndustryClickHAndler, columnIndex) => {
  if (CLICKABLE_CELLS_INDEXES.includes(columnIndex)) {
    return () => sectorIndustryClickHAndler(columnIndex, value);
  }

  const types = typeItem?.split('_');
  switch (types[1]) {
    case 'url':
      return value ? () => openLink(value) : () => { };
    case 'phone':
      return value ? () => createCall(value) : () => { };
    case 'email':
      return value ? () => sendEmail(value) : () => { };
    default:
      return null;
  }
};

const getRangeText = ({ arrayData }) => {
  let value = arrayData[0]?.value;
  const types = arrayData[0]?.subType?.split('_') || ['int'];

  if (types.includes('$')) {
    types.forEach((typeItem) => { value = transformData(`M_${typeItem}`, value); });
  } else {
    types.forEach((typeItem) => { value = transformData(typeItem, value); });
  }

  let valueMax = arrayData[1]?.value;
  const typesMax = arrayData[1]?.subType?.split('_') || ['int'];

  if (typesMax.includes('$')) {
    typesMax.forEach((typeItem) => { valueMax = transformData(`M_${typeItem}`, valueMax); });
  } else {
    typesMax.forEach((typeItem) => { valueMax = transformData(typeItem, valueMax); });
  }

  const textOne = arrayData[0]?.value === null ? '<' : value;
  const textSecond = arrayData[1]?.value === null ? '>' : valueMax;
  let text = '';
  if (arrayData[1]?.value === null) {
    text = `${textSecond} ${textOne}`;
  } else if (arrayData[0]?.value === null) {
    text = `${textOne} ${textSecond} `;
  } else {
    text = `${textOne} - ${textSecond}`;
  }
  return text;
};

const prepareColorHandler = (data, value) => {
  const values = data.filter((item, index) => index % 2);

  let color = '';
  if (Number.isNaN(value) || value === null) {
    return color;
  }
  if (values.length) {
    values.forEach((mainItem, index) => {
      if (index === 0 && value <= mainItem) {
        const findIndex = data.findIndex((item) => item === values[index]);
        color = data[findIndex - 1] === PREPARE_COLOR_DATA.DEFAULT ? PREPARE_COLOR_DATA.EMPTY : data[findIndex - 1];
      } else if (index === 0 && value >= mainItem && data.length <= 3) {
        const findIndex = data.findIndex((item) => item === values[index]);
        color = data[findIndex + 1] === PREPARE_COLOR_DATA.DEFAULT ? PREPARE_COLOR_DATA.EMPTY : data[findIndex + 1];
      } else if (index !== 0 && value < mainItem && value > values[index - 1] && data.length >= 3) {
        const findIndex = data.findIndex((item) => item === values[index]);
        color = data[findIndex - 1] === PREPARE_COLOR_DATA.DEFAULT ? PREPARE_COLOR_DATA.EMPTY : data[findIndex - 1];
      } else if (index !== 0 && value >= mainItem) {
        const findIndex = data.findIndex((item) => item === values[index]);
        color = data[findIndex + 1] === PREPARE_COLOR_DATA.DEFAULT ? PREPARE_COLOR_DATA.EMPTY : data[findIndex + 1];
      }
    });
  }

  return color;
};

const changeColorHandler = (types, value) => {
  const type = types?.split(':');
  const newType = type[0] ?? types;
  const data = type.slice(1, type.length);

  switch (newType) {
    case PREPARE_COLOR_DATA.COLOR_TXT:
      return {
        title: newType,
        color: prepareColorHandler(data, value)
      };
    case PREPARE_COLOR_DATA.COLOR_BG:
      return {
        title: newType,
        color: prepareColorHandler(data, value)
      };
    case PREPARE_COLOR_DATA.COLOR_DOT:
      return {
        title: newType,
        color: prepareColorHandler(data, value)
      };
    default:
      return null;
  }
};

const convertMultiChartSort = (sortBy, direction = -1) => {
  if (direction === -1) {
    return [];
  }
  switch (sortBy) {
    case '':
      return [];
    default:
      return [[parseInt(sortBy, 10), direction]];
  }
};

export const darkStyleGenerator = (color, theme) => {
  if (color === HEX_COLORS.BLUE && theme === ThemeVariants.DARK_MODE) {
    return HEX_COLORS.BLUE_DARK;
  }
  return color;
};

export const removeHashFromHex = (hexColor) => {
  if (hexColor.startsWith('#')) {
    return hexColor.slice(1);
  }
  return hexColor;
};

export const generateOverrides = (theme) => {
  const darkModeBlue = theme === ThemeVariants.DARK_MODE ? '#6D9EFF' : '#3A75E8';

  return {
    'paneProperties.background': 'rgba(255, 255, 255, 0.8)',
    'mainSeriesProperties.candleStyle.upColor': darkModeBlue,
    'mainSeriesProperties.candleStyle.downColor': '#F55BAE',
    'mainSeriesProperties.candleStyle.borderUpColor': darkModeBlue,
    'mainSeriesProperties.candleStyle.borderDownColor': '#F55BAE',
    'mainSeriesProperties.candleStyle.wickUpColor': darkModeBlue,
    'mainSeriesProperties.candleStyle.wickDownColor': '#F55BAE',

    'mainSeriesProperties.hollowCandleStyle.upColor': darkModeBlue,
    'mainSeriesProperties.hollowCandleStyle.downColor': '#F55BAE',
    'mainSeriesProperties.hollowCandleStyle.borderUpColor': darkModeBlue,
    'mainSeriesProperties.hollowCandleStyle.borderDownColor': '#F55BAE',
    'mainSeriesProperties.hollowCandleStyle.wickUpColor': darkModeBlue,
    'mainSeriesProperties.hollowCandleStyle.wickDownColor': '#F55BAE',

    'mainSeriesProperties.haStyle.upColor': darkModeBlue,
    'mainSeriesProperties.haStyle.downColor': '#F55BAE',
    'mainSeriesProperties.haStyle.borderUpColor': darkModeBlue,
    'mainSeriesProperties.haStyle.borderDownColor': '#F55BAE',
    'mainSeriesProperties.haStyle.wickUpColor': darkModeBlue,
    'mainSeriesProperties.haStyle.wickDownColor': '#F55BAE',

    'mainSeriesProperties.barStyle.upColor': darkModeBlue,
    'mainSeriesProperties.barStyle.downColor': '#F55BAE',

    'mainSeriesProperties.lineStyle.color': darkModeBlue,

    'mainSeriesProperties.areaStyle.color1': 'rgba(33, 150, 243, 0.28)',
    'mainSeriesProperties.areaStyle.color2': darkModeBlue,
    'mainSeriesProperties.areaStyle.linecolor': darkModeBlue,

    'mainSeriesProperties.baselineStyle.baselineColor': 'rgba( 117, 134, 150, 1)',
    'mainSeriesProperties.baselineStyle.topFillColor2': 'rgba( 58, 117, 232, 0.28)',
    'mainSeriesProperties.baselineStyle.topFillColor1': 'rgba( 245, 91, 174, 0.05)',
    'mainSeriesProperties.baselineStyle.bottomFillColor2': 'rgba( 58, 117, 232, 0.05)',
    'mainSeriesProperties.baselineStyle.bottomFillColor1': 'rgba( 245, 91, 174, 0.28)',
    'mainSeriesProperties.baselineStyle.topLineColor': '#6D9EFF',
    'mainSeriesProperties.baselineStyle.bottomLineColor': 'rgba( 245, 91, 174, 1)'
  };
};

const styleGenerator = (data, theme) => {
  switch (data?.title) {
    case PREPARE_COLOR_DATA.COLOR_BG:
      return {
        backgroundColor: `#${data?.color}`,
        minWidth: STYLE_GENERATOR_SETTINGS.MIN_WIDTH,
        width: STYLE_GENERATOR_SETTINGS.WIDTH,
        height: STYLE_GENERATOR_SETTINGS.HEIGHT,
        display: STYLE_GENERATOR_SETTINGS.DISPLAY,
        justifyContent: STYLE_GENERATOR_SETTINGS.JUSTIFY_CONTENT,
        padding: STYLE_GENERATOR_SETTINGS.PADDING,
        alignItems: STYLE_GENERATOR_SETTINGS.ALIGN_ITEMS,
        color: STYLE_GENERATOR_SETTINGS.COLOR,
        borderRadius: STYLE_GENERATOR_SETTINGS.BORDER_RADIUS
      };
    case PREPARE_COLOR_DATA.COLOR_TXT:
      return { color: `#${darkStyleGenerator(data?.color, theme)}` };
    case PREPARE_COLOR_DATA.COLOR_DOT:
      return { dotBG: `#${darkStyleGenerator(data?.color, theme)}` };
    default:
      return {};
  }
};

const darkColorGenerator = (data) => {
  if (data?.color === TABLE_ITEM_COLORS_SETTINGS.DEFAULT_COLOR) {
    return {
      color: TABLE_ITEM_COLORS_SETTINGS.DEFAULT_COLOR_DARK
    };
  }
  return data;
};

const correctText = ({ item }) => {
  let text = item?.title;
  if (item?.title) {
    const arrayString = item.title?.split('/');
    if (arrayString.length > 1) {
      text = `${arrayString[0]}/ ${arrayString[1]}`;
    } else {
      text = `${arrayString[0]}`;
    }
  }
  return text;
};

export {
  transformData, formatter, changeColorHandler, styleGenerator, getRangeText,
  correctText, darkColorGenerator, convertMultiChartSort, formatterString,
};
