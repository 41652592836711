import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import FilterLayout from '../../layout/FilterLayout/FilterLayout';
import NoOptions from '../NoOptions/NoOptions';
import styles from './sass/FilterGroupEmaSma.module.scss';
import filtersSwitchButton from '../../lists/filtersSwitchButton';
import { findIndexItem } from '../../utils/helpers';
import {
  getMultipleGroupeSearch,
  createStateCheckbox,
  resetStateCheckBox,
  checkStringLength, checkboxViewHandler, checkEmptyFilterHandler, dateTypeHandler
} from '../../utils/helperFilter';
import GroupDropSelector from './GroupDropSelector';
import GroupCheckboxSelector from './GroupCheckboxSelector';
import RangePanel from './RangePanel';
import CheckboxPanel from './CheckboxPanel';
import filters from '../../constants/filters';
import numberConstants from '../../constants/numberConstants';
import DatePicker from '../DatePicker/DatePicker';
import {
  closeAll, setInitialFilterSimple, saveStateInitialGroup, initTab, initCheckboxState
} from '../../utils/commonGroupFilterHandler';
import { deepEqual } from '../../utils/objectHelper';
import { TCommonItem, TDefineColumn, TFilterItem } from '../FilterScreen/Types';

interface IFilterGroupEmaSma {
  title: string;
  addFavorite: () => void;
  favoriteActive: boolean;
  disabled: boolean;
  disabledHeart: boolean;
  filter: TFilterItem;
  noOptionsAvailable: string;
  setArray: (array: (number | number[])[]) => void;
  viewRange: boolean;
  type: string;
  getTypeFilter: (columnsList: TDefineColumn[], id: number) => string | undefined;
  columnsList: TDefineColumn[];
  activeCheckbox?: boolean;
  deepGroupDrop?: boolean;
  filterData: TCommonItem[];
  bottomFlag: boolean;
  searchFilter: number;
  category: string;
}
const areEqual = (
  prevProps: IFilterGroupEmaSma,
  nextProps: IFilterGroupEmaSma
) => prevProps.bottomFlag === nextProps.bottomFlag
    && prevProps.searchFilter === nextProps.searchFilter
    && deepEqual(prevProps.filterData, nextProps.filterData)
    && deepEqual(prevProps.filter, nextProps.filter)
    && prevProps.activeCheckbox === nextProps.activeCheckbox
    && prevProps.setArray === nextProps.setArray
    && deepEqual(prevProps.setArray, nextProps.setArray)
    && prevProps.filterData === nextProps.filterData;

const FilterGroupEmaSma = ({
  title, addFavorite, favoriteActive, disabled, disabledHeart, filter, noOptionsAvailable,
  setArray, viewRange, type, getTypeFilter, columnsList, activeCheckbox,
  deepGroupDrop, filterData, bottomFlag, searchFilter, category
}: IFilterGroupEmaSma): React.ReactElement => {
  const { t } = useTranslation();
  const [activeTab, setActiveTabs] = useState<Record<string, boolean>>({
    greater: true,
    less: false,
    range: false
  });
  const [filterState, setFilterState] = useState<TCommonItem[]>(filterData);
  const [selectedGroup, setSelectedGroup] = useState<TCommonItem>({} as TCommonItem);
  const [selectFilter, setSelectFilter] = useState<TCommonItem>({} as TCommonItem);
  const [initialSelectedFilter, setInitialSelectedFilter] = useState<TCommonItem>({} as TCommonItem);
  const [initialSelectedGroup, setInitialSelectedGroup] = useState<TCommonItem>({} as TCommonItem);
  const [listCheckBox, setListCheckBox] = useState<Record<number, boolean>>({});
  const [handleTab, setHandleTab] = useState<boolean>(false);
  const [viewDropList, setViewDropList] = useState<boolean>(false);
  const [viewDropListSingle, setViewDropListSingle] = useState<boolean>(false);
  const [viewDropListSingleSecond, setViewDropListSingleSecond] = useState<boolean>(false);
  const [checkDropLabel, setCheckDropLabel] = useState<boolean>(false);
  const checkboxView = checkboxViewHandler(filter);
  const checkEmptyFilter = checkEmptyFilterHandler(initialSelectedFilter as TFilterItem, selectFilter as TFilterItem);
  const checkEmptyState = selectFilter?.fVal;
  const stylesWrapper = checkDropLabel ? styles.longTextContainer : '';
  const dateType = dateTypeHandler(selectFilter);

  const checkInitialData = () => JSON.stringify(filterState) === JSON.stringify(filter?.data);

  useEffect(() => {
    if (filterData.length > 0) {
      setFilterState(filterData);
    }
  }, [filterData]);

  const tabsHandler = (item: string): void => {
    const body: {
      [key: string]: boolean
    } = { ...filters.initialBody };
    setHandleTab(true);
    body[item] = true;
    setActiveTabs(body);
  };

  const setCheckHandler = (columnId: number): void => {
    const indexItem = findIndexItem(filterState, 'id', columnId);
    if (indexItem !== -1) {
      setSelectFilter(filterState[indexItem as number]);
      setInitialSelectedFilter(filter?.data[indexItem]);
      const newStateCheckbox = resetStateCheckBox(listCheckBox);
      newStateCheckbox[columnId] = true;
      setListCheckBox(newStateCheckbox);
    }
  };

  const handlerSelectFilter = (filterItem: TCommonItem): void => {
    setSelectFilter(filterItem);
  };

  const handlerSelectGroupFilter = (group: TCommonItem): void => {
    setSelectedGroup(group);
  };

  const setCheck = (index: string) => {
    let newIndex;
    const newGroup = JSON.parse(JSON.stringify(filterState));
    const indexItem = findIndexItem(selectFilter?.fVal, filters.value, index);
    if (selectFilter?.fVal) {
      newIndex = selectFilter?.fVal[indexItem]?.checked ? -1 : index;
    }
    const newObj = { ...selectFilter };
    newObj.fVal = newObj.fVal.map((el) => {
      const newItem = { ...el };
      if (index === el?.value && !el.checked) {
        newItem.checked = true;
      } else {
        newItem.checked = false;
      }
      return newItem;
    });

    const indexFilter = findIndexItem(newGroup, filters.id, newObj?.id);
    if (indexFilter !== -1) {
      newGroup[indexFilter] = newObj;
      setFilterState(newGroup);
    }

    setArray([selectFilter?.id, 0, [newIndex as number]]);
  };

  useEffect(() => {
    const check = checkStringLength(filter, numberConstants.lengthText);
    setCheckDropLabel(check);
  }, [filter]);

  useEffect(() => {
    if (filterState[0]?.data?.length === 0 && filterState?.length > 0
      && activeCheckbox && !deepGroupDrop) {
      initCheckboxState(null, filterState, filter?.data,
        setSelectFilter, setInitialSelectedFilter, createStateCheckbox, setListCheckBox);
    }
    if (type === filters.range && filterState[0]?.data?.length) {
      const filterFirst = filterState[0]?.data[0];
      if (filterFirst) {
        const firstRangeSettings = filterFirst?.fVal;
        if (firstRangeSettings?.length) {
          initTab(setHandleTab);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (deepGroupDrop && Object.keys(selectFilter).length === 0 && !activeCheckbox) {
      const initialFilterItem = filterState[0]?.data?.filter((el) => el.fShow)[0];
      setSelectFilter(initialFilterItem as TCommonItem);
    }
  }, [filterState]);

  useEffect(() => {
    if (deepGroupDrop && Object.keys(selectFilter).length > 0) {
      let item = {};
      filter?.data?.forEach((element) => {
        const indexItem = findIndexItem(element?.data, filters.id, selectFilter?.id);
        if (indexItem !== -1) {
          const elementData = element?.data as TCommonItem[];
          item = elementData[indexItem];
        }
      });
      setInitialSelectedFilter(item as TCommonItem);
    }
  }, [selectFilter]);

  useEffect(() => {
    if (!searchFilter) return;
    if (activeCheckbox && !deepGroupDrop) {
      initCheckboxState(searchFilter, filterState, filter?.data,
        setSelectFilter, setInitialSelectedFilter, createStateCheckbox, setListCheckBox);
    }
    const searchedElement = getMultipleGroupeSearch(filterState, searchFilter);
    if (searchedElement?.group) {
      setSelectedGroup(searchedElement?.group);
    }
    if (searchedElement?.selector) {
      setSelectFilter(searchedElement?.selector);
      const firstRangeSettings = searchedElement?.selector?.fVal;
      if (firstRangeSettings?.length) {
        initTab(setHandleTab);
      }
    }
  }, [searchFilter, selectFilter, selectedGroup]);

  const saveFilterSearch = (): void => {
    if (filterState?.length
      && filterState[0]?.data?.length
      && filterState?.length !== 0
      && filterState[0]?.data?.length !== 0
    ) {
      const filterFirstState = filterState[0]?.data[0] as TCommonItem;
      setSelectFilter(filterFirstState);
    } else {
      setSelectFilter(filterState[0]);
    }
  };

  const checkAndSetFromSearch = (): void => {
    const searchedElement = getMultipleGroupeSearch(filterState, searchFilter);
    if (searchedElement?.selector) {
      setSelectFilter(searchedElement?.selector);
    } else {
      saveFilterSearch();
    }
  };

  const filtersExistingCheck = (
    filtersList: TCommonItem,
    group: TCommonItem
  ): boolean => (filtersList && Object.keys(filtersList).length > 0) && (group && Object.keys(group).length > 0);

  useEffect(() => {
    if (filterState[0]?.data?.length === 0 && filterState?.length > 0
      && !deepGroupDrop && !activeCheckbox) {
      if (Object.keys(selectFilter).length > 0) {
        const indexItem = findIndexItem(filterState, filters.id, selectFilter?.id);
        if (indexItem !== -1) {
          setSelectFilter(filterState[indexItem]);
        }
      } else {
        if (searchFilter) {
          checkAndSetFromSearch();
        } else {
          setSelectFilter(filterState[0]);
        }
        setInitialSelectedFilter(filter.data[0]);
      }
    }
  }, [filterState]);

  useEffect(() => {
    if (!filterState[0]?.data?.length
        && filterState?.length > 0
        && !deepGroupDrop
        && !activeCheckbox
        && Object.keys(selectFilter).length > 0) {
      const indexItem = findIndexItem(filter.data, filters.id, selectFilter?.id);
      if (indexItem !== -1) {
        setInitialSelectedFilter(filter.data[indexItem]);
      }
    }
  }, [selectFilter]);

  useEffect(() => {
    if (filterState[0]?.data?.length
      && filterState[0]?.data[0]?.data?.length === 0
      && filterState[0]?.data?.length > 0
    ) {
      if (selectedGroup && Object.keys(selectedGroup).length > 0) {
        const indexItem = findIndexItem(filterState, filters.name, selectedGroup?.name);
        if (indexItem !== -1 && !checkInitialData()) {
          setSelectedGroup(filterState[indexItem]);
          setInitialSelectedGroup(filter.data[indexItem]);
        }
        if (checkInitialData()) {
          setSelectedGroup(filterState[0]);
          setInitialSelectedGroup(filter.data[0]);
        }
      } else {
        saveStateInitialGroup(filter?.data, filterState, setInitialSelectedGroup, setSelectedGroup);
      }
    }
  }, [filterState]);

  useEffect(() => {
    if (filterState[0]?.data?.length
      && filterState[0]?.data[0]?.data?.length === 0
      && filterState[0]?.data?.length > 0) {
      if (selectedGroup && Object.keys(selectedGroup).length > 0) {
        const indexItem = findIndexItem(filter.data, filters.name, selectedGroup?.name);
        const indexFilter = findIndexItem(selectedGroup?.data, filters.id, selectFilter?.id);
        const filterStateIndexData = filterState[indexItem]?.data as TCommonItem[];
        const filterIndexData = filter?.data[indexItem]?.data as TCommonItem[];
        if (indexItem !== -1) {
          setInitialSelectedGroup(filter?.data[indexItem]);
          if (indexFilter !== -1 && filterState[indexItem] && filter?.data[indexItem]) {
            setSelectFilter(filterStateIndexData[indexFilter]);
            setInitialSelectedFilter(filterIndexData[indexFilter]);
          } else {
            if (searchFilter) {
              checkAndSetFromSearch();
            } else {
              setSelectFilter(filterStateIndexData?.filter((el) => el.fShow)[0]);
            }
            setInitialSelectedFilter(filterIndexData?.filter((el) => el.fShow)[0]);
          }
        }
      } else {
        const filterFirstState = filterState[0]?.data[0]?.data as TCommonItem[];
        if (searchFilter) {
          checkAndSetFromSearch();
        } else {
          setSelectFilter(filterState[0].data.filter((el) => el.fShow)[0]);
        }
        setInitialSelectedFilter(filterFirstState?.filter((el) => el.fShow)[0]);
        setInitialSelectedGroup(filter.data.filter((el) => el.fShow)[0]);
      }
    }
    if (
      filterState[0]?.data?.length
      && filterState[0]?.data[0]?.data?.length
      && filterState[0]?.data[0]?.data?.length > 0
    ) {
      if (selectedGroup && Object.keys(selectedGroup)?.length > 0) {
        const indexItem = findIndexItem(filter?.data, filters?.name, selectedGroup?.name);
        const filterStateData = filterState[indexItem]?.data as TCommonItem[];
        const filterStateFistData = filterStateData[0]?.data as TCommonItem[];
        const filterDatandex = filter?.data[indexItem]?.data as TCommonItem[];
        const filterFirstData = filterDatandex[0]?.data as TCommonItem[];
        setSelectFilter(filterStateFistData?.filter((el) => el?.fShow)[0]);
        setInitialSelectedFilter(filterFirstData?.filter((el) => el.fShow)[0]);
        setInitialSelectedGroup(filter.data[indexItem]);
      }
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (
      filterState[0]?.data?.length
      && filterState[0]?.data[0]?.data?.length === 0
      && filterState[0]?.data?.length > 0
    ) {
      if (filtersExistingCheck(selectFilter, selectedGroup)) {
        const indexFilter = findIndexItem(selectedGroup?.data, filters?.id, selectFilter?.id);
        if (indexFilter !== -1) {
          const selectedGroupeData = selectedGroup?.data as TCommonItem[];
          const initialSelectedGroupData = initialSelectedGroup?.data as TCommonItem[];
          setSelectFilter(selectedGroupeData[indexFilter]);
          setInitialSelectedFilter(initialSelectedGroupData[indexFilter]);
          const rangeData = selectedGroupeData[indexFilter]?.fVal;
          if (rangeData?.length) {
            initTab(setHandleTab);
          }
        }
      }
    }
    if (filterState?.length && filterState[0]?.data?.length === 0 && filterState[0]?.fType === 'range') {
      setInitialFilterSimple(filter?.data, selectFilter?.id, setInitialSelectedFilter);
      const rangeData = selectFilter?.fVal;
      if (rangeData?.length) {
        initTab(setHandleTab);
      }
    }
  }, [selectFilter]);

  useEffect(() => {
    if (
      filterState[0]?.data?.length
      && filterState[0]?.data[0]?.data?.length
      && filterState[0]?.data[0]?.data?.length > 0
    ) {
      if (selectedGroup && Object.keys(selectedGroup)?.length > 0) {
        const indexItem = findIndexItem(filterState, filters?.name, selectedGroup?.name);
        if (indexItem !== -1) {
          setSelectedGroup(filterState[indexItem]);
          setInitialSelectedGroup(filter?.data[indexItem]);
        }
      } else {
        saveStateInitialGroup(filter?.data, filterState, setInitialSelectedGroup, setSelectedGroup);
      }
    }
  }, [filterState]);

  const getGroupeDeepSecond = (): boolean => {
    const currentFirstFilterState = filterState[0]?.data as TCommonItem[];
    const currentSecondFilterState = currentFirstFilterState[0]?.data as TCommonItem[];
    return (currentSecondFilterState?.length === 0
      && currentFirstFilterState?.length > 0) || currentSecondFilterState?.length > 0;
  };

  return (
    <FilterLayout
      id={title}
      title={title}
      columnId={title as never as number}
      addButtonHandler={addFavorite}
      favoriteActive={favoriteActive}
      disabled={disabled}
      description={selectFilter?.desc || ''}
      disabledHeart={disabledHeart}
      category={category}
      handlerCloseDropdown={() => closeAll(setViewDropListSingle, setViewDropListSingleSecond)}
    >
      {filter?.data?.length > 0 ? (
        <div className={styles.container}>
          <div className={`${styles.firstContainerContent} ${stylesWrapper}`}>
            <GroupCheckboxSelector
              bottomFlag={bottomFlag}
              setCheckHandler={setCheckHandler}
              activeCheckbox={activeCheckbox as boolean}
              filter={filterState}
              listCheckBox={listCheckBox}
              viewDropList={viewDropList}
              selectFilter={selectFilter}
              deepGroupDrop={deepGroupDrop as boolean}
              selectedGroup={selectedGroup}
              setViewDropList={setViewDropList}
              viewDropListSingle={viewDropListSingle}
              handlerSelectFilter={handlerSelectFilter}
              setViewDropListSingle={setViewDropListSingle}
              handlerSelectGroupFilter={handlerSelectGroupFilter}
              groupDeepSecond={getGroupeDeepSecond()}
            />
            <GroupDropSelector
              bottomFlag={bottomFlag}
              handlerSelectFilter={handlerSelectFilter}
              filter={filterState}
              selectFilter={selectFilter}
              selectedGroup={selectedGroup}
              deepGroupDrop={deepGroupDrop as boolean}
              viewDropList={viewDropList}
              setViewDropList={setViewDropList}
              viewDropListSingleSecond={viewDropListSingleSecond}
              setViewDropListSingleSecond={setViewDropListSingleSecond}
            />
          </div>
          {checkboxView && !dateType && (
            <>
              {checkEmptyFilter && (
              <RangePanel
                activeTab={activeTab}
                tabsHandler={tabsHandler}
                viewRange={viewRange}
                initialMax={Object.keys(initialSelectedFilter)?.length > 0
                          && initialSelectedFilter?.fVal[1]?.value as number}
                initialMin={Object.keys(initialSelectedFilter).length > 0
                          && initialSelectedFilter?.fVal[0]?.value as number}
                setArray={setArray}
                columnId={selectFilter?.id}
                typeData={getTypeFilter(columnsList, selectFilter?.id)}
                minValue={selectFilter?.fVal[0]?.value as number}
                maxValue={selectFilter?.fVal[1]?.value as number}
                listButton={filtersSwitchButton}
                handleTab={handleTab}
                noOptionsAvailable={noOptionsAvailable}
              />
              )}
            </>
          )}
          {!checkboxView && !dateType && (
            <>
              {checkEmptyState && (
              <CheckboxPanel
                setCheck={setCheck}
                buttons={selectFilter?.fVal}
                noOptionsAvailable={noOptionsAvailable}
              />
              )}
            </>
          )}
          {dateType && (
            <DatePicker
              searchFilter={searchFilter}
              title={selectFilter?.name}
              stateFiltersHandler={setArray}
              placeholder={t('selectDate')}
              placeholderPeriod={t('dateRange')}
              applyText={t('apply')}
              cancelText={t('cancel')}
              columnId={selectFilter?.id}
              rangeButtonType={selectFilter?.fType.split(':')[1]}
              filter={selectFilter || {}}
              categoryName={category}
              favoriteActive={favoriteActive}
              addFavorite={addFavorite}
              disabled={disabled}
              disabledHeart={disabledHeart}
              description={selectFilter?.desc}
              hideLayout
            />
          )}
        </div>
      ) : (
        <NoOptions text={noOptionsAvailable} />
      )}
    </FilterLayout>
  );
};

export default React.memo(FilterGroupEmaSma, areEqual);
