import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import {
  setETFSymbolIndex,
  setSortData,
  setLoaderAction,
  setMarketsFilterScreenerAction,
  setSearchHeaderValue,
  setSearchValue,
} from '../../store/screener/actions';
import ROLES from '../../constants/authRoles';
import {
  DEFAULT_PAGINATION_STATE,
  FILTER_SETTINGS,
  KEY_CODES,
  HIDEN_SEARCH_ELEMENTS,
  SCREENER_VIEWS,
  TABLE_SCREEN_PATH,
  holdingsIndex,
} from '../../constants/screener';
import useSearch from './useSearch';
import useMultiChartWorker from '../../components/MultichartView/hooks/useMultiChartWorker';
import {
  setGlobalSearchOnKeyDown,
  setGlobalSearchVisible
} from '../../store/account/actions';
import { setPrevScreen, setSelectedScreen } from '../../store/newScreen/actions';
import { setRequestStateAction } from '../../store/filters/slice';
import { setVisibleItemsTableStore, changeTabAction } from '../../store/tableData/slice';
import { sharedChartData } from '../../components/ChartContainer/constants/constants';

const useGlobalSearch = (
  currentModalRef = null
) => {
  const dispatch = useDispatch();
  const [searchDropdownVisible, setSearchDropdownVisible] = useState(false);
  const [activeModalWrapper, setActiveModalWrapper] = useState(false);
  const [activeSymbolIndexValue, setActiveSymbolIndex] = useState();
  const searchHeaderValue = useSelector((state) => state.screenerState.globalSearchHeaderValue, shallowEqual);
  const itemId = useSelector((state) => state.newScreenState.itemId, shallowEqual);
  const accountPlan = useSelector((state) => state.accountState.userProfile?.role, shallowEqual);
  const screenerView = useSelector((state) => state.accountState.userSettings.screenerView, shallowEqual);
  const globalSearchVisible = useSelector((state) => state.accountState.globalSearchVisible, shallowEqual);
  const selectedTab = useSelector((state) => state.watchlistState.selectedTab, shallowEqual);
  const prevScreen = useSelector((state) => state.newScreenState.prevScreen, shallowEqual);
  const pagePath = useSelector((state) => state.screenerState.pagePath, shallowEqual);
  const sortData = useSelector((state) => state.screenerState.sortData, shallowEqual);
  const selectedShapeId = useSelector((state) => state.screenerState.selectedShapeId, shallowEqual);

  const {
    handlerSearch, search, lazyResult, setSearch, setPagingHandler
  } = useSearch();

  const { refreshSymbolsMultiChart } = useMultiChartWorker(false);

  const checkOptionalKeys = (event) => {
    const {
      altKey, ctrlKey, shiftKey, metaKey
    } = event;
    return !(altKey || ctrlKey || shiftKey || metaKey);
  };

  const setGlobalSearchVisibleToggle = useCallback(
    (value, isOnKeyDown = false) => {
      dispatch(setGlobalSearchVisible(value));
      if (!isOnKeyDown) dispatch(setGlobalSearchOnKeyDown(false));
    },
    [],
  );

  const shouldGlobalSearchBeVisible = (event) => (
    !activeModalWrapper
    && !currentModalRef?.current
    && KEY_CODES.LETTERS.includes(event.key)
    && accountPlan !== ROLES.FREE
    && !globalSearchVisible
    && !sharedChartData.preventGlobalSearch
    && !selectedShapeId
    && !HIDEN_SEARCH_ELEMENTS?.includes(event?.target?.tagName)
    && checkOptionalKeys(event)
  );

  const globalSearchKeyHandler = useCallback(
    (event) => {
      if (shouldGlobalSearchBeVisible(event)) {
        dispatch(setGlobalSearchOnKeyDown(true));
        event.stopPropagation();
        event.preventDefault();
        setSearch(event.key);
        setGlobalSearchVisibleToggle(true, true);
      }
    },
    [search, globalSearchVisible],
  );

  useEffect(() => {
    window.addEventListener('keydown', globalSearchKeyHandler);

    return () => {
      window.removeEventListener('keydown', globalSearchKeyHandler);
    };
  }, [accountPlan]);

  const checkWatchlistFilters = (filtersData) => {
    if (filtersData?.length === 0) {
      return [[[FILTER_SETTINGS.ZERO, FILTER_SETTINGS.ZERO, []]]];
    }

    return filtersData;
  };

  const resetDashboardSearch = () => {
    setSearch('');
    dispatch(setSearchHeaderValue(''));
    dispatch(setSearchValue({}));
    setActiveSymbolIndex(undefined);
    sharedChartData.activeSymbolIndex = undefined;
  };

  const resetSearchHandler = useCallback(
    (currentTab = {}) => {
      const currentTabData = Object.keys(currentTab).length > 0
        ? currentTab?.data?.filters : selectedTab?.data?.filters;
      setActiveSymbolIndex(undefined);
      sharedChartData.activeSymbolIndex = undefined;
      dispatch(setLoaderAction(true));
      dispatch(setETFSymbolIndex(null));
      dispatch(setSortData(sortData.filter((sort) => sort.indexElement !== holdingsIndex)));
      dispatch(changeTabAction());
      setSearch('');
      setSearchDropdownVisible(false);
      dispatch(setSearchHeaderValue(''));
      dispatch(setSearchValue({}));
      dispatch(setVisibleItemsTableStore(DEFAULT_PAGINATION_STATE));

      if (itemId.isHoldingView) {
        if (prevScreen) {
          dispatch(setSelectedScreen({ ...prevScreen, isHoldingView: false }));
          if (pagePath === TABLE_SCREEN_PATH.SCREENER) {
            dispatch(setMarketsFilterScreenerAction({ data: prevScreen?.data?.filters || [] }));
            dispatch(setRequestStateAction(prevScreen?.data?.filters || []));
          }
        } else {
          dispatch(setSelectedScreen({ isHoldingView: false }));
        }
        dispatch(setPrevScreen(null));
        dispatch(setMarketsFilterScreenerAction(
          {
            data: pagePath !== TABLE_SCREEN_PATH.SCREENER ? checkWatchlistFilters(currentTabData) : [],
          }
        ));
        dispatch(setRequestStateAction([]));
      } else {
        dispatch(setMarketsFilterScreenerAction(
          {
            data: pagePath !== TABLE_SCREEN_PATH.SCREENER ? checkWatchlistFilters(currentTabData)
              : itemId?.data?.filters
          }
        ));
      }

      if (screenerView === SCREENER_VIEWS.CHART) {
        refreshSymbolsMultiChart(
          true, pagePath !== TABLE_SCREEN_PATH.SCREENER ? checkWatchlistFilters(currentTabData) : itemId?.data?.filters
        );
      }
    },
    [itemId, pagePath, sortData, selectedTab, prevScreen],
  );

  const setActiveSymbolValue = useCallback(
    (activeSymbolIndex, activeSymbolOrderValue, isSingleChange = false) => {
      if (isSingleChange) {
        setActiveSymbolIndex(activeSymbolIndex);
        sharedChartData.activeSymbolIndex = activeSymbolIndex;
      } else {
        dispatch(setLoaderAction(true));
        dispatch(setSearchHeaderValue(activeSymbolOrderValue.sym));
        dispatch(setVisibleItemsTableStore(DEFAULT_PAGINATION_STATE));
        dispatch(setSearchValue({
          name: activeSymbolOrderValue.sym,
          index: activeSymbolOrderValue.index
        }));
        dispatch(changeTabAction());
        dispatch(setMarketsFilterScreenerAction({ data: [[[0, 0, [activeSymbolIndex]]]] }));
        setActiveSymbolIndex(activeSymbolIndex);
        sharedChartData.activeSymbolIndex = activeSymbolIndex;
      }
    },
    [searchHeaderValue, search],
  );

  return {
    searchHeaderValue,
    searchDropdownVisible,
    setActiveModalWrapper,
    resetSearchHandler,
    handlerSearch,
    search,
    lazyResult,
    setPagingHandler,
    setActiveSymbolValue,
    setSearchDropdownVisible,
    globalSearchVisible,
    setGlobalSearchVisible: setGlobalSearchVisibleToggle,
    setSearch,
    resetDashboardSearch,
    globalSearchKeyHandler,
    activeSymbolIndexValue
  };
};

export default useGlobalSearch;
