import React from 'react';

import styles from '../sass/DashboardComponents.module.scss';
import DashboardItemContent from './DashboardItemContent';
import { RENDERED_ITEM_SELECTOR } from '../../../pages/Dashboards/types/DashboardEnums';
import { IDashboardGridResizeItem } from '../types/DashboardComponentsInterfaces';

const DashboardGridResizeItem = ({
  widget,
  updateWidget,
  removeWidget,
  activeSymbolIndexValue,
  changeSymbolForGroup,
  setColorGroup,
  setLastActiveGroup,
  lastActiveGroup,
  setSearch,
  resetDashboardSearch,
  globalSearchKeyHandler,
  dashboardId,
  isEditable
}: IDashboardGridResizeItem): React.ReactElement => (
  <div
    aria-hidden
    onClick={() => setLastActiveGroup(widget)}
    key={['widget', widget.id].join('-')}
    className={`grid-stack-item ${styles.gridStackItem} ${RENDERED_ITEM_SELECTOR}`}
    id={`gs-item-${widget.id}`}
    gs-w={widget.points.w}
    gs-h={widget.points.h}
    gs-x={widget.points.x}
    gs-y={widget.points.y}
  >
    <div className={`grid-stack-item-content ${styles.gridStackContent}`}>
      <DashboardItemContent
        changeSymbolForGroup={changeSymbolForGroup}
        setColorGroup={setColorGroup}
        updateWidget={updateWidget}
        removeWidget={removeWidget}
        activeSymbolIndexValue={activeSymbolIndexValue}
        lastActiveGroup={lastActiveGroup}
        widget={widget}
        resetDashboardSearch={resetDashboardSearch}
        setSearch={setSearch}
        globalSearchKeyHandler={globalSearchKeyHandler}
        dashboardId={dashboardId}
        isEditable={isEditable}
      />
    </div>
  </div>
);

export default DashboardGridResizeItem;
