import React, { useState, useEffect, useRef } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import Portal from '@mui/material/Portal';
import SocketContext from './SocketContext';
import SocketConnection from '../services/SocketConnectionService';
import RootStateTypes from '../store/RootStateTypes';
import BannerMobile from '../components/BannerMobile/BannerMobile';
import { ISocketProviderContextValue } from './Interfaces';
import { setDisconnectPopupAction } from '../store/auth/actions';
import { ReactComponent as ErrorIcon } from '../assets/images/icons/accountSettings/errorBoundary.svg';
import UniqIdTabService from '../services/UniqIdTabService';
import { useTheme } from '../utils/hooks/useTheme';
import { EROR_MESSAGES, WS_CHANNELS } from '../constants/socketConnection';
import { SocketConnectionService } from '../services/Interfaces';
import { setQueryScreenerId, setDataPanelsQueryId } from '../store/tableData/slice';
import useAuth from '../utils/hooks/useAuth';
import useViewListener from '../utils/hooks/useViewListener';
import { cleanWidgetConnectionQueries } from '../store/dashboards/slice';
import { getFromLocalStorage } from '../utils/storageWorks';

const SocketProvider: React.FunctionComponent<{
  socketInstanceCallback: (socketService: SocketConnectionService) => void
}> = (
  { children, socketInstanceCallback }
) => {
  let tryTimeOut: NodeJS.Timeout | undefined;
  const connectionRef = useRef< SocketConnectionService | null>(null);
  const valueRef = useRef< ISocketProviderContextValue | null>(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { theme } = useTheme();
  const { checkAuthWorker } = useAuth();

  const userProfileId = useSelector(
    (state:RootStateTypes) => state.accountState.userProfile?.id, shallowEqual
  );

  const disconnectPopup = useSelector((state:RootStateTypes) => state.authState.disconnectPopup, shallowEqual);
  const queryScreenerId = useSelector((state: RootStateTypes) => state.tableDataState.queryScreenerId, shallowEqual);
  const widgetConnectionQueries = useSelector(
    (state: RootStateTypes) => state.dashboardsState.widgetConnectionQueries, shallowEqual
  );

  const [value, setValue] = useState<ISocketProviderContextValue>({
    Socket: null,
    Authorized: false,
    Connection: null,
  });
  const [isFirstConnect, setIsFirstConnect] = useState(true);
  const [status, setStatus] = useState(true);
  const [titleBanner, setTitleBanner] = useState('');
  const [isConnectionLoss, setIsConnectionLoss] = useState(false);

  const isWebView = getFromLocalStorage('isWebView');

  const cleanCallback = () => {
    dispatch(setQueryScreenerId(null));
    dispatch(setDataPanelsQueryId(null));
    cleanWidgetConnectionQueries();
    connectionRef.current = null;
    valueRef.current = null;
  };

  const authorizedCallback = (isAuthorize: boolean, connectionService: SocketConnectionService) => {
    if (isAuthorize) {
      // eslint-disable-next-line no-console
      console.log('auth socket ==>', connectionService?.socketId);
    } else {
      // eslint-disable-next-line no-console
      console.log('cleanUp SocketConectService ==>', connectionService?.socketId);
    }
    const newState = {
      Authorized: isAuthorize,
      Connection: isAuthorize ? connectionService : null,
      Socket: isAuthorize ? connectionService?.getSocket() : null,
    };
    if (isAuthorize) {
      connectionRef.current = connectionService;
    } else {
      setIsConnectionLoss(true);
    }
    socketInstanceCallback(connectionService);
    setValue((oldState) => {
      const data = { ...oldState, ...newState };
      valueRef.current = data;
      return data;
    });
  };

  const connectionRemoveCallback = (message: string) => {
    if (message === EROR_MESSAGES.LIMIT_EXCEEDED) {
      setTitleBanner(`${t('limitExceeded')} ${t('limitExceededDescription')}`);
    }
    dispatch(setDisconnectPopupAction(true));
    // eslint-disable-next-line no-console
    console.log('remove from server ==>', message);
  };

  const setSubscribe = () => {
    const socketConnection = new SocketConnection(
      {
        userId: userProfileId,
        authorizedCallback,
        connectionRemoveCallback,
        cleanCallback,
        setIsConnectionLoss
      }
    );
    socketConnection.subscribe();
    setValue((oldState) => {
      const data = { ...oldState, ...{ Socket: socketConnection.getSocket(), Connection: socketConnection } };
      valueRef.current = data;
      return data;
    });
    connectionRef.current = socketConnection;
  };

  const setUpdateSocket = (): void => {
    valueRef.current?.Connection?.sendMessage(WS_CHANNELS.CONNECTION_CHECK_PING, { messageId: `${queryScreenerId}` });

    if (connectionRef.current?.getSocket()?.readyState !== WebSocket.OPEN
      && (queryScreenerId || Object.keys(widgetConnectionQueries))
      && !disconnectPopup
    ) {
      connectionRef.current?.unsubscribe();
      cleanCallback();
      checkAuthWorker();
      // eslint-disable-next-line no-console
      console.log('==> socket subscribe after leave tab');
      setSubscribe();
    }
  };

  const awakeSocketWidget = () => {
    connectionRef.current?.unsubscribe();
    cleanCallback();
    checkAuthWorker();
    // eslint-disable-next-line no-console
    console.log('=> socket awake screener');
    setSubscribe();
  };

  const checkConnectionHandler = (res: { errors: string[] | [] }) => {
    if (res.errors.length) {
      awakeSocketWidget();
    }
  };
  useViewListener(setUpdateSocket);

  const stayOnThisDeviceHandler = () => {
    UniqIdTabService.removeUniqId();
    dispatch(setDisconnectPopupAction(false));
    window.location.reload();
  };

  useEffect(() => {
    if (userProfileId && !value?.Socket && !isWebView) {
      setIsFirstConnect(false);
      setSubscribe();
    }

    value.Connection?.messageEmitter.on(WS_CHANNELS.CONNECTION_CHECK_PONG, checkConnectionHandler);
    return () => {
      value.Connection?.messageEmitter.off(WS_CHANNELS.CONNECTION_CHECK_PONG, checkConnectionHandler);
    };
  }, [userProfileId, value?.Authorized]);

  useEffect(() => {
    if (!isFirstConnect && isConnectionLoss && !value?.Authorized && !isWebView && tryTimeOut === undefined) {
      tryTimeOut = setTimeout(() => {
        setUpdateSocket();
        setIsConnectionLoss(false);
      }, 4000);
    }
    if (value?.Authorized) {
      clearTimeout(tryTimeOut);
      tryTimeOut = undefined;
    }
  }, [isConnectionLoss]);

  useEffect(() => {
    if (userProfileId && !value?.Socket && isWebView) {
      setSubscribe();
    }
  }, [userProfileId]);

  useEffect(() => {
    if (
      value?.Connection
      && value?.Socket
      && !userProfileId) {
      value?.Connection?.unsubscribe();
      const newState = {
        Socket: null,
        Connection: null,
        Authorized: false,
      };
      setValue((oldState) => {
        const data = { ...oldState, ...newState };
        valueRef.current = data;
        return data;
      });
    }
  }, [userProfileId]);

  useEffect(() => {
    if (status && !isFirstConnect) {
      // eslint-disable-next-line no-console
      console.log('==> socket subscribe after online');
      setUpdateSocket();
    }
    if (!status) {
      // eslint-disable-next-line no-console
      console.log('==> clean socket after offline');
      connectionRef.current?.unsubscribe();
      cleanCallback();
    }
  }, [status]);

  useEffect(() => {
    function changeStatus() {
      setStatus(navigator.onLine);
    }
    window.addEventListener('online', changeStatus);
    window.addEventListener('offline', changeStatus);
    return () => {
      value?.Connection?.messageEmitter.removeAllListeners();
      const newState = {
        Socket: null,
        Authorized: false,
      };
      setValue((oldState) => {
        const data = { ...oldState, ...newState };
        valueRef.current = data;
        return data;
      });
      window.removeEventListener('online', changeStatus);
      window.removeEventListener('offline', changeStatus);
    };
  }, []);

  return (
    <SocketContext.Provider value={{ ...value, awakeSocketWidget }}>
      <>
        {disconnectPopup && (
          <Portal>
            <BannerMobile
              theme={theme}
              Icon={ErrorIcon}
              title={titleBanner}
              headText={t('limitHeader')}
              additionalClass="fullScreenBanner"
              buttonText={t('stayOnThisDevice')}
              handlerClickButton={stayOnThisDeviceHandler}
            />
          </Portal>
        )}
        { children }
      </>
    </SocketContext.Provider>
  );
};

export default SocketProvider;
