import React from 'react';

import DashboardsView from './DashboardsView';
import UseDashboards from './hooks/UseDashboards';
import useGlobalSearch from '../../utils/hooks/useGlobalSearch';
import { HOLDING_TYPE } from './types/DashboardEnums';

const Dashboards = (): React.ReactElement => {
  const {
    setCurrentDashboard,
    updateWidget,
    createDashboard,
    removeDashboard,
    renameDashboard,
    globalHoldingSearch,
    handleSetGlobalHoldingSearch,
    isUpdatedAllData,
    globalSectorIndustrySearch,
    handleSetGlobalSectorIndustrySearch
  } = UseDashboards();

  const {
    searchDropdownVisible,
    handlerSearch,
    search,
    searchHeaderValue,
    setPagingHandler,
    setSearchDropdownVisible,
    globalSearchVisible,
    setGlobalSearchVisible,
    setSearch,
    lazyResult,
    activeSymbolIndexValue,
    setActiveSymbolValue,
    resetDashboardSearch,
    resetSearchHandler,
    globalSearchKeyHandler
  } = useGlobalSearch();

  const handleSetSearch = (value: string): void => {
    if (value?.match(HOLDING_TYPE)) {
      handleSetGlobalHoldingSearch(true);
    }
    setSearch(value);
  };

  const handleSearchReset = (): void => {
    resetSearchHandler();
    if (globalHoldingSearch) {
      handleSetGlobalHoldingSearch(false);
    }
    if (globalSectorIndustrySearch) {
      handleSetGlobalSectorIndustrySearch(false);
    }
  };

  return (
    <DashboardsView
      createDashboard={createDashboard}
      search={search}
      searchHeaderValue={searchHeaderValue}
      handlerSearch={handlerSearch}
      setGlobalSearchVisible={setGlobalSearchVisible}
      setSearch={handleSetSearch}
      setPagingHandler={setPagingHandler}
      globalSearchVisible={globalSearchVisible}
      setCurrentDashboard={setCurrentDashboard}
      searchDropdownVisible={searchDropdownVisible}
      setSearchDropdownVisible={setSearchDropdownVisible}
      listResults={lazyResult}
      updateWidget={updateWidget}
      renameDashboard={renameDashboard}
      resetDashboardSearch={resetDashboardSearch}
      removeDashboard={removeDashboard}
      setActiveSymbolValue={setActiveSymbolValue}
      resetSearchHandler={handleSearchReset}
      activeSymbolIndexValue={activeSymbolIndexValue}
      isUpdatedAllData={isUpdatedAllData}
      globalSearchKeyHandler={globalSearchKeyHandler}
    />
  );
};

export default Dashboards;
