import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { IMultichartHook, ISocketResponse } from '../types/MultichartInterfaces';
import RootStateTypes from '../../../store/RootStateTypes';
import {
  setMultiChartSymbolsList,
  setMultiChartRange,
  setMultiChartAdditionalData,
  setMultiChartQueryId,
  setMultiChartIsMounted,
  clearMultiChartState,
  setScrollToRow,
} from '../../../store/multiChart/actions';
import SocketContext from '../../../context/SocketContext';
import { ADDRESS, WS_CHANNELS } from '../../../constants/socketConnection';
import { convertMultiChartSort } from '../../../utils/convertingDataHelper';
import {
  filtersStoreHelper,
  getDividentAndEarnings,
  validationFilterMarket
} from '../../../utils/helpers';
import industrySectorWorker from '../../../utils/industrySectorWorker';
import {
  defaultPage,
  FILTER_SETTINGS,
  FIXED_COLUMNS,
  MULTI_CHART_COLUMNS,
  SCREENER_VIEW,
  SCREENER_VIEWS,
  TABLE_SCREEN_PATH,
} from '../../../constants/screener';
import {
  firstMultiChartRow,
  MULTI_CHART_ADDITIONAL_COLUMNS,
  multiChartDefaultRange
} from '../../../constants/multiChart';
import ScreenerViewEnum from '../../ScreenerMenu/enums';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';
import usePrepareLastSymbolState from '../../../utils/hooks/usePrepareLastSymbolState';
import { messageIdGenerator } from '../../ChartContainer/utils/chartingHelper';
import { setCounterSymbols } from '../../../store/tableData/slice';
import { repeatItems, useRepeat } from '../../../utils/hooks/useRepeat';
import { ScreenerQueryMessage, SocketScreenerAllResponse } from '../../../services/Interfaces';

const useMultiChartWorker = (initialLoad: boolean, isDeepList = false): IMultichartHook => {
  const dispatch = useDispatch();
  const multiChartSorting = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.multiChartSorting, shallowEqual
  );
  const { direction, columnId: sortBy } = multiChartSorting ?? { direction: 1, columnId: '' };
  const screenerView = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.screenerView, shallowEqual
  );
  const range = useSelector((state: RootStateTypes) => state.multiChartState.range, shallowEqual);
  const symbolsList = useSelector((state: RootStateTypes) => state.multiChartState.symbolsList, shallowEqual);
  const screenerId = useSelector((state: RootStateTypes) => state.multiChartState.screenerId, shallowEqual);
  const filterMarket = useSelector(
    (state: RootStateTypes) => state.screenerState.filterMarket, shallowEqual
  );
  const pagePath = useSelector((state: RootStateTypes) => state.screenerState.pagePath, shallowEqual);
  const selectedScreen = useSelector((state: RootStateTypes) => state.newScreenState.selectedScreen, shallowEqual);
  const alertsTab = useSelector(
    (state: RootStateTypes) => state.watchlistState.alertsTab, shallowEqual
  );
  const selectedTab = useSelector(
    (state: RootStateTypes) => state.watchlistState.selectedTab, shallowEqual
  );

  const {
    Socket,
    Connection,
    Authorized,
    awakeSocketWidget
  } = useContext(SocketContext);

  const { updateStoreHandler } = useLastStateUpdateStore();

  const { currentSymbolHandler } = usePrepareLastSymbolState();

  const checkScreenerView = () => screenerView === ScreenerViewEnum.CHART;

  const additionalDataListCallback = useCallback((result: SocketScreenerAllResponse) => {
    if (result.errors?.length && awakeSocketWidget) {
      awakeSocketWidget();
      return;
    }

    if (checkScreenerView() && result?.columns?.length === MULTI_CHART_COLUMNS) {
      const dataForIndustry = result?.data as unknown as Array<Array<number | string>>;
      const preparedData = industrySectorWorker(dataForIndustry);
      const preparedAdditionalData = getDividentAndEarnings(result, result.columns);
      if (preparedData) {
        dispatch(setMultiChartAdditionalData({ ...preparedAdditionalData, ...preparedData }));
      }
    }

    if (result?.errors?.length) {
      useRepeat(ADDRESS.TABLE);
    }
  }, [screenerView, dispatch]);

  const additionalDataListFirstCallback = useCallback((result: SocketScreenerAllResponse) => {
    if (result.errors?.length && awakeSocketWidget) {
      awakeSocketWidget();
      return;
    }

    dispatch(setMultiChartQueryId(result.screenerId));
    if (result?.errors?.length) {
      useRepeat(ADDRESS.TABLE);
    }
  }, [screenerView, dispatch]);

  const getSymbolsAdditionalData = (initial: boolean, list: Array<number>) => {
    const initialRange = initial ? multiChartDefaultRange : range;
    const symbolsSliced = list.slice(initialRange[0], initialRange[1] + 1);
    const symbols = symbolsSliced.length ? symbolsSliced : [];

    const queryMessage: ScreenerQueryMessage = {
      messageId: messageIdGenerator(),
      filters: [[[FILTER_SETTINGS.ZERO, FILTER_SETTINGS.ZERO, symbols]]],
      columns: [...FIXED_COLUMNS, ...MULTI_CHART_ADDITIONAL_COLUMNS],
      range: defaultPage,
      sortBy: [],
      screenerId: 0,
      symbolIndex: currentSymbolHandler(false).index
    };

    if (!initial && Connection) {
      queryMessage.screenerId = screenerId;
      const emitter = () => Connection?.sendMessage(
        WS_CHANNELS.SCREENER_PATCH,
        queryMessage,
      );
      emitter();

      // eslint-disable-next-line
      // @ts-ignore
      repeatItems[WS_CHANNELS.SCREENER_PATCH] = emitter;
    } else {
      Connection?.sendMessage(
        WS_CHANNELS.SCREENER_SUBSCRIBE,
        queryMessage,
      );
    }
  };

  const multiChartListCallback = (
    result: ISocketResponse<Array<number>>,
    initial: boolean,
    shouldScrollToTop: boolean
  ) => {
    if (result.data) {
      if (!initial && shouldScrollToTop) {
        dispatch(setScrollToRow(firstMultiChartRow));
      }
      dispatch(setCounterSymbols({ data: result.data.length, screenerId }));
      dispatch(setMultiChartSymbolsList(result.data));
      dispatch(setMultiChartIsMounted(true));
    }
  };

  useEffect(() => {
    if (screenerView === ScreenerViewEnum.CHART) {
      getSymbolsAdditionalData(true, symbolsList);
    }
  }, [screenerView, symbolsList]);

  const getSymbolsMultiChart = (
    sortData: string,
    filters: (number | number[])[][][],
    initial = false,
    shouldScrollToTop = true,
    sortDirection = -1
  ) => {
    Connection?.messageEmitter?.on(
      WS_CHANNELS.SCREENER_INDICES_LIST,
      (result: ISocketResponse<Array<number>>) => {
        if (result.errors?.length && awakeSocketWidget) {
          awakeSocketWidget();
          return;
        }
        multiChartListCallback(result, initial, shouldScrollToTop);
      }
    );
    const sortDataParsed = convertMultiChartSort(sortData, sortDirection);
    const queryMessage = {
      messageId: messageIdGenerator(),
      filters,
      sortBy: sortDataParsed,
    };

    Connection?.sendMessage(
      WS_CHANNELS.SCREENER_INDICES_LIST,
      queryMessage,
    );
  };

  const cleanUpSymbolsData = useCallback((isClear = true) => {
    Connection?.sendMessage(
      WS_CHANNELS.SCREENER_UNSUBSCRIBE, { messageId: messageIdGenerator(), screenerId }
    );
    if (isClear) {
      dispatch(clearMultiChartState());
    }
  }, [Socket, screenerId]);

  const setCurrentRange = (newRange: Array<number>) => {
    dispatch(setMultiChartRange(newRange));
  };

  const refreshSymbolsMultiChart = useCallback((
    isCustomRefresh: boolean,
    filters: Array<Array<number>> = [[]],
    shouldScrollToTop = false
  ) => {
    if (checkScreenerView()) {
      const newFilters = !isCustomRefresh ? filterMarket : filtersStoreHelper(filters);
      getSymbolsMultiChart(sortBy, newFilters, false, shouldScrollToTop, direction);
      dispatch(setMultiChartIsMounted(true));
    }
  }, [filterMarket, screenerView, checkScreenerView]);

  const setChartType = (chartType: string) => {
    updateStoreHandler('multiChartType', chartType);
  };

  const setSortBy = (sortType: string, sortDirection = -1) => {
    getSymbolsMultiChart(sortType, validationFilterMarket(filterMarket), false, false, sortDirection);
    dispatch(setMultiChartIsMounted(true));
  };

  const setTimeFrame = (timeFrame: string) => {
    updateStoreHandler('multiChartTimeframe', timeFrame);
  };

  useEffect(() => {
    if (initialLoad
      && Socket?.readyState === WebSocket.OPEN
      && Authorized) {
      const isEmptyDeepListTab = !selectedTab?.data?.stocks;
      const isFavorite = selectedTab?.isFavorite;
      const deepListFilters = isEmptyDeepListTab && !isFavorite
        ? [[[FILTER_SETTINGS.ZERO, FILTER_SETTINGS.ZERO, []]]]
        : filtersStoreHelper(selectedTab.data.filters);
      const screenerFilters = Object.keys(selectedScreen).length
        ? filtersStoreHelper(selectedScreen?.data?.filters) : filterMarket;
      const isAlerts = selectedTab?.id === alertsTab?.id;

      if (isAlerts && isDeepList) {
        updateStoreHandler(SCREENER_VIEW, SCREENER_VIEWS.SCREENER);
      }

      if ((!isAlerts && isDeepList) || !isDeepList) {
        getSymbolsMultiChart(
          sortBy,
          pagePath === TABLE_SCREEN_PATH.DEEPLIST ? deepListFilters : screenerFilters,
          true,
          true,
          direction
        );
        Connection?.messageEmitter?.on(WS_CHANNELS.SCREENER_SUBSCRIBE, additionalDataListFirstCallback);
        Connection?.messageEmitter.on(WS_CHANNELS.SCREENER_PATCH, additionalDataListCallback);
      }
    }
  }, [
    Authorized,
    Socket?.readyState,
    initialLoad,
  ]);

  useEffect(() => {
    if (initialLoad && symbolsList?.length && Socket?.readyState === WebSocket.OPEN) {
      getSymbolsAdditionalData(false, symbolsList);
    }
  }, [range]);

  return {
    setChartType,
    setSortBy,
    refreshSymbolsMultiChart,
    setTimeFrame,
    cleanUpSymbolsData,
    setCurrentRange,
  };
};

export default useMultiChartWorker;
