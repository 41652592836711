import React, { useState, useEffect, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';
import useStatsChart from '../../../utils/hooks/useStatsChart';
import TableChartWrapper from '../../Charts/TableChart/TableChartWrapper';
import { TDataItem, TBorderSettings } from '../../Charts/TableChart/Types';
import { scrollToItemStatsChart, filterEstimateHandler } from '../../../utils/helpers';
import { IStatsWidget } from '../types/DashboardComponentsInterfaces';
import RootStateTypes from '../../../store/RootStateTypes';
import { StoredSymbol } from '../../../pages/Table/DeeplistUtils/types/AlertTypes';
import styles from '../sass/DashboardComponents.module.scss';
import SocketContext from '../../../context/SocketContext';
import { WS_CHANNELS } from '../../../constants/socketConnection';

type TResponceFromApi = {
  type:string,
  messageId: number,
  data: [],
  latency: number
  errors?: string[] | []
};

const StatsWidget = ({
  widget,
  currentSource,
  numberOfQuarters,
  estimateVisibleData,
  checkedFilters,
  tableRef,
  activeSymbolIndexValue,
  width,
  height,
  setShowData
}: IStatsWidget): React.ReactElement => {
  const { t } = useTranslation();
  const { getStatsDataAsync, statsChartWidgetCallback } = useStatsChart();
  const symbolsList = useSelector((state: RootStateTypes) => state.screenerState.symbolsList, shallowEqual);
  const {
    Connection,
    Authorized,
    awakeSocketWidget
  } = useContext(SocketContext);

  const [currentData, setCurrentData] = useState<[TDataItem[], TDataItem[]]>([[], []]);
  const [chartSymbolData, setStatsChartData] = useState<[TDataItem[], TDataItem[]]>([[], []]);
  const [resizeClass, setResizeClass] = useState<string>('');
  const [tableBorderSettings, setTableBorderSettings] = useState<TBorderSettings>({
    salesPositive: [],
    salesNegative: [],
    epsPositive: [],
    epsNegative: []
  });

  const columnWidth = ((width || 0) - 150) / numberOfQuarters;
  const currentQuarter = `${moment().year()}: Q${moment().quarter()}`;
  const prevQuarter = moment().subtract(1, 'quarter');

  useEffect(() => {
    setCurrentData(chartSymbolData);

    if (!estimateVisibleData) {
      setCurrentData([
        filterEstimateHandler(chartSymbolData[0]),
        filterEstimateHandler(chartSymbolData[1])
      ]);
    }
  }, [chartSymbolData, estimateVisibleData]);

  useEffect(() => {
    if (currentData[0]?.length) {
      const currentQuarterIndex = currentData[0].findIndex(
        (item) => item.quarter === currentQuarter
      );

      const prevQuarterIndex = currentData[0].findIndex(
        (item) => item.quarter === `${prevQuarter.year()}: Q${prevQuarter.quarter()}`
      );

      const quarterIndex = currentQuarterIndex === -1 ? prevQuarterIndex : currentQuarterIndex;

      scrollToItemStatsChart(numberOfQuarters === 4 ? currentQuarter : currentData[0][quarterIndex]?.quarter);
    }
  }, [chartSymbolData, currentData[0], numberOfQuarters]);

  useEffect(() => {
    const statsResponseHandler = (res: TResponceFromApi) => {
      if (res.errors?.length && awakeSocketWidget) {
        awakeSocketWidget();
        return;
      }

      statsChartWidgetCallback(res, currentSource, setStatsChartData, setTableBorderSettings, widget);
    };

    if (widget?.symbol && Authorized) {
      let symbol;
      if (activeSymbolIndexValue) {
        symbol = symbolsList.find((item: StoredSymbol) => item.index === activeSymbolIndexValue);
      } else {
        symbol = symbolsList?.find((el: StoredSymbol) => el.sym === widget.symbol);
      }

      getStatsDataAsync(Connection, symbol.index, currentSource, widget.id);
      Connection?.messageEmitter?.on(WS_CHANNELS.SYMBOL_DATA_BATCH, statsResponseHandler);
    }

    return () => {
      if (Connection) {
        Connection?.messageEmitter?.off(WS_CHANNELS.SYMBOL_DATA_BATCH, statsResponseHandler);
      }
    };
  }, [widget, activeSymbolIndexValue, currentSource, numberOfQuarters, Authorized]);

  useEffect(() => {
    if (tableRef.current) {
      if (height + 1 < tableRef.current?.scrollHeight) {
        setResizeClass(styles.vScrolled);
      } else {
        setResizeClass('');
      }
    }
  }, [height]);

  useEffect(() => {
    if (currentData[0]?.length && currentData[1]?.length) {
      setShowData(true);
    } else {
      setShowData(false);
    }
  }, [currentData]);

  return (
    <div
      ref={tableRef}
      className={`${styles.statsChartWrapper}
      ${resizeClass}
      ${width < 300 && styles.statsChartWrapperSmall}`}
      id="scroll"
    >
      {currentData?.length && currentData[0]?.length && currentData[1]?.length ? (
        <TableChartWrapper
          checkedFilters={checkedFilters}
          columnWidth={columnWidth}
          currentData={currentData}
          tableBorderSettings={tableBorderSettings}
          isWidget
        />
      ) : (
        <div className={styles.statsChartEmpty}>
          {t('noDataChart')}
        </div>
      )}

    </div>
  );
};

export default StatsWidget;
