import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { IItemDropdownListItem } from '../types/interfaces';
import styles from '../sass/DashboardItemDropdown.module.scss';
import { ReactComponent as DndControl } from '../../../assets/images/icons/screener/dnd_frame.svg';
import { ReactComponent as IconStarFill } from '../../../assets/images/icons/screener/star-fill.svg';
import { ReactComponent as IconStar } from '../../../assets/images/icons/screener/star.svg';
import { ReactComponent as IconCheck } from '../../../assets/images/icons/screener/Check.svg';
import { ReactComponent as Favorites } from '../../../assets/images/icons/wlIcons/favorites.svg';
import { ReactComponent as Universe } from '../../../assets/images/icons/universe.svg';

import ItemMenu from './ItemMenu';
import {
  DasboardsItemsDropdownTypes,
  MENU_ITEM, MENU_ITEM_DASHBOARDS, MENU_ITEM_DEFAULTS, MENU_ITEM_FAVOURITES,
  MENU_ITEM_NO_ADMIN, MENU_ITEM_SCREENER_WATCHLISTS, MENU_ITEM_TARGET_LISTS,
  MENU_SUBITEM, MENU_SUBITEM_DASHBOARDS
} from '../types/constants';
import { DialogItemDataTypes } from '../../DashboardItemsDialog/types/constants';
import { FAVOURITE_TYPE, UNIVERSE_TYPE, VIEW_TYPE_TARGET } from '../../../constants/watchlist';
import TargetListIcon from '../../../assets/images/icons/targetList/TargetListIcon';
import TextField from '../../TextField/TextField';
import { TEMPLATE_TYPE, VIEW_TYPES } from '../../../constants/screener';

const DashboardItem = ({
  item,
  theme,
  selectedItem,
  actionDuplicate,
  actionShare,
  actionDelete,
  actionEdit,
  actionMove,
  isTouchDevice,
  actionFavorite,
  setSelectedItem,
  actionRemove,
  singleItem,
  selectedTab,
  adminRole,
  provided,
  disableDND,
  itemType,
  setDragStart
}: IItemDropdownListItem): React.ReactElement => {
  const { t } = useTranslation();
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const isElementShown = itemType === DialogItemDataTypes.DEEP_LIST || itemType === DialogItemDataTypes.DATA_PANEL;
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const getFullAccessItemMenu = () => {
    const isDashboard = item?.type === TEMPLATE_TYPE.DASHBOARDS;
    if (singleItem && !isDashboard) return MENU_ITEM;
    if (singleItem && isDashboard) return MENU_ITEM_DASHBOARDS;
    if (!singleItem && isDashboard) return MENU_SUBITEM_DASHBOARDS;
    return MENU_SUBITEM;
  };

  const isFavorite = item?.data?.subType === FAVOURITE_TYPE;
  const isUniverse = item?.data?.subType === UNIVERSE_TYPE;
  const isTargetList = item?.viewType === VIEW_TYPES.TARGET;
  const { style } = provided.draggableProps;

  const getItemMenu = () => {
    if (item?.parentId) return MENU_ITEM_SCREENER_WATCHLISTS;
    if (item?.isDefault) return MENU_ITEM_DEFAULTS;
    if (isFavorite || isUniverse) return MENU_ITEM_FAVOURITES;
    if (isTargetList) return MENU_ITEM_TARGET_LISTS;
    if (adminRole) return getFullAccessItemMenu();
    if (selectedTab === DasboardsItemsDropdownTypes.PRESET) return MENU_ITEM_NO_ADMIN;
    return getFullAccessItemMenu();
  };

  const clickHandler = () => {
    setSelectedItem(item);
  };

  const getCount = () => {
    if (itemType === DialogItemDataTypes.DATA_PANEL
    && item?.data?.leftColumn && item?.data?.rightColumn) {
      return item?.data?.leftColumn?.length + item?.data?.rightColumn?.length;
    }
    return item?.data?.stocks || 0;
  };

  return (
    <div
      key={`${item?.id}-${item?.title}`}
      className={styles.itemDropdownListItem}
      ref={provided.innerRef}
      aria-hidden
      onMouseDown={() => {
        setDragStart(!isTouchDevice && true);
      }}
      {...provided.draggableProps} // eslint-disable-line
      {...!isTouchDevice && provided.dragHandleProps} // eslint-disable-line
      style={{
        ...style,
        left: 'auto !important',
        top: 'auto !important',
      }}
    >
      <div className={styles.leftBlock}>
        {!disableDND && (
          isTouchDevice ? (
            <div
              onMouseDown={() => {
                setDragStart(true);
              }}
              aria-hidden
              {...provided.dragHandleProps} // eslint-disable-line
            >
              <DndControl className={styles.dndIcon} />
            </div>
          ) : (
            <DndControl className={styles.dndIcon} />
          )
        )}
        {
          itemType !== DialogItemDataTypes.DEEP_LIST && itemType !== DialogItemDataTypes.DATA_PANEL
          && itemType !== DialogItemDataTypes.DASHBOARD && itemType !== DialogItemDataTypes.COLUMN_SET
            ? (
              <>
                {item?.isFavorite && <IconStarFill className={styles.starIcon} onClick={() => actionFavorite(item)} />}
                {!item?.isFavorite && <IconStar className={styles.starIcon} onClick={() => actionFavorite(item)} />}
              </>
            )
            : (
              <>
                {isFavorite && <div className={styles.favouritesIconWrapper}><Favorites /></div>}
                {isUniverse && <div className={styles.favouritesIconWrapper}><Universe /></div>}
                {item?.viewType === VIEW_TYPE_TARGET && (
                <div className={styles.targetIcon}>
                  <TargetListIcon colorStart={item?.data?.color1 as string} />
                </div>
                )}
              </>
            )
          }
        <div
          aria-hidden
          className={`${isElementShown && styles.watchlistItem}
          ${styles.itemDropdownListItemText} ${styles.textNoWrap}`}
          onClick={clickHandler}
        >
          <span className={styles.itemDropdownListItemTextDescription}>
            {item?.title}
          </span>
          {
            isElementShown
            && (
              <TextField
                text={`${getCount() || 0} ${itemType === DialogItemDataTypes.DEEP_LIST
                  ? t('stocks') : t('categories')}`}
                styleType="listContentItemDescription"
                className={styles.textFieldDeeplist}
              />
            )
           }
        </div>
      </div>
      <div className={`${styles.rightBlock} ${styles.endPosition}`}>
        {selectedItem?.id === item?.id && (
          <IconCheck className={styles.wwwww} />
        )}
        <ItemMenu
          item={item}
          anchorElement={anchorElement}
          handleOpen={handleOpen}
          menuOpen={Boolean(anchorElement)}
          setMenuClose={handleClose}
          theme={theme}
          actionDuplicate={actionDuplicate}
          actionShare={actionShare}
          actionMove={actionMove}
          actionEdit={actionEdit}
          actionDelete={actionDelete}
          actionRemove={actionRemove}
          menuObject={getItemMenu()}
        />
      </div>
    </div>
  );
};

export default DashboardItem;
