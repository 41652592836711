import { CustomIndicator, IPineStudyResult, LibraryPineStudy, PineJS, RawStudyMetaInfoId, StudyInputType, StudyPlotType } from '../../charting_library/charting_library';
import getInputArray from '../utilities/getInputArrayTs';

export default function ttmSqueeze(mPineJS: PineJS): CustomIndicator {
  return {
    name: 'DV - TTM Squeeze',
    metainfo: {
      id: 'TL-dvttmsqueeze@tv-basicstudies-1' as RawStudyMetaInfoId,
      shortDescription: 'DV - TTM Squeeze',
      description: 'DV - TTM Squeeze',
      format: {
        type: 'inherit'
      },
      is_hidden_study: false,
      is_price_study: false,
      precision: 1,
      styles: {
        plot_0: {
          histogramBase: 0,
          isHidden: false,
          joinPoints: false,
          title: 'Histogram:'
        },
        plot_2: {
          histogramBase: 0,
          isHidden: false,
          joinPoints: false,
          title: 'Dot Plot:'
        }
      },
      plots: [{
        id: 'plot_0',
        type: 'line' as StudyPlotType.Line
      },
      {
        id: 'plot_1',
        palette: 'palette_0',
        target: 'plot_0',
        type: 'colorer' as StudyPlotType.Colorer
      },
      {
        id: 'plot_2',
        type: 'line' as StudyPlotType.Line
      },
      {
        id: 'plot_3',
        palette: 'palette_1',
        target: 'plot_2',
        type: 'colorer' as StudyPlotType.Colorer
      }
      ],
      palettes: {
        palette_0: {
          colors: {
            0: {
              name: 'Increasing Positive Momentum'
            },
            1: {
              name: 'Decreasing Positive Momentum'
            },
            2: {
              name: 'Increasing Negative Momentum'
            },
            3: {
              name: 'Decreasing Negative Momentum'
            },
          },
          valToIndex: {
            0: 0,
            1: 1,
            2: 2,
            3: 3
          }
        },
        palette_1: {
          colors: {
            0: {
              name: 'Price Expansion'
            },
            1: {
              name: 'Price Squeeze'
            }
          },
          valToIndex: {
            4: 0,
            5: 1
          }
        }
      },
      inputs: [
        {
          defval: 20,
          id: 'length',
          name: 'Length',
          type: 'integer' as StudyInputType.Integer
        }
      ],
      defaults: {
        inputs: {
          length: 20,
        },
        styles: {
          plot_0: {
            color: '#3BB3E4',
            linestyle: 0,
            linewidth: 2,
            plottype: 1,
            trackPrice: false,
            transparency: 35,
            visible: true,
          },
          plot_2: {
            color: '#3BB3E4',
            linestyle: 0,
            linewidth: 3,
            plottype: 6,
            trackPrice: false,
            transparency: 35,
            visible: true
          }
        },
        palettes: {
          palette_0: {
            colors: {
              0: {
                color: '#00ffff',
                style: 0,
                width: 2
              },
              1: {
                color: '#009b9b',
                style: 0,
                width: 2
              },
              2: {
                color: '#ff9bff',
                style: 0,
                width: 2
              },
              3: {
                color: '#cc00cc',
                style: 0,
                width: 2
              },
            }
          },
          palette_1: {
            colors: {
              0: {
                color: '#008000',
                style: 0,
                width: 3
              },
              1: {
                color: '#FF0000',
                style: 0,
                width: 3
              }
            }
          }
        },
      }
    },
    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = (context) => {
        this._context = context;
      };
      this.main = (context, inputs): IPineStudyResult => {
        this._context = context;
        const [length] = getInputArray({ inputs, length: 1 });
        const closeSeries = context.new_unlimited_var(mPineJS.Std.close(this._context));

        function bband(length: number, mult: number) {
          return mPineJS.Std.sma(closeSeries, length, context) + mult * mPineJS.Std.stdev(closeSeries, length, context)
        }

        function keltner(length: number, mult: number) {
          return mPineJS.Std.ema(closeSeries, length, context) + mult * mPineJS.Std.ema(context.new_unlimited_var(mPineJS.Std.tr(0, context)), length, context)
        }

        const highestHigh = mPineJS.Std.highest(this._context.new_unlimited_var(mPineJS.Std.high(this._context)), length, this._context);
        const lowestLow = mPineJS.Std.lowest(this._context.new_unlimited_var(mPineJS.Std.low(this._context)), length, this._context);
        const smaClose = mPineJS.Std.sma(closeSeries, length, this._context);

        const e1 = (highestHigh + lowestLow) / 2 + smaClose
        const osc = mPineJS.Std.linreg(context.new_unlimited_var(closeSeries.get(0) - e1 / 2), length, 0)
        const diff = bband(length, 2) - keltner(length, 1);
        const oscSeries = context.new_var(osc);

        return [
          osc,
          oscSeries.get(1) < oscSeries.get(0) ? oscSeries.get(0) >= 0 ? 0 : 3 : oscSeries.get(0) >= 0 ? 1 : 2,
          0,
          diff >= 0 ? 0 : 1
        ];
      };
    }
  }
}
