import React, { useEffect, useRef } from 'react';

import 'gridstack/dist/gridstack.min.css';
import './sass/gridStackOverrides.scss';
import { useResizeDetector } from 'react-resize-detector';
import useGridStack from './hook/useGridStack';
import styles from './sass/DashboardComponents.module.scss';
import { IDashboardGrid } from './types/DashboardComponentsInterfaces';
import { Widget } from '../../pages/Dashboards/types/DashboardTypes';
import useColorGroup from './hook/useColorGroup';
import GridMask from './GridMask';

import DashboardGridResizeItem from './components/DashboardGridResizeItem';
import { checkRoleUser } from '../../utils/userHelper';
import { SUBSCRIPTION_TYPE, TEMPLATE_TYPE } from '../../constants/screener';

const DashboardGrid = ({
  parrentRef, dashboard, updateWidget,
  activeSymbolIndexValue, setSearch,
  globalSearchKeyHandler, setEnableDrag, resetDashboardSearch
}: IDashboardGrid): React.ReactElement => {
  const targetRef = useRef(null);
  const { width, height } = useResizeDetector({ targetRef });
  // eslint-disable-next-line no-console
  console.log('updated');
  const {
    setColorGroup,
    changeSymbolForGroup,
    lastActiveGroup,
    setLastActiveGroup
  } = useColorGroup();
  const { removeWidget } = useGridStack(parrentRef, targetRef, dashboard, setEnableDrag);

  const adminRole = checkRoleUser(SUBSCRIPTION_TYPE.ADMIN) || checkRoleUser(SUBSCRIPTION_TYPE.EDITOR);

  const checkIsEditType = (): boolean => adminRole || (dashboard?.type !== TEMPLATE_TYPE.DASHBOARDS_PRESET);

  const handleWidgetUpdate = (widget: Widget) => {
    if (dashboard) {
      updateWidget(widget, dashboard?.id);
    }
  };

  useEffect(() => () => {
    targetRef.current = null;
  }, []);

  return (
    <>
      <div ref={targetRef} id="#gridStackNode" className={`grid-stack ${styles.innerGrid}`}>
        {dashboard?.data?.widgets.map((widget) => (
          <DashboardGridResizeItem
            key={['widget', widget.id].join('-')}
            widget={widget}
            changeSymbolForGroup={changeSymbolForGroup}
            setColorGroup={setColorGroup}
            updateWidget={handleWidgetUpdate}
            removeWidget={removeWidget}
            activeSymbolIndexValue={activeSymbolIndexValue}
            lastActiveGroup={lastActiveGroup}
            setLastActiveGroup={setLastActiveGroup}
            parrentWidth={width}
            parrentHeight={height}
            resetDashboardSearch={resetDashboardSearch}
            setSearch={setSearch}
            globalSearchKeyHandler={globalSearchKeyHandler}
            dashboardId={dashboard?.id || 0}
            isEditable={checkIsEditType()}
          />
        ))}
      </div>
      {targetRef?.current && (<GridMask parrentWidth={width as number} parrentHeight={height as number} />)}
    </>
  );
};

export default DashboardGrid;
