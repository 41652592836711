import getInputArray from '../utilities/getInputArrayTs';
import { CustomIndicator, IPineStudyResult, LibraryPineStudy, PineJS, RawStudyMetaInfoId, StudyInputType, StudyPlotType } from '../../charting_library/charting_library';
import moment from 'moment';

export default function zangerVolumeRatio(mPineJS: PineJS): CustomIndicator {
  return {
    name: 'DV - Zanger Volume Ratio',
    metainfo: {
      id: 'DV-zvr@tv-basicstudies-1' as RawStudyMetaInfoId,
      shortDescription: 'DV - Zanger Volume Ratio',
      description: 'DV - Zanger Volume Ratio',
      is_price_study: false,
      isCustomIndicator: true,
      format: {
        type: 'inherit',
      },
      palettes: {
        palette_0: {
          addDefaultColor: true,
          colors: {
            0: {
              name: 'Up'
            },
            1: {
              name: 'Down'
            },
          },
          valToIndex: {
            0: 0,
            1: 1,
          }
        },
        palette_1: {
          addDefaultColor: true,
          colors: {
            0: {
              name: 'Extreme Level'
            },
            1: {
              name: 'High Level'
            },
            2: {
              name: 'Average Level'
            },
            3: {
              name: 'Light Level'
            },
            4: {
              name: 'Dry Up'
            }
          },
          valToIndex: {
            0: 0,
            1: 1,
            2: 2,
            3: 3,
            4: 4,
          }
        },
      },
      inputs: [
        {
          defval: 20,
          id: 'in_0',
          name: 'Lookback Days',
          type: 'integer' as StudyInputType.Integer
        },
        {
          defval: 150,
          id: 'in_1',
          name: 'Extreme Level',
          type: 'integer' as StudyInputType.Integer
        },
        {
          defval: 110,
          id: 'in_3',
          name: 'High Level',
          type: 'integer' as StudyInputType.Integer
        },
        {
          defval: 85,
          id: 'in_5',
          name: 'Average Level',
          type: 'integer' as StudyInputType.Integer
        },
        {
          defval: 60,
          id: 'in_7',
          name: 'Light Level',
          type: 'integer' as StudyInputType.Integer
        },
      ],
      plots: [
        {
          id: 'plot_0',
          type: 'line' as StudyPlotType.Line
        },
        {
          id: 'plot_1',
          palette: 'palette_0',
          target: 'plot_0',
          type: 'colorer' as StudyPlotType.Colorer
        },
        {
          id: 'plot_2',
          palette: 'palette_1',
          type: 'bg_colorer' as StudyPlotType.BgColorer
        }
      ],
      styles: {
        plot_0: {
          histogramBase: 0,
          isHidden: false,
          joinPoints: false,
          title: 'Plot'
        },
        plot_2: {
          isHidden: false,
          title: 'Background Color'
        }
      },
      defaults: {
        inputs: {
          in_0: 20,
          in_1: 150,
          in_3: 110,
          in_5: 85,
          in_7: 60,
        },
        palettes: {
          palette_0: {
            colors: {
              0: {
                color: '#4CAF50',
                style: 0,
                width: 1
              },
              1: {
                color: '#FF5252',
                style: 0,
                width: 1
              }
            }
          },
          palette_1: {
            colors: {
              0: {
                color: '#ff5252',
                style: 0,
                width: 1
              },
              1: {
                color: '#e040fb',
                style: 0,
                width: 1
              },
              2: {
                color: '#ffeb3b',
                style: 0,
                width: 1
              },
              3: {
                color: '#ff9800',
                style: 0,
                width: 1
              },
              4: {
                color: '#787b86',
                style: 0,
                width: 1
              }
            }
          }
        },
        styles: {
          plot_0: {
            color: '#2962ff',
            display: 15,
            linestyle: 0,
            linewidth: 1,
            plottype: 0,
            trackPrice: false,
            transparency: 0
          },
          plot_2: {
            display: 15,
            transparency: 80
          }
        }
      }
    },
    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = (context) => {
        this._context = context;
        this.cv = 0;
        this.isSamebar = 0;
      };
      this.main = (context, inputs): IPineStudyResult => {
        this._context = context;
        const [lookback, extLev, highLev, avgLev, lightLev] = getInputArray({
          inputs,
          length: 5
        });
        const volArr = [];
        const timePeriod = Number(mPineJS.Std.period(this._context));
        const bars = 390 / timePeriod;
        const times = mPineJS.Std.time(context, mPineJS.Std.period(this._context));
        var timeSeries = context.new_var(times);
        const isSameDate = checkSameDate(timeSeries.get(1), timeSeries.get(0));

        const barTime = mPineJS.Std.time(this._context);

        if (!isSameDate) {
          this.cv = 0;
        }

        if (barTime !== this.isSamebar) {
          this.cv += mPineJS.Std.volume(this._context);
          this.isSamebar = barTime;
        }

        const cvSeries = this._context.new_unlimited_var(this.cv);

        for (var i = lookback; i >= 1; i--) {
          const numbers = cvSeries.get(Math.trunc(bars * i));
          if (!isNaN(numbers)) {
            volArr.unshift(numbers);
          }
        }

        const ma = calculateAverage(volArr);
        const ratio = Math.round((this.cv / ma) * 100);
        const ratioSeries = this._context.new_unlimited_var(ratio);

        const ratioCol = ratio >= extLev ? 0 : ratio >= highLev ? 1 : ratio >= avgLev ? 2 : ratio >= lightLev ? 3 : 4;

        return [
          ratio,
          ratioSeries.get(0) > ratioSeries.get(1) ? 0 : 1,
          ratioCol
        ];
      };
    }
  };
}

function checkSameDate(oldVal: number, newVal: number): boolean {
  if (isNaN(oldVal) || isNaN(newVal)) {
    return false;
  }
  var time1 = moment(oldVal);
  var time2 = moment(newVal);
  var isSame1 = time1.isSame(time2, 'date');

  return isSame1;
}

function calculateAverage(values: number[]) {
  const sum = values.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  return sum / values.length;
}
