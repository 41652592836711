import React, { useState, useCallback, useEffect } from 'react';

import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import Portal from '@mui/material/Portal';
import { IDashboardsView } from './types/DashboardInterfaces';
import DashboardsHeader from '../../components/DasboardComponents/DashboardsHeader';
import DashboardsWraper from '../../components/DasboardComponents/DashboardsWraper';
import DashboardAddMenu from './components/DashboardAddMenu';
import GlobalSearch from '../../components/GlobalSearch/GlobalSearch';
import RootStateTypes from '../../store/RootStateTypes';
import styles from './sass/Dashboards.module.scss';
import NewScreenModals from '../../components/NewScreenModals/NewScreenModals';
import { MODAL_TITLES } from '../../constants/screener';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import ModalAlert from '../Table/screenerComponents/ModalAlert';
import ClientOnlyPortal from '../../components/ClientOnlyPortal/ClientOnlyPortal';
import WatchListModals from '../../components/WatchListModals/WatchListModals';
import useScreenshot from '../../components/TwitterShare/hooks/useScreenshot';
import ShareTwitterModal from '../../components/TwitterShare/ShareTwitterModal';
import useEnabledDragMenu from './hooks/useEnabledDragMenu';
import Header from '../../components/Header/Header';
import AlertsNotionList from '../../components/AlertsNotionList/AlertsNotionList';
import useLastStateUpdateStore from '../../utils/hooks/useLastStateUpdateStore';
import { PSC_REMOVE_DASHBOARD } from '../../constants/storage';
import NotionPopUp from '../../components/NotionPopUp/NotionPopUp';
import { setIsOpenModal } from '../../store/watchlist/actions';

const DashboardsView = ({
  setCurrentDashboard,
  globalSearchVisible,
  setGlobalSearchVisible,
  setSearch,
  setPagingHandler,
  handlerSearch,
  search,
  listResults,
  searchDropdownVisible,
  setSearchDropdownVisible,
  updateWidget,
  createDashboard,
  resetDashboardSearch,
  renameDashboard,
  removeDashboard,
  setActiveSymbolValue,
  resetSearchHandler,
  activeSymbolIndexValue,
  isUpdatedAllData,
  globalSearchKeyHandler,
}: IDashboardsView): React.ReactElement => {
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [controledName, setControlledName] = useState('');
  const [removeId, setRemoveId] = useState(0);
  const dashboardsList = useSelector((state: RootStateTypes) => state.dashboardsState.dashboards, shallowEqual);
  const dashboardsListPresets = useSelector(
    (state: RootStateTypes) => state.dashboardsState.dashboardPresets, shallowEqual
  );
  const selectedDashboardId = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.selectedDashboardId,
    shallowEqual
  );
  const showTwitterModal = useSelector((state: RootStateTypes) => state.twitterState.showShareModal, shallowEqual);
  const openConfirmModal = useSelector((state: RootStateTypes) => state.accountState.openConfirmModal, shallowEqual);
  const profile = useSelector((state: RootStateTypes) => state.accountState.userProfile, shallowEqual);
  const popUp = useSelector((state: RootStateTypes) => state.authState.popUp, shallowEqual);
  const modalTypeWatchList = useSelector((state: RootStateTypes) => state.watchlistState.modalType, shallowEqual);

  const {
    enableScreenDrag,
    enableTVDrag,
    enableStatsDrag,
    enablePSCDrag,
    enableWidgetChartDrag,
    setEnableDrag
  } = useEnabledDragMenu();

  const { updateNestedPscActionData } = useLastStateUpdateStore();

  const closeModalHandler = () => {
    setOpenModal(false);
    setModalType('');
  };

  const handleCreateDashboard = (title: string): void => {
    createDashboard(title, false);
    setOpenModal(false);
  };
  const {
    twittText,
    setTwittText,
    cleanUpScreenshotData,
  } = useScreenshot();

  const handleDashboardAction = (type: string, value?: string): void => {
    if (type === MODAL_TITLES.RENAME && value) {
      renameDashboard(value, removeId);
    } else if (type === MODAL_TITLES.DELETE) {
      const dashboardIndex = dashboardsList.findIndex((item) => item.id === removeId);
      if (dashboardIndex !== 0) {
        setCurrentDashboard(dashboardsList[dashboardIndex - 1].id);
      } else if (dashboardsList.length > 1 && dashboardIndex === 0) {
        setCurrentDashboard(dashboardsList[dashboardIndex + 1].id);
      }
      removeDashboard(removeId);
      updateNestedPscActionData(PSC_REMOVE_DASHBOARD, {}, removeId);
    }
    setOpenModal(false);
  };

  const morePopupCallback = (value: string, id: number) => {
    const dashboard = dashboardsList.find((item) => item.id === id);
    setControlledName(dashboard?.title || '');
    setRemoveId(id);
    setModalType(value);
    setOpenModal(true);
  };

  const setFocus = useCallback(
    () => setGlobalSearchVisible(true),
    [globalSearchVisible],
  );
  useEffect(() => {
    resetSearchHandler();
  }, [selectedDashboardId]);

  useEffect(() => {
    dispatch(setIsOpenModal(!!modalType));
  }, [modalType]);

  return (
    <div className={styles.dashboardsWraper}>
      <Header />
      <AlertsNotionList />
      {modalTypeWatchList && modalTypeWatchList !== MODAL_TITLES.CREATE && (
        <ClientOnlyPortal selector="#watchlist_modals">
          <WatchListModals />
        </ClientOnlyPortal>
      )}
      <ModalAlert />
      {(openModal || modalType) && (
        <NewScreenModals
          openModal=""
          controlledEditName={controledName}
          isDashboard
          modalType={modalType}
          closeModalControlled={closeModalHandler}
          openModalHandler={() => undefined}
          controledAction={modalType === MODAL_TITLES.CREATE
            ? handleCreateDashboard
            : handleDashboardAction}
        />
      )}
      {openConfirmModal && <ConfirmModal />}
      {
        (globalSearchVisible) && (
          <GlobalSearch
            setPaging={setPagingHandler}
            setGlobalSearchVisible={setGlobalSearchVisible}
            handlerSearch={handlerSearch}
            search={search}
            listResults={listResults}
            handleOpenChart={() => undefined}
            setSearch={setSearch}
            setIsVisibleFilters={() => undefined}
            setActiveSymbolValue={setActiveSymbolValue}
            searchDropdownVisible={searchDropdownVisible}
            setSearchDropdownVisible={setSearchDropdownVisible}
            isWidget
            hideChart
          />
        )
      }
      <DashboardsHeader
        morePopupCallback={morePopupCallback}
        search={search}
        resetSearchHandler={resetSearchHandler}
        setFocus={setFocus}
        selectedDashboard={[
          ...dashboardsList,
          ...dashboardsListPresets].find((item) => item.id === selectedDashboardId)}
        setCurrentDashboard={setCurrentDashboard}
        dashboards={dashboardsList}
        profile={profile}
        setOpenModal={setModalType}
      />
      <DashboardsWraper
        dashboard={[...dashboardsList, ...dashboardsListPresets].find((item) => item.id === selectedDashboardId)}
        emptyState={dashboardsList.length === 0}
        updateWidget={updateWidget}
        activeSymbolIndexValue={activeSymbolIndexValue}
        setSearch={setSearch}
        resetDashboardSearch={resetDashboardSearch}
        isUpdatedAllData={isUpdatedAllData}
        globalSearchKeyHandler={globalSearchKeyHandler}
        setEnableDrag={setEnableDrag}
      />
      <Portal>
        <DashboardAddMenu
          enableTVDrag={enableTVDrag}
          enableStatsDrag={enableStatsDrag}
          enableScreenDrag={enableScreenDrag}
          enablePSCDrag={enablePSCDrag}
          enableWidgetChartDrag={enableWidgetChartDrag}
        />
      </Portal>
      {showTwitterModal && (
        <Portal>
          <ShareTwitterModal
            textTweet={twittText}
            handlerTextTweet={setTwittText}
            cleanUpScreenshotData={cleanUpScreenshotData}
          />
        </Portal>
      )}
      {(popUp) && (<NotionPopUp />)}
    </div>
  );
};

export default DashboardsView;
