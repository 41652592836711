import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';
import { SELECTED_TAB_ID } from '../../constants/storage';
import $api from '../../http';
import {
  DASHBOARD_FOLDERS,
  DASHBOARD_ITEM_CHANGE_ORDER,
  DASHBOARD_ITEM_TYPE_WATCHLIST,
  DASHBOARD_ITEMS,
  LINK_TO_WATCHLIST,
  UNLINK_FROM_WATCHLIST,
} from '../../constants/paths';
import { errorMessageAction } from '../../store/account/actions';
import {
  FILTER_SETTINGS,
  NEW_SCREEN_DATA,
  SCREENER_VIEWS,
  TABLE_SCREEN_PATH,
  TEMPLATE_TYPE,
  VIEW_TYPES,
} from '../../constants/screener';
import {
  changeNotificationTypeAction,
  errorMessageTitleAction,
  messageAction,
  popUpAction
} from '../../store/auth/actions';
import { DASHBOARD_ITEM_EXISTS, STATUS_200, WATCHLIST_FULL } from '../../constants/responseStatuses';
import numberConstant from '../../constants/numberConstants';
import {
  setAlertsData,
  setFavoritesData,
  setModalType,
  setSelectedTab,
  setTabFromDropDownWatchlist,
  setWatchlistData,
  updateFavouriteWatchlist,
  updateSpecificWatchlist
} from '../../store/watchlist/actions';
import { clearSelectedSymbolsScreener, setMarketsFilterWatchlistAction } from '../../store/screener/actions';
import useMultiChartWorker from '../../components/MultichartView/hooks/useMultiChartWorker';
import { sortDataHandler } from '../watchListsHelper';
import { setItemsNewScreen, setPresetsNewScreen } from '../../store/newScreen/actions';
import requestHandler from '../../pages/Table/DeeplistUtils/helpers/helpers';
import filters from '../../constants/filters';
import {
  ALERTS_TYPE, FAVOURITE_TYPE, TAB_SYMBOLS_LIMIT, UNIVERSE_TYPE
} from '../../constants/watchlist';
import { fillSpeedValuesHandler } from './useSpeedCheckup';
import { setCleanedItemsForTarget } from '../../store/tableData/slice';
import useWorkerItems from './useWorkerItems';
import useLastStateUpdateStore from './useLastStateUpdateStore';

const useWatchList = (modalHandler = () => {
}) => {
  const { t } = useTranslation();
  const tabsListRef = useRef([]);
  const presetsRef = useRef([]);
  const { updateStoreHandler } = useLastStateUpdateStore();

  const dispatch = useDispatch();
  const selectedSymbolsList = useSelector((state) => state.watchlistState.selectedSymbolsList, shallowEqual);
  const selectedTab = useSelector((state) => state.watchlistState.selectedTab, shallowEqual);
  const selectedTabID = useSelector((state) => state.accountState.userSettings.selectedTabId, shallowEqual);
  const tabsList = useSelector((state) => state.watchlistState.tabsList, shallowEqual);
  const presets = useSelector((state) => state.watchlistState.presets, shallowEqual);
  const selectedSymbols = useSelector((state) => state.screenerState.selectedSymbols, shallowEqual);
  const itemsNewScreen = useSelector((state) => state.newScreenState.itemsNewScreen, shallowEqual);
  const presetsNewScreen = useSelector((state) => state.newScreenState.presetsNewScreen, shallowEqual);
  const screenerView = useSelector((state) => state.accountState.userSettings.screenerView, shallowEqual);
  const lastTablePath = useSelector((state) => state.accountState.userSettings.lastTablePath, shallowEqual);
  const searchHeaderValue = useSelector((state) => state.screenerState.globalSearchHeaderValue, shallowEqual);
  const { refreshSymbolsMultiChart } = useMultiChartWorker(false);

  tabsListRef.current = tabsList;
  presetsRef.current = presets;

  useEffect(() => {
    return () => {
      tabsListRef.current = null;
      presetsRef.current = null;
    };
  }, []);

  const {
    getDeeplistFolders,
    getDeeplistPreset,
    getDeeplistPresetFolders
  } = useWorkerItems();

  const successHandler = (message, title) => {
    dispatch(popUpAction(true));
    dispatch(errorMessageTitleAction({ messageTitle: title }));
    dispatch(messageAction({ message }));
    dispatch(changeNotificationTypeAction({ type: t('successType') }));
    dispatch(setTabFromDropDownWatchlist({}));
  };

  const errorHandler = ({ message, title, type }) => {
    dispatch(popUpAction(true));
    dispatch(errorMessageTitleAction({ messageTitle: title }));
    dispatch(messageAction({ message }));
    dispatch(changeNotificationTypeAction({ type }));
  };

  const errorNotionWatchlistExist = (name) => {
    return {
      title: t('watchlistExist', { title: name }),
      message: t('changeName'),
      type: t('error')
    };
  };

  const prepareWatchlistBatchHandler = (data, selectedTabId) => {
    const favouriteItem = data.filter((item) => item?.data?.subType === FAVOURITE_TYPE);
    const universeItem = data.filter((item) => item?.data?.subType === UNIVERSE_TYPE);

    dispatch(setWatchlistData(data.filter((item) => item?.data?.subType !== ALERTS_TYPE)));
    const alertsItem = data.filter((item) => item?.data?.subType === ALERTS_TYPE);

    dispatch(setAlertsData(...alertsItem));
    dispatch(setFavoritesData(...favouriteItem));

    const currentItem = [...data, ...presets].find(
      (subItem) => (selectedTabId ? subItem.id === selectedTabId : subItem.id === selectedTabID)
    );
    if (!searchHeaderValue) {
      if (currentItem && Object.keys(currentItem).length) {
        dispatch(setSelectedTab(currentItem));
      } else {
        dispatch(setSelectedTab(...universeItem));
      }
    }
  };

  const getWatchLists = (selectedTabIDNew) => {
    const start = Date.now();
    try {
      $api.get(`${DASHBOARD_FOLDERS}?${DASHBOARD_ITEM_TYPE_WATCHLIST}&page=0&limit=${numberConstant.limitItems}`)
        .then((resFolders) => {
          getDeeplistFolders(resFolders.data.items, resFolders.data.count);
          $api.get(`${DASHBOARD_ITEMS}?${DASHBOARD_ITEM_TYPE_WATCHLIST}&limit=${numberConstant.limitItems}`)
            .then((res) => {
              requestHandler(res.data.items, res.data.count, filters.deepList,
                (response) => prepareWatchlistBatchHandler(response, selectedTabIDNew ?? selectedTabID));

              // speed check temporary data
              const end = Date.now();
              const speedCheck = end - start;
              fillSpeedValuesHandler('get_watchLists_data', speedCheck);
              // eslint-disable-next-line no-console
              console.log('=> get_watchLists_data', speedCheck);
            });
        });
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const updateOrdersWatchlists = () => {
    try {
      $api.get(`${DASHBOARD_ITEMS}?${DASHBOARD_ITEM_TYPE_WATCHLIST}&limit=${numberConstant.limitItems}`)
        .then((res) => {
          requestHandler(res.data.items, res.data.count, filters.deepList, prepareWatchlistBatchHandler);
        });
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const copySymbolsToWatchlist = (item, newSymbols, noPostAction = false) => {
    const filtersData = item?.data?.filters.flat(2)[2];
    const data = filtersData && filtersData[0] !== FILTER_SETTINGS.BILLION
      ? [...new Set([...filtersData, ...newSymbols])] : [...new Set([...newSymbols])];
    const sortedData = sortDataHandler(data);

    $api.patch(
      `${DASHBOARD_ITEMS}/${item.id}`,
      {
        data: {
          ...item.data,
          stocks: sortedData.length,
          filters: [[[FILTER_SETTINGS.ZERO, FILTER_SETTINGS.ZERO, sortedData]]]
        },
      }
    ).then((res) => {
      getWatchLists();
      if (!noPostAction) {
        dispatch(clearSelectedSymbolsScreener());
        if (selectedTab.id === res.data.id) {
          dispatch(setSelectedTab(res.data));
        }
      }

      successHandler(item.title, t('watchlistUpdate'));
    }).catch((error) => {
      if (error.response.data.message === WATCHLIST_FULL) {
        const errorFullNotion = {
          title: t('watchlistLimit'),
          message: t('watchlistLimitCount'),
          type: t('error')
        };
        errorHandler(errorFullNotion);
      }
      if (error.response.data.message === DASHBOARD_ITEM_EXISTS) {
        const message = errorNotionWatchlistExist(item.title);
        errorHandler(message);
      }
    });
  };

  const createWatchList = (name) => {
    $api.post(
      DASHBOARD_ITEMS,
      {
        title: name,
        type: TEMPLATE_TYPE.WATCHLIST,
        viewType: VIEW_TYPES.TABLE,
        data: {
          stocks: 0,
          filters: [],
        },
      }
    ).then((res) => {
      if (res.status === STATUS_200) {
        modalHandler(STATUS_200);
      }

      if (selectedSymbols.length) {
        copySymbolsToWatchlist(res.data, selectedSymbols);
      }

      getWatchLists();
    }).catch((error) => {
      if (error.response.data.message === DASHBOARD_ITEM_EXISTS) {
        const message = errorNotionWatchlistExist(name);
        errorHandler(message);
      }
    });
  };

  const updateState = () => {
    getWatchLists();
  };

  const deepFind = (arr, id) => {
    const findDeepItem = arr.find((item) => item.id === id);
    return findDeepItem;
  };

  const updateFavorite = (id, fav) => {
    const newState = [];
    const newPresetsState = [];
    itemsNewScreen.forEach((item) => newState.push(item));
    let findItem = newState?.find((item) => {
      if (item.type === NEW_SCREEN_DATA.FOLDER) {
        return deepFind(item.items, id);
      }
      return item.id === id;
    });
    if (!findItem) {
      presetsNewScreen.forEach((item) => newPresetsState.push(item));
      findItem = newPresetsState?.find((item) => {
        return deepFind(item.items, id);
      });
      findItem = deepFind(findItem.items, id);
      findItem.isFavorite = fav;
      dispatch(setPresetsNewScreen([...newPresetsState]));
    } else {
      if (findItem?.type === NEW_SCREEN_DATA.FOLDER) {
        findItem = deepFind(findItem.items, id);
      }
      findItem.isFavorite = fav;
      dispatch(setItemsNewScreen([...newState]));
    }
  };

  const linkToWatchlist = (id, title) => {
    updateFavorite(id, true);
    $api.post(
      `${DASHBOARD_ITEMS}${LINK_TO_WATCHLIST}/${id}`
    ).then((res) => {
      if (res.status === STATUS_200) {
        modalHandler(STATUS_200);
        successHandler(title, t('watchlistSuccessLinked'));
      }
      updateState();
    }).catch((e) => {
      dispatch(errorMessageAction({ errorMessage: e?.message }));
    });
  };

  const unLinkFromWatchlist = (id, title) => {
    updateFavorite(id, false);
    $api.delete(
      `${DASHBOARD_ITEMS}${UNLINK_FROM_WATCHLIST}/${id}`
    ).then((res) => {
      if (res.status === STATUS_200) {
        modalHandler(STATUS_200);
        successHandler(title, t('watchlistSuccessUnlinked'));
      }
      updateState();
    }).catch((e) => {
      dispatch(errorMessageAction({ errorMessage: e?.message }));
    });
  };

  const getWatchlistById = (id, favourite = false) => {
    try {
      $api.get(`${DASHBOARD_ITEMS}/${id}`).then((res) => {
        if (!favourite) {
          dispatch(updateSpecificWatchlist(res.data));
        }

        if (favourite) {
          dispatch(updateFavouriteWatchlist(res.data));
        }

        if (selectedTab?.id === res.data.id) {
          dispatch(setMarketsFilterWatchlistAction(res.data));
        }
      });
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const updateWatchlist = (id, name) => {
    $api.patch(
      `${DASHBOARD_ITEMS}/${id}`,
      {
        title: name,
      }
    ).then((res) => {
      successHandler(name, t('watchlistUpdate'));
      getWatchLists();
      if (selectedTab?.id === id) {
        dispatch(setSelectedTab(res.data));
      }

      dispatch(setModalType(''));
    });
  };

  const saveSortingToWatchlist = (id, sortData, isAlertTable = false) => {
    const currentItem = [...tabsListRef.current, ...presetsRef.current].find(((tab) => tab?.id === id)) || selectedTab;
    const data = { ...currentItem.data };

    data.sortData = sortData;
    $api.patch(
      `${DASHBOARD_ITEMS}/${currentItem.id}`,
      { data }
    ).then((res) => {
      if (res.status === STATUS_200) {
        if (isAlertTable) {
          dispatch(setAlertsData(res?.data));
        }
        dispatch(updateSpecificWatchlist(res?.data));
        if (!searchHeaderValue) {
          dispatch(setSelectedTab(res?.data));
        }
      }
    });
  };

  const updateTargetListName = (id, name) => {
    $api.patch(
      `${DASHBOARD_ITEMS}/${id}`,
      {
        title: name,
      }
    ).then(() => {
      successHandler(name, t('watchlistUpdate'));
      getWatchLists();
      dispatch(setModalType(''));
    }).catch((error) => {
      if (error.response.data.message === DASHBOARD_ITEM_EXISTS) {
        const message = errorNotionWatchlistExist(name);
        errorHandler(message);
      }
    });
  };

  const updateWatchlistData = (id, data) => {
    $api.patch(
      `${DASHBOARD_ITEMS}/${id}`,
      { data }
    ).then(() => {
      getWatchLists();
    }).catch((error) => {
      dispatch(errorMessageAction({ errorMessage: error.message }));
    });
  };

  const removeWatchlist = (id, name = '') => {
    const removeTabIndex = tabsList.findIndex((item) => item.id === id);

    try {
      $api.delete(`${DASHBOARD_ITEMS}/${id}`).then(() => {
        const universeTab = tabsList.find((item) => item.data?.subType === UNIVERSE_TYPE);
        if (selectedTabID === id) {
          const nextTab = tabsList[removeTabIndex + 1];
          updateStoreHandler(SELECTED_TAB_ID, nextTab?.id);
          dispatch(setSelectedTab(nextTab || universeTab));
          getWatchLists(nextTab?.id);
        } else getWatchLists();
        successHandler(name, t('watchListDelete'));
        dispatch(setModalType(''));
      });
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const addNewSymbolToWatchlist = (selectedSymbolsNew = null, newSelectedTab = null) => {
    const sortedData = selectedSymbolsNew
      ? [...new Set([...selectedSymbolsNew.map((item) => item.index)])]
      : [...new Set([...selectedSymbolsList.map((item) => item.index)])];

    const currentTab = newSelectedTab ?? selectedTab;
    const currentTabListData = [...tabsList, ...presets].find((tab) => tab?.id === currentTab?.id).data;
    $api.patch(
      `${DASHBOARD_ITEMS}/${currentTab.id}`,
      {
        data: {
          ...currentTabListData,
          stocks: sortedData?.length,
          filters: [[[
            FILTER_SETTINGS.ZERO,
            FILTER_SETTINGS.ZERO,
            sortedData.length ? sortedData : []
          ]]]
        },
      }
    ).then((res) => {
      if (currentTab.type === TEMPLATE_TYPE.WATCHLIST) {
        getWatchLists(currentTab.id);
      } else {
        getDeeplistPreset();
        getDeeplistPresetFolders();
      }
      dispatch(setMarketsFilterWatchlistAction(res.data));
      dispatch(setSelectedTab(res.data));
      successHandler(currentTab.title, t('watchlistUpdate'));

      if (screenerView === SCREENER_VIEWS.CHART) {
        refreshSymbolsMultiChart(
          true,
          [[[FILTER_SETTINGS.ZERO, FILTER_SETTINGS.ZERO, sortedData.length ? sortedData : []]]]
        );
      }
    }).catch((error) => {
      if (error.response.data.message === DASHBOARD_ITEM_EXISTS) {
        const message = errorNotionWatchlistExist(currentTab.title);
        errorHandler(message);
      }
      if (error.response.data.message === WATCHLIST_FULL) {
        const errorFullNotion = {
          title: t('watchlistLimit'),
          message: t('watchlistLimitCount'),
          type: t('error')
        };
        errorHandler(errorFullNotion);
      }
    });
  };

  const updateLocalWatchlist = (targetList, symbolsArray) => {
    const updateData = {
      ...targetList,
      data: {
        ...targetList.data,
        stocks: symbolsArray.length,
        filters: [[[
          FILTER_SETTINGS.ZERO,
          FILTER_SETTINGS.ZERO,
          symbolsArray.length > 0 ? [...new Set([...symbolsArray])] : []
        ]]]
      }
    };

    dispatch(updateSpecificWatchlist(updateData));

    if (selectedTab.id === updateData.id) {
      dispatch(setSelectedTab(updateData));
      dispatch(setMarketsFilterWatchlistAction(updateData.data.filters));
    }
  };

  const updateTargetList = (targetList, symbolsArray, symbol = null, updateFlag = false, noPostAction = false) => {
    if (noPostAction) {
      updateLocalWatchlist(targetList, symbolsArray);
    }

    $api.patch(
      `${DASHBOARD_ITEMS}/${targetList.id}`,
      {
        data: {
          ...targetList.data,
          stocks: symbolsArray.length,
          filters: [[[
            FILTER_SETTINGS.ZERO,
            FILTER_SETTINGS.ZERO,
            symbolsArray.length > 0 ? [...new Set([...symbolsArray])] : []
          ]]]
        },
      }
    ).then((res) => {
      if (noPostAction) return;

      dispatch(clearSelectedSymbolsScreener());
      getWatchLists();

      if (selectedTab.id === res.data.id) {
        if (updateFlag && lastTablePath !== TABLE_SCREEN_PATH.SCREENER && screenerView !== SCREENER_VIEWS.CHART) {
          dispatch(setMarketsFilterWatchlistAction(res.data));
          dispatch(setCleanedItemsForTarget([symbol.index]));
        }
        if (!searchHeaderValue) {
          dispatch(setSelectedTab(res.data));
        }
      }
    }).catch((error) => {
      if (error.response.data.message === WATCHLIST_FULL) {
        const errorFullNotion = {
          title: t('watchlistLimit'),
          message: t('watchlistLimitCount'),
          type: t('error')
        };
        errorHandler(errorFullNotion);
      }
    });
  };

  const updateStocksForEmptyDL = (item) => {
    $api.patch(
      `${DASHBOARD_ITEMS}/${item.id}`,
      {
        data: {
          ...item.data,
          stocks: 0,
        },
      }
    ).then(() => {
      getWatchLists();
    }).catch((error) => {
      dispatch(errorMessageAction({ errorMessage: error.message }));
    });
  };

  const addSymbolToTargetList = ({ symbol, targetList, noPostAction = false }) => {
    if (targetList && targetList.data) {
      const symbolsList = [...targetList.data.filters[0][0][2], symbol.sortIndex];
      const filteredList = symbolsList.filter((item) => item !== 100000000);

      const sortedData = sortDataHandler(filteredList);
      const noPostActionFlag = symbolsList.length < TAB_SYMBOLS_LIMIT && noPostAction;

      updateTargetList(targetList, sortedData, null, false, noPostActionFlag);
    }
  };

  const removeSymbolFromTargetList = ({
    symbol, currentTargetList, updateFlag = false, noPostAction = false
  }) => {
    const buffer = currentTargetList.data.filters[0][0][2];
    const index = buffer.indexOf(symbol.sortIndex);
    if (index !== -1) {
      buffer.splice(index, 1);
    }
    const noPostActionFlag = buffer.length < TAB_SYMBOLS_LIMIT && noPostAction;

    updateTargetList(currentTargetList, buffer, symbol, updateFlag, noPostActionFlag);
  };

  const moveSymbolToTargetList = ({
    symbol, targetList, currentTargetList, updateFlag = false
  }) => {
    removeSymbolFromTargetList({ symbol, currentTargetList, updateFlag });
    addSymbolToTargetList({ symbol, targetList });

    if (screenerView === SCREENER_VIEWS.CHART) {
      refreshSymbolsMultiChart(false, [[]], false);
    }
  };

  const handlerTargetList = ({
    symbol, targetList, currentTargetList, updateFlag
  }) => {
    if (targetList && currentTargetList) {
      removeSymbolFromTargetList({
        symbol, currentTargetList, updateFlag, noPostAction: true
      });
      addSymbolToTargetList({ symbol, targetList, noPostAction: true });
    }

    if (targetList && !currentTargetList) {
      addSymbolToTargetList({ symbol, targetList });
    }

    if (!targetList && currentTargetList) {
      removeSymbolFromTargetList({
        symbol, currentTargetList, updateFlag: false, noPostAction: true
      });
    }

    if (screenerView === SCREENER_VIEWS.CHART && (!targetList && currentTargetList)) {
      refreshSymbolsMultiChart(false, [[]], false);
    }
  };

  const saveOrder = (itemId, placeAfterId) => {
    $api.post(`${DASHBOARD_ITEMS}/${DASHBOARD_ITEM_CHANGE_ORDER}`,
      {
        itemId,
        placeAfterId
      })
      .then(() => updateOrdersWatchlists()).catch((error) => {
        if (error.response.data.message === DASHBOARD_ITEM_EXISTS) {
          errorHandler(' ');
        }
      });
  };

  const deleteSymbolsFromWatchlist = (
    symbolIds,
    watchListItem,
    noPostAction = false,
    showNotification = true
  ) => {
    const symbolsToRemove = symbolIds || selectedSymbols;
    const currentTab = tabsList.find((tab) => tab?.id === watchListItem?.id) || selectedTab;
    const isSelectedTab = currentTab?.id === selectedTab?.id;
    const filtersData = currentTab?.data?.filters.flat(2)[2].filter((item) => !symbolsToRemove.includes(item));

    const isMultiChart = screenerView === SCREENER_VIEWS.CHART;

    if (currentTab.title === 'Favorites') {
      currentTab.data.subType = 'Favorites';
    }

    const currentTabListData = tabsList.find((tab) => tab?.id === currentTab?.id).data;

    if (noPostAction) {
      updateLocalWatchlist(currentTab, filtersData);
    }

    $api.patch(
      `${DASHBOARD_ITEMS}/${currentTab.id}`,
      {
        data: {
          ...currentTabListData,
          stocks: filtersData?.length,
          filters: [[[
            FILTER_SETTINGS.ZERO,
            FILTER_SETTINGS.ZERO,
            filtersData.length ? [...new Set([...filtersData])] : []
          ]]],
        },
      }
    ).then(() => {
      if (!noPostAction) {
        getWatchLists();
      }

      if (showNotification) {
        successHandler(currentTab.title, t('watchlistUpdate'));
      }
      if (isMultiChart && isSelectedTab) {
        refreshSymbolsMultiChart(
          true,
          [[[FILTER_SETTINGS.ZERO, FILTER_SETTINGS.ZERO, filtersData.length ? filtersData : []]]],
          false
        );
      }
    }).catch((error) => {
      if (error.response.data.message === DASHBOARD_ITEM_EXISTS) {
        const message = errorNotionWatchlistExist(currentTab.title);
        errorHandler(message);
      }
    });
  };

  const removeHandlerFromTargetList = (symbols, currentTargetList) => {
    const isMultiChart = screenerView === SCREENER_VIEWS.CHART;
    const withoutRefresh = isMultiChart && !!selectedTab.parentId;

    deleteSymbolsFromWatchlist(symbols, currentTargetList, withoutRefresh, false);
    if (isMultiChart) {
      dispatch(clearSelectedSymbolsScreener());
    }
  };

  return {
    saveOrder,
    copySymbolsToWatchlist,
    addNewSymbolToWatchlist,
    getWatchLists,
    createWatchList,
    getWatchlistById,
    updateWatchlist,
    removeWatchlist,
    deleteSymbolsFromWatchlist,
    linkToWatchlist,
    unLinkFromWatchlist,
    updateWatchlistData,
    addSymbolToTargetList,
    handlerTargetList,
    moveSymbolToTargetList,
    updateTargetListName,
    updateStocksForEmptyDL,
    saveSortingToWatchlist,
    removeHandlerFromTargetList,
    updateOrdersWatchlists,
  };
};

export default useWatchList;
