import React, { useState, useEffect, BaseSyntheticEvent } from 'react';

import {
  DragDropContext, Droppable,
  Draggable, DropResult, DragStart, DraggableLocation
} from 'react-beautiful-dnd';
import { IItemDropdownList } from '../types/interfaces';
import styles from '../sass/DashboardItemDropdown.module.scss';
import { NEW_SCREEN_DATA } from '../../../constants/screener';
import DashboardItemFolder from './DashboardItemFolder';
import DashboardItem from './DashboardItem';
import DashboardItemDropDownEmpty from './DashboardItemDropDownEmpty';
import { ScreenItemData, TDashboardItemsData } from '../../../utils/Types';
import useNewScreen from '../../../utils/hooks/useNewScreen';
import filters from '../../../constants/filters';
import SubitemsMenu from './SubitemsMenu';
import { DasboardsItemsDropdownTypes } from '../types/constants';
import { DialogItemDataTypes } from '../../DashboardItemsDialog/types/constants';

type TSubitemsState = {
  [key: string]: TDashboardItemsData[]
};

const ItemDropdownList = ({
  itemList,
  theme,
  selectedItem,
  actionDuplicate,
  actionShare,
  actionDelete,
  actionEdit,
  actionMove,
  actionRemove,
  actionFavorite,
  setSelectedItem,
  search,
  itemType,
  selectedTab,
  adminRole,
  hideDnd,
  setUpdateState
}: IItemDropdownList): React.ReactElement => {
  const [scrollableArea, setScrollableArea] = useState<boolean>(false);
  const [foldersState, setFoldersState] = useState<TDashboardItemsData[]>([]);
  const [itemsState, setItemsState] = useState<TDashboardItemsData[]>([]);
  const [anchorItemsElement, setAnchorItemsElement] = useState<null | HTMLElement>(null);
  const [subitemsState, setSubitemsState] = useState<TSubitemsState>({});
  const [dragStart, setDragStart] = useState<boolean>(false);
  const [disableDropInFolderArea, setDisableDropInFolderArea] = useState<boolean>(false);
  const [disableDropInFolder, setDisableDropInFolder] = useState<boolean>(false);
  const [disableInTopList, setDisableInTopList] = useState<boolean>(false);
  const [dragDisabled, setDragDisabled] = useState<boolean>(false);

  const isTouchDevice = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0));

  const {
    moveToFolder, saveOrder,
    saveOrderFolder,
    removeSubItemFromFolder
  } = useNewScreen(() => {});

  const updateState = (items: TDashboardItemsData[]): void => {
    const folders = items.filter((item) => item.type === NEW_SCREEN_DATA.FOLDER);
    const itemsList = items.filter((item) => item.type !== NEW_SCREEN_DATA.FOLDER
      && (itemType === DialogItemDataTypes.DATA_PANEL ? !item.isDefault : true));
    const subitems: TSubitemsState = {};
    folders.forEach((item) => {
      subitems[`${item.id}`] = item.items as TDashboardItemsData[];
    });
    setSubitemsState(subitems);
    setFoldersState(folders);
    setItemsState(itemsList);
  };

  const handleCloseSubitem = (e: BaseSyntheticEvent): void => {
    if (isTouchDevice) return;
    if (dragStart) return;
    if (e.target === anchorItemsElement) return;
    setAnchorItemsElement(null);
  };

  const getIndexes = (
    source: DraggableLocation,
    destination: DraggableLocation,
    items: TDashboardItemsData[]
  ) => {
    const index = (destination.index && source.index < destination.index)
      ? destination.index : destination.index - 1;
    const indexId = destination.index > 0
      ? items[index].id : 0;
    const indexFrom = source.index;
    const indexTo = destination.index;
    return { indexId, indexFrom, indexTo };
  };

  const dragEndHandler = (result: DropResult | null): void => {
    if (!result) {
      setDragStart(false);
      return;
    }
    const { destination, draggableId, source } = result;
    if (!destination) {
      setDragStart(false);
      return;
    }
    const droppTarget = destination?.droppableId.split('__');
    if (droppTarget?.length === 2 && droppTarget[1] === filters.folder) {
      if (source.droppableId.includes('subitems') && droppTarget[0] === source.droppableId.split('__')[0]) {
        return;
      }
      const newState = [...itemsState];
      const dragItem = newState.find((el) => String(el.id) === draggableId);
      const cleanState = newState.filter((item) => String(item.id) !== draggableId);
      setItemsState(cleanState);
      const newStateFolder = [...subitemsState[droppTarget[0]]];
      newStateFolder.push(dragItem as TDashboardItemsData);
      setSubitemsState({ ...subitemsState, [droppTarget[0]]: newStateFolder });

      const folder = foldersState.find(
        (folderState) => folderState.id === Number(droppTarget[0])
      )?.title;
      const screen = dragItem?.title;
      const details = (folder && screen) ? { folder, screen } : null;

      moveToFolder(draggableId, droppTarget[0], details, false);
    }
    if (destination.droppableId === 'DndTopList') {
      const newState = [...itemsState];
      const { indexId, indexFrom, indexTo } = getIndexes(source, destination, newState);
      if (source.droppableId.includes('subitems')) {
        const newFolderState = [...foldersState];
        const folderId = source.droppableId.split('__')[0];
        const newSubitems = [...subitemsState[folderId]];
        const newItem = newSubitems.find((item) => String(item.id) === draggableId) as TDashboardItemsData;
        removeSubItemFromFolder(draggableId, null, true);
        newState.splice(indexTo, 0, newItem);
        const newItems = newSubitems.filter((item) => String(item.id) !== draggableId) as ScreenItemData[];
        const updatedFolders = newFolderState.map((folder) => {
          if (folder?.id === Number(folderId)) {
            return { ...folder, items: newItems };
          }
          return folder;
        });
        setFoldersState(updatedFolders as TDashboardItemsData[]);
        setSubitemsState({ ...subitemsState, [source.droppableId.split('__')[0]]: newItems });
      } else {
        newState.splice(indexTo, 0, newState.splice(indexFrom, 1)[0]);
      }
      setItemsState(newState);
      saveOrder(draggableId, indexId, newState[0]?.type || itemType);
    }
    if (destination.droppableId === 'DndFolderList') {
      const newState = [...foldersState];
      const { indexId, indexFrom, indexTo } = getIndexes(source, destination, newState);
      newState.splice(indexTo, 0, newState.splice(indexFrom, 1)[0]);
      setFoldersState(newState);
      saveOrderFolder(draggableId, indexId, itemType);
    }
    if (destination.droppableId.includes('subitems')) {
      const newState = [...subitemsState[droppTarget[0]]];
      const { indexId, indexFrom, indexTo } = getIndexes(source, destination, newState);
      newState.splice(indexTo, 0, newState.splice(indexFrom, 1)[0]);
      setSubitemsState({ ...subitemsState, [droppTarget[0]]: newState });
      saveOrder(draggableId, indexId, newState[0].type || itemType);
    }
    setDragStart(false);
    if (!destination.droppableId.includes('subitems')) {
      setAnchorItemsElement(null);
    }
    setUpdateState(true);
    setDisableDropInFolderArea(false);
    setDisableDropInFolder(false);
    setDisableInTopList(false);
  };

  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>): void => {
    if (dragStart) {
      dragEndHandler(null);
      return;
    }
    setAnchorItemsElement(event.currentTarget);
  };

  const checkOpenSublist = (): boolean => {
    if (!anchorItemsElement) return false;
    if (!subitemsState[anchorItemsElement?.id]?.length) return false;
    return true;
  };

  const dragStartHandler = (start: DragStart): void => {
    setDragStart(true);
    if (start.source.droppableId === 'DndTopList' || start.source.droppableId?.includes('subitems')) {
      setDisableDropInFolderArea(true);
    }
    if (start.source.droppableId === 'DndFolderList') {
      setDisableDropInFolder(true);
      setDisableInTopList(true);
    }
  };

  const setDragPosibilyty = (overload?: boolean): void => {
    if (overload && typeof overload === 'boolean') {
      setDragDisabled(true);
      return;
    }
    if (selectedTab === DasboardsItemsDropdownTypes.PRESET) {
      if (!adminRole) {
        setDragDisabled(true);
      } else {
        setDragDisabled(false);
      }
    } else {
      setDragDisabled(false);
    }
  };

  useEffect(() => {
    setScrollableArea(itemList.length > 5);
    updateState(itemList);
  }, [itemList]);

  useEffect(() => {
    setAnchorItemsElement(null);
    setDragPosibilyty();
  }, [selectedTab]);

  useEffect(() => {
    setDragPosibilyty(hideDnd);
  }, [hideDnd]);

  useEffect(() => () => setAnchorItemsElement(null), []);

  return (
    <DragDropContext onDragEnd={dragEndHandler} onDragStart={dragStartHandler}>
      <div className={`${styles.itemDropdownList} ${scrollableArea && styles.scrollSet}`}>
        <Droppable isDropDisabled={disableDropInFolderArea} key="DndFolderList" droppableId="DndFolderList">
          {(folderProvided) => (
            <div
              style={{ minHeight: `${foldersState.length * 52}px`, paddingBottom: '50px' }}
              ref={folderProvided.innerRef}
              {...folderProvided.droppableProps} // eslint-disable-line
            >
              {foldersState.map((item, folderIndex) => (
                <Draggable
                  isDragDisabled={dragDisabled}
                  key={`${item.id}`}
                  draggableId={`${item.id}`}
                  index={folderIndex}
                >
                  {(dragProvided) => {
                    const { style } = dragProvided.draggableProps;
                    return (
                      <div
                        ref={dragProvided.innerRef}
                        {...dragProvided.draggableProps} // eslint-disable-line
                        {...dragProvided.dragHandleProps} // eslint-disable-line
                        style={{
                          ...style,
                          left: 'auto !important',
                          top: 'auto !important',
                        }}
                      >
                        <Droppable
                          isDropDisabled={disableDropInFolder}
                          key={`${item.id}__folder`}
                          droppableId={`${item.id}__folder`}
                        >
                          {(provided, snapshot) => (
                            <DashboardItemFolder
                              key={item.id}
                              theme={theme}
                              item={item}
                              provided={provided}
                              snapshot={snapshot}
                              selectedItem={selectedItem}
                              actionDuplicate={actionDuplicate}
                              actionShare={actionShare}
                              actionDelete={actionDelete}
                              actionEdit={actionEdit}
                              actionMove={actionMove}
                              actionFavorite={actionFavorite}
                              actionRemove={actionRemove}
                              selectedTab={selectedTab}
                              adminRole={adminRole}
                              handleMouseEnter={handleMouseEnter}
                              handleCloseSubitem={handleCloseSubitem}
                              disableDND={dragDisabled}
                              itemType={itemType}
                              setDragStart={setDragStart}
                            />
                          )}
                        </Droppable>
                      </div>
                    );
                  }}
                </Draggable>
              ))}

            </div>
          )}
        </Droppable>
        <Droppable isDropDisabled={disableInTopList} key="DndTopList" droppableId="DndTopList">
          {(topProvided) => (
            <div
              style={{ minHeight: itemsState?.length ? `${itemsState.length * 52}px` : '52px', marginTop: '-45px' }}
              className={styles.itemList}
              ref={topProvided.innerRef}
              {...topProvided.droppableProps} // eslint-disable-line
            >
              {itemsState.map((item, index) => (
                <Draggable
                  key={item?.id}
                  isDragDisabled={dragDisabled}
                  draggableId={`${item?.id}`}
                  index={index}
                >
                  {(dragProvided) => (
                    <DashboardItem
                      provided={dragProvided}
                      key={item?.id}
                      theme={theme}
                      item={item}
                      selectedItem={selectedItem}
                      setSelectedItem={setSelectedItem}
                      actionDuplicate={actionDuplicate}
                      actionShare={actionShare}
                      actionDelete={actionDelete}
                      actionEdit={actionEdit}
                      actionMove={actionMove}
                      isTouchDevice={isTouchDevice}
                      actionFavorite={actionFavorite}
                      actionRemove={actionRemove}
                      selectedTab={selectedTab}
                      adminRole={adminRole}
                      singleItem
                      disableDND={dragDisabled}
                      itemType={itemType}
                      setDragStart={setDragStart}
                    />
                  )}
                </Draggable>
              ))}
              {!itemList.length && (
              <DashboardItemDropDownEmpty search={search} itemType={itemType} />
              )}
            </div>
          )}
        </Droppable>
      </div>
      {anchorItemsElement && checkOpenSublist() && (
        <SubitemsMenu
          itemType={itemType}
          subitemsId={Number(anchorItemsElement?.id)}
          anchorElement={anchorItemsElement}
          items={subitemsState[anchorItemsElement?.id] as TDashboardItemsData[]}
          theme={theme}
          selectedItem={selectedItem as TDashboardItemsData}
          setSelectedItem={setSelectedItem}
          actionDuplicate={actionDuplicate}
          actionShare={actionShare}
          actionMove={actionMove}
          actionEdit={actionEdit}
          actionDelete={actionDelete}
          actionFavorite={actionFavorite}
          actionRemove={actionRemove}
          selectedTab={selectedTab}
          adminRole={adminRole}
          disabledDrag={dragDisabled}
          handleCloseSubitem={handleCloseSubitem}
          setDragStart={setDragStart}
          itemList={itemList}
        />
      )}
    </DragDropContext>
  );
};

export default ItemDropdownList;
