import * as React from 'react';
import {
  useEffect, useState,
  useRef, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SubscribeEventsMap, widget } from '../../charting_library';
import widgetOptions from '../../utils/widgetOptions';
import TradingViewAdapter from '../../services/chartingHistoricalClient';
import { setToLocalStorage } from '../../utils/storageWorks';
import { WS_CHART_ERROR } from '../../constants/storage';
import { TV_EVENTS, TWO_HUNDRED } from '../../constants/screener';
import { handleDebounce } from '../../utils/helpers';
import { injectScriptToIframe } from './utils/chartingHelper';
import {
  changeNotificationTypeAction,
  errorMessageTitleAction,
  messageAction,
  popUpAction
} from '../../store/auth/actions';
import { changeActiveSymbol, chartCleanUp, shapeMoveListener } from './utils/tradingViewHelper';
import chartSearch from '../../utils/chartSearch';
import {
  DEFINE_TIMESCALE_MARK,
  SYMBOL_LOCK,
  SIDEBAR_TOGGLE,
  DRAWING_TOOLBAR,
  CHART_CURSOR,
} from '../../constants/tvWidgetOptions';
import useAlerts from './hooks/useAlerts';
import styles from './sass/ChartContainer.module.scss';
import { WS_CHANNELS, WS_EVENTS } from '../../constants/socketConnection';
import {
  pingCallback,
  rtDataListenerFromSocket,
  saveChartIdToSubscription,
} from '../../services/socketTvSubscriber';
import useChartState from '../../utils/hooks/useChartState';
import usePrepareLastSymbolState from '../../utils/hooks/usePrepareLastSymbolState';
import useLastStateUpdateStore from '../../utils/hooks/useLastStateUpdateStore';
import { useTheme } from '../../utils/hooks/useTheme';
import {
  alertSubscribe,
  applyOverridesHandler,
  autoSaveSubscribe,
  headerReadySubscribe,
  onChangeTradingViewTheme,
  shortcutSubscribe,
  timeScaleApplier,
  timeScaleListener,
  unbindAll
} from './utils/subscribeUtils';
import useAdditionalDataForTv from './hooks/useAdditionalDataForTv';
import { setSelectedShapeId, setTvIsReady } from '../../store/screener/actions';
import RootStateTypes from '../../store/RootStateTypes';
import {
  CurrentWidget,
  TAlertData
} from './types/Types';
import { IChartContainer, IExtendCurrentWidget } from './types/Interfaces';
import { StoredSymbol } from '../../pages/Table/DeeplistUtils/types/AlertTypes';
import SocketContext from '../../context/SocketContext';
import {
  alertsHandler,
  crossLayoutPositionHandler,
  crossLayoutShapeHandler,
  delay,
  fillAlertData,
  multipleLayoutHandler,
  priceLineGenerator,
  removeAlertWithTrendLine,
  setPointsForClick,
  shapeTypeHandler,
  symbolFromActiveChart,
  symbolFromLayoutHandler,
} from './utils/alertHelper';
import { currentAlertData, sharedChartData } from './constants/constants';
import { COMMON_LINE } from '../../constants/alerts';
import { Widget } from '../../pages/Dashboards/types/DashboardTypes';
import { resetChartData } from '../../services/ResetChartDataProcessor';
import useDebouncedCallback from '../../utils/hooks/useDebouncedCallback';

const ChartContainer = ({
  isAlertTable,
  watchList,
  dashWidget,
  saveChartLayoudId,
  updateChartSymbolSync,
  updateIntervalSync,
  updateTimeSync,
  updateCrosshairSync,
  updateDateRangeSync,
  updateSymbolSync,
  dashboardChartRef,
  resetSearch,
  setUpdateInstance,
  activeSymbolIndexValue,
}: IChartContainer): React.ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const RefExtended = useRef<boolean | string | null>(null);
  const DashboardWidgetRef = useRef<Widget | null>(null);

  const { t } = useTranslation();
  const {
    Socket,
    Authorized,
    Connection,
    awakeSocketWidget
  } = useContext(SocketContext);
  const { saveFavoriteState } = useChartState();
  const { theme, previousTheme } = useTheme();
  const { currentSymbolHandler } = usePrepareLastSymbolState();
  const { updateStoreHandler } = useLastStateUpdateStore();
  const { contentHandler } = useAdditionalDataForTv();
  const {
    alertAction,
    alertActionEdit,
    clearLastPrice,
    updateAlertPoints,
    removeAlerts,
    getAlertsForSymbolIndex,
    updateAlertLinks
  } = useAlerts();

  const [symbol, setSymbol] = useState<string>('');
  const [layoutId, setLayoutId] = useState<string | number>(0);
  const [tradingViewWidget, setTradingViewWidget] = useState<CurrentWidget | null>(null);

  const userProfile = useSelector((state: RootStateTypes) => state.accountState.userProfile, shallowEqual);
  const symbolsList = useSelector((state: RootStateTypes) => state.screenerState.symbolsList, shallowEqual);
  const lastScreenerSymbol = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.lastScreenerSymbol, shallowEqual
  );
  const userFavorite = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings?.chartFavorites, shallowEqual
  );
  const lastDeepListSymbol = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.lastDeepListSymbol, shallowEqual
  );
  const lastAlertsSymbol = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.lastAlertsSymbol, shallowEqual
  );
  const searchValue = useSelector(
    (state: RootStateTypes) => state.screenerState.searchValue, shallowEqual
  );
  const tableLoaded = useSelector(
    (state: RootStateTypes) => state.screenerState.tableLoaded, shallowEqual
  );
  const userLastState = useSelector((state: RootStateTypes) => state.accountState.userSettings, shallowEqual);
  const holidaysList = useSelector((state: RootStateTypes) => state.watchlistState.holidaysList, shallowEqual);
  const alertListFromStore = useSelector((state: RootStateTypes) => state?.alertsState?.alertsList, shallowEqual);
  const overwriteFlag = useSelector((state: RootStateTypes) => state.accountState.overwriteFlag, shallowEqual);
  const tabUpdateFlag = useSelector((state: RootStateTypes) => state.screenerState.tabUpdateFlag, shallowEqual);
  const tvLayoutsList = useSelector((state: RootStateTypes) => state.screenerState.tvLayoutsList, shallowEqual);
  const defaultLayoutId = useSelector((state: RootStateTypes) => state.screenerState.defaultLayoutId, shallowEqual);
  const sameSymbolFlag = useSelector((state: RootStateTypes) => state.screenerState.sameSymbolFlag, shallowEqual);
  const debouncedSymbolSaveWidget = useDebouncedCallback();

  const alertSettingsItemData = useSelector(
    (state: RootStateTypes) => state?.alertsState?.alertSettingsItemData, shallowEqual
  );
  const pagePath = useSelector((state: RootStateTypes) => state.screenerState.pagePath, shallowEqual);

  const successHandler = (title: string) => {
    dispatch(popUpAction(true));
    dispatch(errorMessageTitleAction({ messageTitle: title }));
    dispatch(messageAction({ message: t('layoutRemoved') }));
    dispatch(changeNotificationTypeAction({ type: t('successType') }));
  };

  const setPointsValue = async (id: string, cb: string, currentWidget: CurrentWidget): Promise<void> => {
    await delay(200);
    const activeChartSymbol = symbolFromActiveChart(
      currentWidget.activeChart().symbol(), symbolsList
    );

    if (cb === TV_EVENTS.REMOVE || !id) {
      currentAlertData.data = null;
      return;
    }

    if (cb === TV_EVENTS.PROPERTIES_CHANGED) {
      const { alreadyAlert, shape } = shapeTypeHandler(sharedChartData.alertList, currentWidget, id);
      const properties = shape.getProperties();

      if (properties.linecolor === COMMON_LINE && !alreadyAlert) {
        fillAlertData(id, shape.getPoints(), properties, activeChartSymbol);
      }
    }

    if (cb === TV_EVENTS.CLICK) {
      const { alreadyAlert, shapeType, shape } = shapeTypeHandler(sharedChartData.alertList, currentWidget, id);

      if (alreadyAlert && shapeType) {
        alertActionEdit(alreadyAlert.id);
        return;
      }

      if (!alreadyAlert && shapeType) {
        fillAlertData(id, shape.getPoints(), shape.getProperties(), activeChartSymbol, shape);
      }
    }

    if (cb === TV_EVENTS.CREATE_EVENT) {
      const { shapeType, shape } = shapeTypeHandler(sharedChartData.alertList, currentWidget, id);
      if (shapeType) {
        fillAlertData(id, shape.getPoints(), shape.getProperties(), activeChartSymbol, shape);
      }
    }

    if (cb === TV_EVENTS.POINTS_CHANGED) {
      const { alreadyAlert, shapeType, shape } = shapeTypeHandler(sharedChartData.alertList, currentWidget, id);

      if (!alreadyAlert && shapeType) {
        fillAlertData(id, shape.getPoints(), shape.getProperties(), activeChartSymbol);
      }

      if (shapeType && alreadyAlert) {
        setTimeout(() => {
          const movedPoints = [...shape.getPoints()];
          updateAlertPoints(alreadyAlert, movedPoints);
        }, TWO_HUNDRED);
      }
    }
  };

  const sendAlertAction = async (isEmptyPriceValue = false): Promise<void> => {
    const activeChartSymbol = symbolFromActiveChart(
      sharedChartData.currentWidgetRef?.activeChart().symbol(), symbolsList
    );

    if (currentAlertData.data === null) {
      await priceLineGenerator(
        sharedChartData.currentWidgetRef,
        activeChartSymbol,
        isEmptyPriceValue ? sharedChartData.currentHistoryCacheKey : '',
        isEmptyPriceValue
      );
    }

    const { data } = currentAlertData as { data: TAlertData };

    if (data && DashboardWidgetRef?.current?.id) {
      let widgetSymbol = symbol;
      const searchSymbolIndex = sharedChartData?.activeSymbolIndex;
      if (DashboardWidgetRef?.current?.symbol) {
        widgetSymbol = DashboardWidgetRef?.current?.symbol;
      }
      if (searchSymbolIndex) {
        widgetSymbol = symbolsList.find((item: StoredSymbol) => item.index === searchSymbolIndex)?.sym;
      }
      const symbolFromWidget = symbolFromLayoutHandler(symbolsList, widgetSymbol);
      data.symbol = symbolFromWidget as StoredSymbol;
    }
    alertAction(data);
  };

  const setAlertActionFromTop = async (tvWidget: CurrentWidget, isEmptyPriceValue = false): Promise<void> => {
    await delay(200);
    const activeChartSymbol = symbolFromActiveChart(
      sharedChartData.currentWidgetRef?.activeChart().symbol(), symbolsList
    );

    if (!DashboardWidgetRef?.current?.id) {
      await priceLineGenerator(
        sharedChartData.currentWidgetRef,
        activeChartSymbol,
        sharedChartData.currentHistoryCacheKey,
        isEmptyPriceValue,
      );
    }
    if (DashboardWidgetRef?.current?.id) {
      const symbolFromWidget = symbolFromLayoutHandler(
        symbolsList,
        DashboardWidgetRef?.current?.symbol ? DashboardWidgetRef?.current?.symbol : symbol
      );
      await priceLineGenerator(
        tvWidget,
        symbolFromWidget,
        `${DashboardWidgetRef?.current?.symbol}_${userLastState?.lastResolution}`
      );
    }
    alertAction(currentAlertData.data);
  };

  const errorHandler = (title: string): void => {
    dispatch(popUpAction(true));
    dispatch(errorMessageTitleAction({ messageTitle: `The name ${title} already exists` }));
    dispatch(messageAction({ message: t('changeName') }));
    dispatch(changeNotificationTypeAction({ type: t('error') }));
  };

  const checkIsNeedUpdateSymbol = (): boolean => {
    if (dashWidget?.id) {
      return true;
    }
    return currentSymbolHandler(watchList).name !== symbol;
  };

  const changeSymbolHandler = (widgetSymbol: string | null): void => {
    if (checkIsNeedUpdateSymbol() && Authorized) {
      if (widgetSymbol) {
        setSymbol(widgetSymbol);
      } else {
        if (dashWidget?.id && tradingViewWidget && typeof updateSymbolSync === 'function') {
          debouncedSymbolSaveWidget(
            updateSymbolSync,
            TWO_HUNDRED,
            currentSymbolHandler(watchList).name,
            dashWidget?.id
          );
          return;
        }
        if (dashWidget?.id && typeof updateSymbolSync === 'function') {
          debouncedSymbolSaveWidget(
            updateSymbolSync,
            TWO_HUNDRED,
            currentSymbolHandler(watchList).name,
            dashWidget?.id
          );
        }
        setSymbol(currentSymbolHandler(watchList).name);
      }
    }
  };

  const handleSaveChartLayoudId = (id: number, widgetId: string): void => {
    saveChartLayoudId(id, widgetId);
    if (DashboardWidgetRef?.current?.id === widgetId) {
      changeSymbolHandler(DashboardWidgetRef?.current?.symbol as string);
    }
  };

  const handleSetSelectedShapeId = (id: string | null) => {
    sharedChartData.selectedShapeId = id;
    dispatch(setSelectedShapeId(id));
  };

  useEffect(() => {
    if (activeSymbolIndexValue && dashWidget) {
      const globalSearchSymbol = symbolsList.find((item: StoredSymbol) => item.index === activeSymbolIndexValue)?.sym;
      changeSymbolHandler(globalSearchSymbol ? globalSearchSymbol as string : null);
    }
    if (!activeSymbolIndexValue && dashWidget) {
      changeSymbolHandler(dashWidget?.id ? dashWidget?.symbol as string : null);
    }
  }, [activeSymbolIndexValue]);

  useEffect(() => {
    sharedChartData.currentSymbolData = currentSymbolHandler(watchList);
    tradingViewWidget?.onChartReady(() => {
      if (
        tradingViewWidget
        && !dashWidget?.id
        && tradingViewWidget?.activeChart().symbol() !== symbol
        && checkIsNeedUpdateSymbol()
      ) {
        setSymbol(tradingViewWidget?.activeChart().symbol() as string);
      }
    });

    if (!dashWidget?.id) {
      changeSymbolHandler(null);
    } else {
      if (activeSymbolIndexValue && resetSearch) {
        resetSearch();
      }
      changeSymbolHandler(dashWidget?.id ? dashWidget?.symbol as string : null);
    }
  }, [
    userLastState?.currentSessionFromStore,
    lastScreenerSymbol,
    lastAlertsSymbol,
    lastDeepListSymbol,
    searchValue,
    Authorized,
    sameSymbolFlag,
    dashWidget?.symbol,
    dashWidget?.chartLayoutId,
    pagePath
  ]);

  useEffect(() => {
    if (
      symbol
      && !tradingViewWidget
      && Object.keys(userProfile)?.length
      && Socket
      && Authorized
      && tvLayoutsList.length
    ) {
      const configuration = widgetOptions({
        setLayoutId,
        setSymbol,
        symbol,
        interval: userLastState?.activeChartLayoutId ? null : (userLastState?.lastResolution || '1D'),
        errorHandler,
        alertAction: sendAlertAction,
        userProfile,
        theme: theme.substring(0, theme.indexOf('-')),
        userFavorite,
        favoriteCallback: saveFavoriteState,
        updateStoreHandler,
        userLastState,
        dashWidget,
        tvLayoutsList,
        saveChartLayoudId: handleSaveChartLayoudId,
        updateCrosshairSync,
        successHandler,
        defaultLayoutId,
        setUpdateInstance,
        datafeed: new TradingViewAdapter({
          symbol,
          interval: userLastState?.lastResolution || '1D',
          symbolsList,
          searchSymbols: handleDebounce(chartSearch, 300, symbolsList),
          socket: Connection,
          chanelId: userProfile.id,
          currentPath: history.location.pathname,
          holidaysList,
          extendedFromUser: RefExtended,
          dashWidgetId: dashWidget?.id as string,
        }),
      });

      // eslint-disable-next-line
      const newWidget: IExtendCurrentWidget = new widget(configuration);
      if (newWidget) {
        newWidget.datafeed = configuration.datafeed;
        newWidget.onChartReady(() => {
          newWidget?.watermark().setContentProvider((data) => contentHandler(data));
          if (userLastState[DEFINE_TIMESCALE_MARK]) {
            const definedMarks = JSON.parse(userLastState[DEFINE_TIMESCALE_MARK]);
            if (!definedMarks) {
              newWidget?.activeChart()?.executeActionById('hideAllMarks');
            }
          }
          timeScaleApplier(newWidget, updateStoreHandler);
          timeScaleListener(newWidget, updateStoreHandler);
          shapeMoveListener(newWidget, handleSetSelectedShapeId);
          autoSaveSubscribe({
            tradingViewWidget: newWidget,
          });
          const cursorPreference = userLastState[CHART_CURSOR];
          newWidget?.subscribe(TV_EVENTS.LINE_TOOL_CHANGED as keyof SubscribeEventsMap, () => {
            updateStoreHandler(CHART_CURSOR, newWidget?.selectedLineTool());
          });
          if (cursorPreference) {
            newWidget?.selectLineTool(cursorPreference);
          }
          if (dashWidget?.id
            && typeof updateIntervalSync === 'function'
            && typeof updateTimeSync === 'function'
            && typeof updateChartSymbolSync === 'function'
            && typeof updateDateRangeSync === 'function') {
            newWidget?.intervalSync().subscribe((value) => {
              updateIntervalSync(value, dashWidget?.id);
            });
            newWidget?.timeSync().subscribe((value) => {
              updateTimeSync(value, dashWidget?.id);
            });
            newWidget?.symbolSync().subscribe((value) => {
              updateChartSymbolSync(value, dashWidget?.id);
            });
            newWidget?.dateRangeSync().subscribe((value) => {
              updateDateRangeSync(value, dashWidget?.id);
            });
          } else {
            newWidget?.symbolSync().subscribe((value) => updateStoreHandler(SYMBOL_LOCK, value));
          }
          newWidget?.subscribe(TV_EVENTS.TOGGLE_SIDEBAR as keyof SubscribeEventsMap, (isHidden: boolean) => {
            if (userLastState.sidebarToggle !== isHidden) {
              updateStoreHandler(SIDEBAR_TOGGLE, isHidden);
            }
          });
          if (!userLastState.sidebarToggle) {
            newWidget?.activeChart().executeActionById(DRAWING_TOOLBAR);
          }
          newWidget?.activeChart().dataReady(() => dispatch(setTvIsReady(false)));
          headerReadySubscribe(
            newWidget,
            setAlertActionFromTop,
            userLastState,
            dispatch,
            updateStoreHandler,
            dashWidget?.id
          );
          alertSubscribe(
            newWidget,
            setPointsValue,
            setPointsForClick
          );
          shortcutSubscribe(newWidget, setAlertActionFromTop);
        });
      }

      setTradingViewWidget(newWidget);
      sharedChartData.currentWidgetRef = newWidget;
      resetChartData.setChart(newWidget);

      if (dashWidget?.id && dashboardChartRef) {
        // eslint-disable-next-line
        dashboardChartRef.current = newWidget as CurrentWidget;
      }
    }
  }, [symbol, userProfile, Socket, Authorized, tvLayoutsList]);

  useEffect(() => {
    if (tradingViewWidget) {
      tradingViewWidget?.onChartReady(() => {
        if (dashWidget?.id) {
          tradingViewWidget.symbolSync().setValue(dashWidget?.symbolSync as boolean);
          tradingViewWidget.intervalSync().setValue(dashWidget?.intervalSync as boolean);
          tradingViewWidget.timeSync().setValue(dashWidget?.timeSync as boolean);
          tradingViewWidget.dateRangeSync().setValue(dashWidget?.dateRangeSync as boolean);
        } else {
          tradingViewWidget.symbolSync().setValue(userLastState.symbolLock);
        }
      });
    }
  }, [tradingViewWidget, userLastState.symbolLock]);

  useEffect(() => {
    if (theme && tradingViewWidget) {
      if (previousTheme !== theme) {
        onChangeTradingViewTheme(
          tradingViewWidget,
          theme,
          userLastState?.chartproperties,
          overwriteFlag,
          userLastState?.activeChartLayoutId,
          tvLayoutsList,
          userLastState?.layoutFlag,
        );
      }
    }
  }, [theme, tradingViewWidget]);

  useEffect(() => {
    if (userLastState?.chartproperties && tradingViewWidget && theme) {
      applyOverridesHandler(
        userLastState?.chartproperties,
        tradingViewWidget,
        theme,
        userLastState?.layoutFlag
      );
    }
  }, [userLastState?.chartproperties, tradingViewWidget]);

  useEffect(() => {
    currentAlertData.data = null;

    if (symbol) {
      changeActiveSymbol({
        tradingViewWidget,
        symbol,
        contentHandler,
      });
    }
  }, [symbol, tradingViewWidget, layoutId]);

  useEffect(() => {
    tradingViewWidget?.onChartReady(() => {
      tradingViewWidget?.activeChart().dataReady(() => {
        const activeChartSymbol = symbolFromActiveChart(
          tradingViewWidget?.activeChart().symbol(), symbolsList
        );
        getAlertsForSymbolIndex(activeChartSymbol.name).then((alerts) => {
          crossLayoutShapeHandler(tradingViewWidget, alerts);
        });
      });
    });
  }, [layoutId]);

  useEffect(() => {
    const symbolFromLayout = symbolFromLayoutHandler(symbolsList, dashWidget?.symbol ? dashWidget?.symbol : symbol);
    sharedChartData.currentSymbolData = dashWidget?.id ? symbolFromLayout : currentSymbolHandler(watchList);

    if (symbol) {
      tradingViewWidget?.onChartReady(() => {
        tradingViewWidget?.activeChart().dataReady(() => {
          const activeChartSymbol = symbolFromActiveChart(
            tradingViewWidget?.activeChart().symbol(), symbolsList
          );

          getAlertsForSymbolIndex(activeChartSymbol.name).then((alerts) => {
            multipleLayoutHandler(tradingViewWidget, updateAlertLinks, alerts);
            alertsHandler(tradingViewWidget, alerts);
            if (dashWidget?.id && alerts?.length) {
              crossLayoutPositionHandler(tradingViewWidget, alerts);
            }
          });
        });
      });
    }

    DashboardWidgetRef.current = dashWidget as Widget;
  }, [tradingViewWidget, symbol, dashWidget?.symbol, layoutId]);

  useEffect(() => {
    tradingViewWidget?.onChartReady(() => {
      tradingViewWidget?.activeChart().dataReady(() => {
        const activeChartSymbol = symbolFromActiveChart(
          tradingViewWidget?.activeChart().symbol(), symbolsList
        );

        getAlertsForSymbolIndex(activeChartSymbol.name).then((alerts) => {
          alertsHandler(tradingViewWidget, alerts);
        });
      });
    });
  }, [alertListFromStore]);

  useEffect(() => {
    const symbolFromLayout = symbolFromLayoutHandler(symbolsList, dashWidget?.symbol ? dashWidget?.symbol : symbol);
    sharedChartData.currentSymbolData = dashWidget?.id ? symbolFromLayout : currentSymbolHandler(watchList);

    tradingViewWidget?.onChartReady(() => {
      tradingViewWidget?.activeChart().dataReady(() => {
        const activeChartSymbol = symbolFromActiveChart(
          tradingViewWidget?.activeChart().symbol(), symbolsList
        );

        getAlertsForSymbolIndex(activeChartSymbol.name).then((alerts) => {
          crossLayoutPositionHandler(tradingViewWidget, alerts);
        });
      });
    });
  }, [layoutId, alertSettingsItemData, alertListFromStore.length]);

  useEffect(() => {
    sharedChartData.alertList = alertListFromStore;
  }, [alertListFromStore]);

  useEffect(() => {
    tradingViewWidget?.onChartReady(() => {
      removeAlertWithTrendLine(tradingViewWidget, alertListFromStore, removeAlerts, handleSetSelectedShapeId);
    });
  }, [alertListFromStore, tradingViewWidget]);

  useEffect(() => {
    sharedChartData.currentHistoryCacheKey = `${symbol}_${userLastState?.lastResolution}`;
    clearLastPrice();
  }, [symbol, userLastState?.lastResolution, tableLoaded]);

  useEffect(() => {
    if (Authorized) {
      Connection?.messageEmitter.on(WS_CHANNELS.PING_PERIODICAL_DATA, (data) => {
        if (data.errors?.length && awakeSocketWidget) {
          awakeSocketWidget();
          return;
        }

        pingCallback(data, Connection);
      });

      Connection?.messageEmitter?.on(WS_CHANNELS.CHART_SUBSCRIBE, (data) => {
        if (data.errors?.length && awakeSocketWidget) {
          awakeSocketWidget();
          return;
        }

        saveChartIdToSubscription(data.chartId, data.messageId);
      });

      Connection?.messageEmitter?.on(WS_CHANNELS.CHART_TICK, (data) => {
        if (data.errors?.length && awakeSocketWidget) {
          awakeSocketWidget();
          return;
        }

        rtDataListenerFromSocket(data?.symbolIndex, data.chartId, data);
      });

      Connection?.messageEmitter?.on(WS_EVENTS.EXCEPTION, (err) => {
        if (err.errors?.length && awakeSocketWidget) {
          awakeSocketWidget();
          return;
        }

        setToLocalStorage(WS_CHART_ERROR, err);
      });
    }
  }, [Authorized, Socket]);

  useEffect(() => unbindAll(tradingViewWidget as CurrentWidget), []);

  useEffect(() => {
    if (Socket && tradingViewWidget) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tradingViewWidget.datafeed.socket = Connection;
    }
  }, [Socket, Socket?.readyState, Authorized, tabUpdateFlag]);

  useEffect(() => {
    RefExtended.current = userLastState?.currentSessionFromStore;
  }, [userLastState?.currentSessionFromStore]);

  useEffect(() => {
    if (isAlertTable && tradingViewWidget) {
      changeActiveSymbol({
        tradingViewWidget,
        symbol,
        contentHandler,
      });
    }
    if (tradingViewWidget && dashWidget?.id) {
      tradingViewWidget.onChartReady(() => {
        // eslint-disable-next-line
        // @ts-ignore
        // eslint-disable-next-line no-underscore-dangle
        const widgetClassList = tradingViewWidget._iFrame.contentWindow.document.body.classList;
        widgetClassList.add('chartInWidgetAPP');
      });
    }
  }, [isAlertTable, tradingViewWidget]);

  useEffect(() => () => {
    if (!dashWidget?.id) {
      chartCleanUp(tradingViewWidget);
    }
  }, []);

  useEffect(() => {
    if (tradingViewWidget) {
      injectScriptToIframe(dashWidget as Widget);
    }
  }, [tradingViewWidget]);

  useEffect(() => {
    if (Connection && tradingViewWidget && Authorized) {
      resetChartData.resetDataHandler(currentSymbolHandler(watchList).index);
    }
  }, [Connection, Authorized]);

  return (
    <>
      {theme && (
        <div id="tvChart" className={`${styles.chartContainer}`}>
          <div
            id={dashWidget?.id ? `tv_chart_container_${dashWidget?.id}` : 'tv_chart_container'}
            className={`${styles.chartContainer} focusTarget`}
          />
        </div>
      )}
    </>
  );
};

export default React.memo(ChartContainer);
