import React, { useState, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';
import Input from '../../Input/Input';
import { ReactComponent as SearchIcon } from '../../../assets/images/icons/search.svg';
import styles from '../sass/FilterScreen.module.scss';
import TextField from '../../TextField/TextField';
import { searchItem, getDeepItemsForSearch } from '../../../utils/helperFilter';
import constants from '../../../constants/filters';
import useOutsideAlerter from '../../../utils/hooks/useOutside';
import {
  TFilterGroupe, TShowFilter
} from '../Types';
import RootStateTypes from '../../../store/RootStateTypes';

interface IFilterSearch {
  filterList: TFilterGroupe[];
  adminUser: boolean;
  isVisibleFilters: boolean;
  handlerSearchFilter: (
    id: number,
    type: string | undefined,
    columnId: number,
    list: TFilterGroupe[],
    category?: string
  ) => void
}

const FilterSearch = ({
  filterList, adminUser, isVisibleFilters, handlerSearchFilter
}: IFilterSearch): React.ReactElement => {
  const { t } = useTranslation();
  const selectedRef = useRef<TShowFilter | null>(null);
  const [initialList, setInitialList] = useState<TShowFilter[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [result, setResult] = useState<TShowFilter[]>([]);
  const [viewDrop, setViewDrop, ref] = useOutsideAlerter(false);

  const initialFilterList = useSelector((state: RootStateTypes) => state.screenerState.initialFilterList, shallowEqual);

  useEffect(() => {
    const cloneFilterList = JSON.parse(JSON.stringify(filterList)) as TFilterGroupe[];
    let newArray: TShowFilter[] = [];
    cloneFilterList.forEach((category) => {
      category?.items?.forEach((item) => {
        if (item.fShow || adminUser) {
          const filterItem = {
            title: item.name,
            category: category.name,
            id: item.id as number,
          };
          if (item.fType === 'dropdown') {
            newArray.push(filterItem as unknown as TShowFilter);
            const deepItems = getDeepItemsForSearch(item?.data, item.name, category.name);
            newArray = [...newArray, ...deepItems];
          } else {
            newArray.push(filterItem as unknown as TShowFilter);
          }
        }
      });
    });
    setInitialList(newArray);
  }, [filterList]);

  const searchValueHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSearchValue(event.target.value);
  };

  const closeDropList = (category: string, id: number) => {
    handlerSearchFilter(id, undefined, id, initialFilterList, category);
    setViewDrop(false);
    setSearchValue('');
  };

  const enterPressHandler = (e: React.KeyboardEvent) => {
    const item = selectedRef.current as TShowFilter;
    if (e.key === 'Enter') {
      closeDropList(item?.category, item.id);
    }
  };

  useEffect(() => {
    const resultItems = searchItem(initialList, searchValue, constants.title);
    setResult(resultItems as unknown as TShowFilter[]);
    if (searchValue) {
      setViewDrop(true);
    } else {
      setViewDrop(false);
    }
  }, [searchValue]);

  useEffect(() => {
    if (result.length > 0) {
      const [firstItem] = result;
      selectedRef.current = firstItem;
    } else {
      selectedRef.current = null;
    }
  }, [result]);

  useEffect(() => {
    window.addEventListener('keydown', (e) => enterPressHandler(e as unknown as React.KeyboardEvent));
    return () => {
      window.removeEventListener('keydown', (e) => enterPressHandler(e as unknown as React.KeyboardEvent));
    };
  }, []);

  return (
    <div className={styles.searchWrapper} ref={ref}>
      <Input
        IconBefore={SearchIcon}
        placeholder={t('searchByFilter')}
        value={searchValue}
        handlerChange={searchValueHandler}
        inputClass={styles.searchInput}
        isFocused={isVisibleFilters}
      />
      {viewDrop && (
      <div className={styles.searchResultList}>
        {result.length > 0 ? (
          <>
            {result && result.map((item, index) => (
              <div
                aria-hidden="true"
                className={styles.resultItem}
                key={[item?.title, index].join('_')}
                onClick={() => closeDropList(item?.category, item.id)}
                role="button"
                tabIndex={0}
              >
                <TextField text={t(`${item?.title}`)} styleType="resultItem" />
                <TextField text={`in ${t(`${item?.category}`)}`} styleType="resultItemCategory" />
              </div>
            ))}
          </>
        ) : (
          <div className={styles.resultItem}>
            <TextField text={t('noResultsFound')} styleType="resultItemCategory" />
          </div>
        )}
      </div>
      )}
    </div>

  );
};

export default FilterSearch;
