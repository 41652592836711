import React, {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AutoSizer, Grid } from 'react-virtualized';

import { useHistory } from 'react-router-dom';
import { setVisibleItemsTableStore } from '../../store/tableData/slice';
import { getFromLocalStorage, removeLocalStorage } from '../../utils/storageWorks';
import ScreenerTableHeader from './ScreenerTableHeader';
import SymbolCounter from '../SymbolCounter/SymbolCounter';
import ModalLayout from '../../layout/ModalLayout/ModalLayout';
import SettingsComponent from './components/SettingsComponent';
import VisibilitySetting from '../VisibilitySetting/VisibilitySetting';
import {
  SUBSCRIPTION_TYPE,
  KEY_CODES,
  TABLE_COLUMNS_MIN_SIZES,
  TABLE_COLUMNS_MAX_SIZES,
  ONE_HUNDRED,
  MOUSE_BUTTONS,
  FIND_NAME,
  SYM_COLUMN_INDEX,
  MAX_COLUMN_WIDTH,
  TOUCH_EVENT,
  MOUSE_EVENTS,
  ADDITIONAL_DATA_COLUMNS,
  ROW_STYLES,
  CURRENT_PAGE_DEFAULT,
  COLUMN_COUNT,
  SCREENER_PADDING_WIDGET,
  SCREENER_PADDINGS,
  OVERSCAN_ROW_COUNT,
  symbolColumn,
  LAST_ITEM_SCREENER_WIDTH,
  STATUS_ALERT_COLUMN,
  MS_INTERVAL, TABLE_SCREEN_PATH, defaultPage
} from '../../constants/screener';
import { VIEW_TYPE_TARGET } from '../../constants/watchlist';

import useWatchList from '../../utils/hooks/useWatchList';
import { findIndexItem } from '../../utils/helpers';
import useColumnsSizeState from '../Columns/hooks/useColumnsSizeState';
import useLastSymbolState from '../../utils/hooks/useLastSymbolState';
import { deepEqual } from '../../utils/objectHelper';
import {
  setActiveRowStyle,
  setListForFilterData, setRoute,
  setSearchValue,
  setSelectedShapeId,
  setVisibleColumnsScreenAction,
  toggleSameSymbol
} from '../../store/screener/actions';
import listVisibilitySetting from '../../lists/listVisibilitySetting';

import styles from './sass/ScreenerTable.module.scss';
import { ReactComponent as IconResize } from '../../assets/images/icons/resizeDragIcon.svg';
import { getColumnsSetId } from '../DasboardComponents/utils/utils';
import {
  ALERT_SYMBOL, SELECTED_TAB_ID, VISIBILITY_SETTINGS
} from '../../constants/storage';
import useLastStateUpdateStore from '../../utils/hooks/useLastStateUpdateStore';
import UpgradeAccountBanner from '../UpgradeAccountBanner/UpgradeAccountBanner';
import usePrepareLastSymbolState from '../../utils/hooks/usePrepareLastSymbolState';
import { scrollProcessor } from '../../services/ScrollRequestProcessor';
import { TABLE_LISTENER_ID } from '../../constants/shortcuts';
import ScreenerTableItem from './ScreenerTableItem';
import AlertAction from './components/AlertAction';
import SymbolTableItem from './components/SymbolTableItem';
import StatusAlertComponent from './components/StatusAlertComponent';
import EmptyTableItem from './components/EmptyTableItem';
import { sharedChartData } from '../ChartContainer/constants/constants';
import { setSelectedTab } from '../../store/watchlist/actions';

const areEqual = (prevProps, nextProps) => {
  return deepEqual(prevProps.data, nextProps.data)
    && prevProps.loaderStatus === nextProps.loaderStatus
    && prevProps.activeAlert === nextProps.activeAlert
    && prevProps.hideChart === nextProps.hideChart
    && deepEqual(prevProps.columns, nextProps.columns)
    && prevProps.showIcons === nextProps.showIcons
    && prevProps.selectedItems === nextProps.selectedItems
    && prevProps.widgetSymbol === nextProps.widgetSymbol
    && prevProps.watchList === nextProps.watchList
    && prevProps.activePosition === nextProps.activePosition
    && prevProps.scrollTop === nextProps.scrollTop
    && prevProps.handleChangeLayoutSize === nextProps.handleChangeLayoutSize
    && prevProps.isSearch === nextProps.isSearch
    && JSON.stringify(prevProps.sortData) === JSON.stringify(nextProps.sortData)
    && prevProps.sortStatus === nextProps.sortStatus
    && prevProps.activeVisibility === nextProps.activeVisibility
    && prevProps.selectedItems === nextProps.selectedItems
    && prevProps.hideColumnsSettings === nextProps.hideColumnsSettings;
};

const ScreenerTable = ({
  data,
  columns,
  setItems,
  setLoader,
  sortStatus,
  isScrolled,
  setAllItems,
  sortHandler,
  selectedItems,
  keyDownHandler,
  setIsVisibleFilters,
  loaderStatus,
  profile,
  clearSorting,
  handleChangeLayoutSize,
  hideChart,
  handleOpenChart,
  setHideEvents,
  hideColumnsSettings,
  activeAlert,
  activePosition,
  setActiveAlert,
  watchList,
  scrollTop,
  isSearch,
  isWidget,
  additionalDataWidget,
  volumeBarsWidget,
  setVisibleItemsTable,
  selectedItemFromWidget,
  selectedColumnIdFromWidget,
  handleSetActiveOnWidget,
  widgetId,
  sortData,
  setOpenEtfWidget,
  isHoldingViewFromWidget,
  widgetSymbol,
  clearSelections,
  setTopIndexWidget,
  sectorIndustryWidgetHandler,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const currentSymbolRef = useRef(null);
  const resizeRef = useRef(null);
  const headerRef = React.createRef();
  const headerScroll = React.createRef();
  const lastChildRef = useRef(null);

  const watchLists = useSelector((state) => state.watchlistState.tabsList, shallowEqual);
  const userSettings = useSelector((state) => state.accountState.userSettings, shallowEqual);
  const itemsColumnSets = useSelector((state) => state.newScreenState.itemsColumnSets, shallowEqual);
  const itemsNewScreen = useSelector((state) => state.newScreenState.itemsNewScreen, shallowEqual);
  const presetsNewScreen = useSelector((state) => state.newScreenState.presetsNewScreen, shallowEqual);
  const tabsList = useSelector((state) => state.watchlistState.tabsList, shallowEqual);
  const presets = useSelector((state) => state.watchlistState.presets, shallowEqual);
  const visibilitySetting = useSelector((state) => state.accountState.userSettings.visibilitySetting, shallowEqual);
  const activeRowStyle = useSelector((state) => state.screenerState.activeRowStyle, shallowEqual);
  const selectedTab = useSelector((state) => state.watchlistState.selectedTab, shallowEqual);
  const alertTab = useSelector((state) => state.watchlistState.alertsTab, shallowEqual);
  const pagePath = useSelector((state) => state.screenerState.pagePath, shallowEqual);
  const searchHeaderValue = useSelector((state) => state.screenerState.globalSearchHeaderValue, shallowEqual);
  const globalSearchVisible = useSelector((state) => state.accountState.globalSearchVisible, shallowEqual);
  const counterSymbols = useSelector((state) => state.tableDataState.counterSymbols, shallowEqual);
  const symbolsList = useSelector((state) => state.screenerState.symbolsList, shallowEqual);
  const hoveredSymbolIndex = useSelector((state) => state.tableDataState.hoveredSymbolIndex, shallowEqual);
  const selectedScreenId = useSelector((state) => state.accountState.userSettings.selectedScreenId, shallowEqual);
  const globalHoldingSearch = useSelector((state) => state.dashboardsState.globalHoldingSearch, shallowEqual);
  const globalSectorIndustrySearch = useSelector(
    (state) => state.dashboardsState.globalSectorIndustrySearch, shallowEqual
  );

  const [viewVisibilitySettingModal, setViewVisibilitySettingModal] = useState(false);
  const [activeVisibility, setActiveVisibility] = useState({});
  const [scrollLeft, setScrollLeft] = useState(0);
  const [prevTouchX, setPrevTouchX] = useState(0);
  const [startResize, setStartResize] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [gridWidth, setGridWidth] = useState(MAX_COLUMN_WIDTH);
  const [widgetResizeSelector, setWidgetResizeSelector] = useState(0);
  const [columnSetIdWidget, setColumnSetIdWidget] = useState(null);
  const [isHorizontalScroll, setHorizontalScroll] = useState(false);
  const [activeScrollPosition, setActiveScrollPosition] = useState(undefined);

  const symItemIndex = findIndexItem(columns, FIND_NAME.INDEX, SYM_COLUMN_INDEX);

  const { currentSymbolHandler } = usePrepareLastSymbolState();
  const { updateLastSymbolState } = useLastSymbolState();
  const { columnsSize, saveScreenerColumnsState } = useColumnsSizeState(hideColumnsSettings);
  const { handlerTargetList } = useWatchList();
  const { updateStoreHandler } = useLastStateUpdateStore();

  const getColumnsSizeWidget = () => {
    if (!selectedItemFromWidget) {
      const defaultColumnsSet = userSettings?.lastScreenerColumnsSet;
      const sizesFromDefault = userSettings?.columnsWidth?.[defaultColumnsSet];
      if (widgetResizeSelector !== defaultColumnsSet) {
        setColumnSetIdWidget(defaultColumnsSet);
        setWidgetResizeSelector(defaultColumnsSet);
      }
      return sizesFromDefault || {};
    }
    const columnsSetId = getColumnsSetId(
      selectedItemFromWidget,
      watchList,
      itemsNewScreen,
      presetsNewScreen,
      tabsList,
      presets,
      itemsColumnSets,
      userSettings,
    );
    if (columnSetIdWidget !== columnsSetId) {
      setColumnSetIdWidget(columnsSetId);
    }
    if (widgetResizeSelector !== columnsSetId) {
      setWidgetResizeSelector(columnsSetId);
    }
    return userSettings?.columnsWidth?.[columnsSetId] || {};
  };

  const currentColumnsSize = isWidget ? getColumnsSizeWidget() : (columnsSize || {});

  const toggleIconHandler = (value) => setViewVisibilitySettingModal(value);

  const visibleColumnsScreen = useSelector(
    (state) => state.screenerState.visibleColumnsScreen, shallowEqual
  );

  const toggleColumnsHandler = (visible) => {
    setIsVisibleFilters(false);
    dispatch(setVisibleColumnsScreenAction(visible));
  };

  useEffect(() => {
    const getVisibilitySetting = visibilitySetting;
    let stateActive = {};
    if (getVisibilitySetting) {
      stateActive = JSON.parse(getVisibilitySetting);
    } else {
      listVisibilitySetting.forEach((el) => {
        stateActive[el.title] = true;
      });
    }
  }, [data]);

  useEffect(() => {
    if (currentSymbolRef.current) {
      currentSymbolRef.current.focus();
    }
  }, [currentSymbolRef.current]);

  useEffect(() => {
    currentSymbolRef.current = document.getElementById(
      widgetId ? `${widgetId}_${widgetSymbol}` : `${currentSymbolHandler(watchList).index}`
    );
    return () => {
      currentSymbolRef.current = null;
    };
  }, [
    widgetSymbol,
    userSettings?.lastScreenerSymbol,
    userSettings?.lastDeepListSymbol,
    userSettings?.lastAlertsSymbol
  ]);

  const scrollBeforeSet = (event, ref) => {
    event.preventDefault();
    const container = document.getElementById(isWidget ? `tableScroll_${widgetId}` : 'tableScroll');

    if (container) {
      const rectContainer = container.getBoundingClientRect();
      const rectItem = ref.getBoundingClientRect();

      const isItemAboveView = rectItem.top < rectContainer.top;
      const isItemBelowView = rectItem.bottom > rectContainer.bottom;

      switch (event.code) {
        case KEY_CODES.ARROW_UP:
          if (isItemAboveView) {
            ref.scrollIntoView({ behavior: 'auto', block: 'center' });
          }
          if (activePosition + 1 === data.length && watchList) {
            ref.scrollIntoView({ behavior: 'auto', block: 'center' });
          }
          if (!activePosition && watchList) {
            setScrollToBottom(true);
          } else {
            setScrollToBottom(false);
          }
          break;
        case KEY_CODES.ARROW_DOWN:
        case KEY_CODES.SPACE:
          if (isItemBelowView) {
            ref.scrollIntoView({ behavior: 'auto', block: 'center' });
          }
          break;
        default:
          break;
      }
      keyDownHandler(event);
    }
  };

  const initColumnsSize = (width, columnId) => {
    const oldStoreColumns = currentColumnsSize;
    if (!oldStoreColumns[columnId]) {
      oldStoreColumns[columnId] = {
        minWidth: width,
        startWidth: TABLE_COLUMNS_MIN_SIZES[columnId] || TABLE_COLUMNS_MIN_SIZES.default,
        maxWidth: TABLE_COLUMNS_MAX_SIZES[columnId] || TABLE_COLUMNS_MAX_SIZES.default
      };
    } else {
      oldStoreColumns[columnId] = {
        startWidth: TABLE_COLUMNS_MIN_SIZES[columnId] || TABLE_COLUMNS_MIN_SIZES.default,
        maxWidth: TABLE_COLUMNS_MAX_SIZES[columnId] || TABLE_COLUMNS_MAX_SIZES.default
      };
    }
    if (!oldStoreColumns[columnId]?.maxWidth) {
      oldStoreColumns[columnId].maxWidth = TABLE_COLUMNS_MAX_SIZES[columnId] || TABLE_COLUMNS_MAX_SIZES.default;
    }
    saveScreenerColumnsState(oldStoreColumns, columnSetIdWidget);
  };

  const updateColumnsSize = (width, columnId) => {
    const oldStoreColumns = currentColumnsSize;
    if (oldStoreColumns[columnId]) {
      oldStoreColumns[columnId].minWidth = width;
      if (columnId === SYM_COLUMN_INDEX) {
        oldStoreColumns[columnId].maxWidth = MAX_COLUMN_WIDTH;
      }
      saveScreenerColumnsState(oldStoreColumns, columnSetIdWidget);
    }
  };

  const setActiveHandler = (id) => {
    const newState = { ...activeVisibility };
    newState[id] = !newState[id];
    setActiveVisibility(newState);
    updateStoreHandler(VISIBILITY_SETTINGS, JSON.stringify(newState));
  };

  const checkHorizontallScroll = (e) => {
    if (e?.currentTarget?.scrollLeft !== scrollLeft) {
      setScrollLeft(0);
    }
  };

  const moveAction = useCallback((e) => {
    if (resizeRef?.current) {
      const { left, width } = resizeRef.current?.getBoundingClientRect();
      const layoutRect = document.getElementById('screenerLayout')?.getBoundingClientRect();
      if (layoutRect?.width) {
        const currentClientX = e?.type === TOUCH_EVENT.TOUCH_MOVE ? e.changedTouches[0]?.clientX : e?.clientX;
        const deltaCursor = currentClientX - (left + (width / 2));
        handleChangeLayoutSize((deltaCursor / layoutRect?.width) * ONE_HUNDRED);
      }
    }
  }, []);

  const onStartResize = (event) => {
    if (hideChart) return;
    event?.preventDefault();
    event?.stopPropagation();
    if (event?.button === MOUSE_BUTTONS.LEFT || event.type === TOUCH_EVENT.TOUCH_START) {
      setStartResize(true);
    } else {
      setStartResize(false);
    }
  };

  const upAction = () => {
    setStartResize(false);
  };

  const scrollGridListener = (event) => {
    const tableHeader = document.querySelector(widgetId ? `#tableHeader_${widgetId}` : '#tableHeader');
    if (event?.scrollLeft !== scrollLeft) {
      setScrollLeft(0);
    }

    tableHeader.scrollLeft = event.scrollLeft;
  };

  const wheelGridListener = (event) => {
    event.preventDefault();

    if (event?.target?.id !== MOUSE_EVENTS.RESIZE) {
      const gridSelector = document.querySelector(
        widgetId
          ? `#tableScroll_${widgetId} .ReactVirtualized__Grid`
          : '.ReactVirtualized__Grid'
      );
      const tableHeader = document.querySelector(widgetId ? `#tableHeader_${widgetId}` : '#tableHeader');

      const deltaY = event.type !== MOUSE_EVENTS.WHEEL ? 0 : event.deltaY;
      const deltaX = event.type !== MOUSE_EVENTS.WHEEL ? prevTouchX - event.touches[0].clientX : event.deltaX;

      if (gridSelector) {
        const scrollPositionX = gridSelector.scrollLeft + deltaX;
        const scrollPositionY = gridSelector.scrollTop + deltaY;

        tableHeader.scrollLeft = scrollPositionX;
        gridSelector.scrollLeft = scrollPositionX;
        gridSelector.scrollTop = scrollPositionY;
      }
    }
  };

  const touchGreedDelta = (event) => setPrevTouchX(event.touches[0].clientX);

  const cleanListeners = () => {
    document.removeEventListener('mouseup', upAction, true);
    document.removeEventListener('touchend', upAction, true);
    document.removeEventListener('mousemove', moveAction, true);
    document.removeEventListener('touchmove', moveAction, true);
    document.removeEventListener('scroll', checkHorizontallScroll);
    document.removeEventListener('wheel', wheelGridListener);
    document.removeEventListener('touchmove', wheelGridListener);
    document.removeEventListener('touchstart', touchGreedDelta);
  };

  useEffect(() => {
    if (startResize) {
      setHideEvents(true);
      document.addEventListener('mouseup', upAction, true);
      document.addEventListener('touchend', upAction, true);
      document.addEventListener('mousemove', moveAction, true);
      document.addEventListener('touchmove', moveAction, true);
    } else {
      setHideEvents(false);
      cleanListeners();
    }

    return () => {
      cleanListeners();
    };
  }, [startResize]);

  const setGridWidthHandler = () => {
    if (currentColumnsSize) {
      setGridWidth(Object.values(currentColumnsSize).reduce(
        (a, b) => a + (b.minWidth || b.startWidth) || TABLE_COLUMNS_MIN_SIZES.default, 0
      ));
    }
  };

  useEffect(() => {
    if (headerRef.current?.clientWidth) {
      setGridWidth(headerRef.current?.clientWidth);
    } else {
      setGridWidthHandler();
    }
  }, [currentColumnsSize, columns]);

  useEffect(() => {
    if (watchList && scrollToBottom && data.length === counterSymbols && !getFromLocalStorage(ALERT_SYMBOL)) {
      updateLastSymbolState(data.at(-1)[0], true, watchList, false, data.length - 1);
      setScrollToBottom(false);
    }
  }, [scrollToBottom, data]);

  useEffect(() => {
    const symbolIndex = getFromLocalStorage(ALERT_SYMBOL);
    if (symbolIndex && symbolsList.length && data.length) {
      updateLastSymbolState(symbolsList.find((symbol) => symbol.sym === symbolIndex),
        true, false, true);
      updateStoreHandler(SELECTED_TAB_ID, alertTab?.id);
      dispatch(setSelectedTab(alertTab));
      dispatch(setVisibleItemsTableStore(defaultPage));
      dispatch(setRoute(TABLE_SCREEN_PATH.DEEPLIST));

      removeLocalStorage(ALERT_SYMBOL);
    }
  }, [getFromLocalStorage(ALERT_SYMBOL), symbolsList.length, data]);

  const getActiveAlertIndex = () => {
    if (!data?.length) return 0;
    return data?.indexOf(data?.filter((row) => row[0]?.id === activeAlert)[0]) + 1;
  };

  const handleScrollLeft = () => {
    const gridSelector = document.querySelector(
      widgetId
        ? `#tableScroll_${widgetId} .ReactVirtualized__Grid`
        : '.ReactVirtualized__Grid'
    );
    setScrollLeft(gridSelector.scrollLeft);
  };

  useEffect(() => {
    const getVisibilitySetting = JSON.parse(visibilitySetting || '[]');
    let stateActive = {};
    if (getVisibilitySetting && Object.keys(getVisibilitySetting).length > 0) {
      stateActive = getVisibilitySetting;
    } else {
      listVisibilitySetting.forEach((el) => {
        stateActive[el.title] = true;
      });
    }
    setActiveVisibility(stateActive);
    const tableWrapper = document.getElementById(isWidget ? `tableScroll_${widgetId}` : 'tableScroll');
    tableWrapper.addEventListener('wheel', wheelGridListener);
    return cleanListeners;
  }, []);

  useEffect(() => {
    const tableHeader = document.querySelector(widgetId ? `#tableHeader_${widgetId}` : '#tableHeader');

    if (tableHeader) {
      tableHeader.addEventListener('touchstart', touchGreedDelta);
      tableHeader.addEventListener('touchmove', wheelGridListener);
    }

    return () => {
      if (tableHeader) {
        tableHeader.removeEventListener('touchstart', touchGreedDelta);
        tableHeader.removeEventListener('touchmove', wheelGridListener);
      }
    };
  }, [prevTouchX]);

  useEffect(() => {
    return () => {
      headerRef.current = null;
      headerScroll.current = null;
      lastChildRef.current = null;
    };
  }, [history.location.pathname, pagePath]);

  useEffect(() => {
    if (userSettings?.activeRowStyle && Object.keys(userSettings?.activeRowStyle).length > 0) {
      dispatch(setActiveRowStyle(userSettings?.activeRowStyle));
    } else {
      dispatch(setActiveRowStyle(ROW_STYLES[0]));
    }
  }, []);

  const clickHandler = (event, index, row) => {
    sharedChartData.selectedShapeId = null;
    dispatch(setSelectedShapeId(null));
    if (
      typeof event.target.className === 'string'
      && !event.target.className.includes('symbolCheckbox')
    ) {
      if (currentSymbolHandler(watchList).name === row[0].sym) {
        dispatch(toggleSameSymbol());
      }

      if (isWidget) {
        handleSetActiveOnWidget(row[0].sortIndex);
      } else {
        if (!searchHeaderValue || globalHoldingSearch || globalSectorIndustrySearch) {
          updateLastSymbolState(row, false, watchList, hideColumnsSettings, index);
        }
        handleOpenChart();
        if (hideColumnsSettings) {
          dispatch(setSearchValue({}));
          setActiveAlert(row[0].id);
        }
      }
    }
  };

  const getActiveClass = (row) => {
    const activeClass = `${styles.screenerTableItem} ${styles.active} ${styles.hoverItem}`;
    const symbolSource = widgetSymbol ?? currentSymbolHandler(watchList).index;

    if (hideColumnsSettings) {
      return row[0].id === activeAlert ? activeClass : `${styles.screenerTableItem}`;
    }
    return row[0]?.sortIndex === symbolSource ? activeClass : `${styles.screenerTableItem}`;
  };

  const getChecked = (row) => {
    if (!hideColumnsSettings) {
      return selectedItems.indexOf(row[0]?.sortIndex) !== -1 || selectedItems[0] === 'ALL';
    }
    return selectedItems.indexOf(row[0]?.id) !== -1 || selectedItems[0] === 'ALL';
  };

  const setDelayedRange = (range, startIndex) => {
    setTopIndexWidget(startIndex);
    return setVisibleItemsTable(range);
  };

  const targetLists = useMemo(
    () => watchLists.filter((list) => list.viewType === VIEW_TYPE_TARGET), [watchLists]
  );

  const onSectionRendered = ({ rowOverscanStartIndex, rowStartIndex }) => {
    if (!hideColumnsSettings) {
      dispatch(setListForFilterData({ data: [] }));
      scrollProcessor.setRange(
        [rowOverscanStartIndex, CURRENT_PAGE_DEFAULT.SYMBOLS_AMOUNT],
        rowStartIndex,
        setDelayedRange
      );
    }
  };

  useEffect(() => {
    if (hideColumnsSettings && data.length > 0) {
      const activeAlertRow = activeAlert > 0 ? data.find((row) => row[0].id === activeAlert) : data[0];
      const isAlertTab = alertTab?.id === selectedTab?.id;

      if (activeAlert === 0) {
        setActiveAlert(data[0][0].id);
      }

      if (Object.keys(selectedTab).length && isAlertTab && activeAlertRow) {
        updateLastSymbolState(activeAlertRow, false, watchList, hideColumnsSettings, data.indexOf(activeAlertRow));
      }
    }
  }, [hideColumnsSettings, data, selectedTab]);

  useEffect(() => {
    let widgetTimeout = null;
    const currentActivePosition = isWidget ? activePosition : currentSymbolHandler(watchList).position;
    setActiveScrollPosition(currentActivePosition);
    widgetTimeout = setTimeout(() => {
      setActiveScrollPosition(undefined);
      clearTimeout(widgetTimeout);
    }, MS_INTERVAL.TWO_SECOND);
  }, [activePosition, searchHeaderValue]);

  useEffect(() => {
    if (!globalSearchVisible) {
      const currentTarget = widgetId ? `${widgetId}_${TABLE_LISTENER_ID}` : TABLE_LISTENER_ID;
      document?.getElementById(currentTarget)?.focus();
    }
  }, [globalSearchVisible]);

  const cellRenderer = ({ key, rowIndex, style }) => {
    const currentCellData = data[rowIndex];

    const topIndustryColIndex = columns.findIndex((column) => column.index === 258);
    const topSubIndustryColIndex = columns.findIndex((column) => column.index === 65);
    const topGroupColIndex = columns.findIndex((column) => column.index === 66);
    const topSectorColIndex = columns.findIndex((column) => column.index === 67);
    const statusIndustryIndex = topGroupColIndex > -1 ? topGroupColIndex : currentCellData.length - 4;
    const statusSubIndustryIndex = topSubIndustryColIndex > -1 ? topSubIndustryColIndex : currentCellData.length - 3;
    const statusGroupIndex = topIndustryColIndex > -1 ? topIndustryColIndex : currentCellData.length - 2;
    const statusSectorIndex = topGroupColIndex > -1 ? topSectorColIndex : currentCellData.length - 1;

    const earningsValue = additionalDataWidget ? additionalDataWidget[ADDITIONAL_DATA_COLUMNS.EARNINGS] : {};
    const dividendValue = additionalDataWidget ? additionalDataWidget[ADDITIONAL_DATA_COLUMNS.EX_DIVIDENT] : {};
    const earningTimeValue = additionalDataWidget ? additionalDataWidget[ADDITIONAL_DATA_COLUMNS.EARNINGS_TIME] : {};
    const isETF = additionalDataWidget ? additionalDataWidget[ADDITIONAL_DATA_COLUMNS.IS_ETF] : {};

    const getVolumeData = (rowData) => {
      return volumeBarsWidget?.[hideColumnsSettings ? rowData[1]?.sortIndex : rowData[0]?.sortIndex] || [];
    };

    const getEarningsValue = (rowData) => {
      return earningsValue[hideColumnsSettings ? rowData[1]?.sortIndex : rowData[0]?.sortIndex];
    };

    const getDividendValue = (rowData) => {
      return dividendValue[hideColumnsSettings ? rowData[1]?.sortIndex : rowData[0]?.sortIndex];
    };

    const getEarningsTimeValue = (rowData) => {
      return earningTimeValue[hideColumnsSettings ? rowData[1]?.sortIndex : rowData[0]?.sortIndex];
    };

    const getSymbolTypeValue = (rowData) => {
      return isETF[hideColumnsSettings ? rowData[1]?.sortIndex : rowData[0]?.sortIndex];
    };

    return (
      <div
        key={[widgetId, history.location.pathname, key].join('_')}
        style={{ ...style, width: 'max-content', minWidth: '100%' }}
      >
        {currentCellData && currentCellData?.length ? (
          <div
            tabIndex={-1}
            role="button"
            onKeyDown={() => {}}
            id={widgetId
              ? `${widgetId}_${currentCellData[hideColumnsSettings ? 1 : 0].sortIndex}`
              : `${currentCellData[hideColumnsSettings ? 1 : 0].sortIndex}`}
            onClick={(event) => clickHandler(event, rowIndex, currentCellData)}
            style={{ height: style.height }}
            className={`${!data[rowIndex][0]?.outOfRange
              ? `${styles.deactivateShimmer} ${getActiveClass(data[rowIndex])}`
              : `${getActiveClass(data[rowIndex])}`}
              ${data[rowIndex][hideColumnsSettings ? 1 : 0].index === hoveredSymbolIndex?.index 
            && (widgetId === hoveredSymbolIndex.dataId || (watchList ? selectedTab.id === hoveredSymbolIndex.dataId 
              : selectedScreenId === hoveredSymbolIndex.dataId)) && styles.active}`}
          >
            <>
              {hideColumnsSettings && (
                <StatusAlertComponent
                  columnIndex={STATUS_ALERT_COLUMN}
                  size={currentColumnsSize?.[columns[0]?.index]}
                  item={currentCellData[0]}
                  name={columns[0]?.name}
                  checked={getChecked(currentCellData)}
                  changeHandler={setItems}
                />
              )}
              <SymbolTableItem
                alertTable={hideColumnsSettings}
                earningsValue={getEarningsValue(currentCellData) || null}
                dividendValue={getDividendValue(currentCellData) || null}
                earningTimeValue={getEarningsTimeValue(currentCellData) || null}
                isETF={getSymbolTypeValue(currentCellData) || null}
                columnIndex={symbolColumn}
                size={currentColumnsSize?.[hideColumnsSettings ? columns[1]?.index : symbolColumn]}
                item={currentCellData[hideColumnsSettings ? 1 : 0]}
                name={columns[hideColumnsSettings ? 1 : 0]?.name}
                checked={getChecked(currentCellData)}
                changeHandler={setItems}
                hideCheckbox={watchList && alertTab?.id === selectedTab?.id}
                isSearch={isSearch || globalSectorIndustrySearch}
                activeVisibility={activeVisibility}
                watchList={watchList}
                targetLists={targetLists}
                targetListDisabled={profile?.role === SUBSCRIPTION_TYPE.FREE}
                addSymbolToTargetList={handlerTargetList}
                widgetId={widgetId}
                widgetResizeSelector={widgetResizeSelector}
                setOpenEtfWidget={setOpenEtfWidget}
                selectedItemFromWidget={selectedItemFromWidget}
                showWeight={isHoldingViewFromWidget}
                selectedRowsFromWidget={selectedItems}
                clearSelections={clearSelections}
              />
            </>
            <>
              {currentCellData.map((cell, column) => {
                const columnIndex = column === 0 ? symbolColumn : columns[column]?.index;

                if (columnIndex === 1998 && !isHoldingViewFromWidget) return null;
                if (column === 0) return null;
                if (column === 1) return null;
                if (column === 2 && !hideColumnsSettings) return null;

                return (
                  <React.Fragment
                    key={[widgetId, history.location.pathname, column, currentCellData[0].sortIndex].join('_')}
                  >
                    {(!(column >= currentCellData.length - 4) || hideColumnsSettings) && (
                      <ScreenerTableItem
                        item={cell}
                        name={columns[column]?.name}
                        type={columns[column]?.type}
                        activeVisibility={activeVisibility}
                        statusIndustry={+currentCellData[statusIndustryIndex]}
                        statusSubIndustryIndex={+currentCellData[statusSubIndustryIndex]}
                        statusSectorIndex={+currentCellData[statusSectorIndex]}
                        statusGroupIndex={+currentCellData[statusGroupIndex]}
                        volumesHistoryData={getVolumeData(currentCellData)}
                        columnIndex={columnIndex}
                        size={currentColumnsSize?.[columns[column]?.index]}
                        alertTable={hideColumnsSettings}
                        watchList={watchList}
                        isSearch={isSearch}
                        widgetId={widgetId}
                        widgetResizeSelector={widgetResizeSelector}
                        sectorIndustryWidgetHandler={sectorIndustryWidgetHandler}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </>
            <div
              ref={lastChildRef}
              className={`${styles.screenerTableCell} ${hideColumnsSettings ? styles.stickyChild : ''}`}
              style={{ width: `${LAST_ITEM_SCREENER_WIDTH}px` }}
            >
              {hideColumnsSettings && <AlertAction alertStatus={currentCellData[0]} />}
            </div>
          </div>
        ) : (
          <div style={{ ...style, width: 'max-content' }} />
        )}
      </div>
    );
  };

  return (
    <>
      <div className={`${styles.screenerLayout} ${isWidget && styles.widgetLayout}`}>
        <div
          id={isWidget ? `tableScroll_${widgetId}` : 'tableScroll'}
          className={`${styles.screenerTableLayout}`}
          style={{ overflow: isScrolled && 'hidden', marginRight: hideChart ? '0' : '16px' }}
          role="button"
          tabIndex={-1}
          onKeyDown={(event) => scrollBeforeSet(event, currentSymbolRef.current)}
        >
          <div className={`${styles.screenerTable}`} id="screenerTable">
            {(columns.length > 0 && data.length > 0) ? (
              <AutoSizer>
                {({ height, width }) => (
                  <>
                    <div
                      id={isWidget ? `tableHeader_${widgetId}` : 'tableHeader'}
                      className={styles.headerWrapper}
                      style={{
                        display: 'block',
                        overflow: 'scroll',
                        background: 'var(--theadBgColor)',
                        width: hideChart ? width : width - 15
                      }}
                      ref={headerScroll}
                    >
                      <ScreenerTableHeader
                        data={data}
                        columns={columns}
                        headerRef={headerRef}
                        gridWidth={gridWidth}
                        setGridWidth={setGridWidth}
                        setLoader={setLoader}
                        sortStatus={sortStatus}
                        sortHandler={sortHandler}
                        setAllItems={setAllItems}
                        checked={selectedItems?.length > 0}
                        toggleIconHandler={toggleIconHandler}
                        columnsSize={currentColumnsSize}
                        initColumnsSize={initColumnsSize}
                        updateColumnsSize={updateColumnsSize}
                        loaderStatus={loaderStatus}
                        firstItem={columns[symItemIndex]}
                        zIndexFirst={symItemIndex}
                        alertTable={hideColumnsSettings}
                        setScrollLeft={handleScrollLeft}
                        headerScroll={headerScroll}
                        showWeight={isHoldingViewFromWidget}
                        widgetResizeSelector={widgetResizeSelector}
                        isWidget={isWidget}
                      />
                    </div>
                    <Grid
                      cellRenderer={cellRenderer}
                      id={widgetId ? `${widgetId}_${TABLE_LISTENER_ID}` : TABLE_LISTENER_ID}
                      onScroll={scrollGridListener}
                      className={styles.listWrapper}
                      scrollToRow={scrollToBottom ? data?.length - 1 : activeScrollPosition}
                      columnCount={COLUMN_COUNT}
                      columnWidth={gridWidth}
                      height={height - (isWidget ? SCREENER_PADDING_WIDGET : SCREENER_PADDINGS)}
                      rowCount={data?.length}
                      width={hideChart ? width : width - 15}
                      initialScrollLeft={scrollLeft}
                      overscanRowCount={OVERSCAN_ROW_COUNT}
                      rowHeight={activeRowStyle.size}
                      onScrollbarPresenceChange={(params) => {
                        setHorizontalScroll(params?.horizontal);
                      }}
                      scrollLeft={scrollLeft !== 0 ? scrollLeft : null}
                      onSectionRendered={onSectionRendered}
                      scrollTop={scrollTop === 0 ? scrollTop : null}
                    />
                  </>
                )}
              </AutoSizer>
            ) : (
              <EmptyTableItem
                alertTable={hideColumnsSettings}
                showLoader={loaderStatus}
                isWidget={isWidget}
                watchList={watchList}
                selectedItemFromWidget={selectedItemFromWidget}
              />
            )}

            <div className={styles.screenerTable}>
              <SettingsComponent
                isEmpty={!!data?.length}
                clearSorting={clearSorting}
                openColumnsHandler={toggleColumnsHandler}
                tooltipLabel={t('columnSetting')}
                hideChart={hideChart}
                handleOpenChart={handleOpenChart}
                setHideEvents={setHideEvents}
                hideColumnsSettings={hideColumnsSettings}
                visibleColumnsScreen={visibleColumnsScreen}
                isWidget={isWidget}
                selectedItemFromWidget={selectedItemFromWidget}
                selectedColumnIdFromWidget={selectedColumnIdFromWidget}
                isWatchlist={watchList}
                sortData={sortData}
                widgetId={widgetId}
              />
              {!hideChart && (
                <div
                  ref={resizeRef}
                  aria-hidden
                  onMouseDown={onStartResize}
                  onTouchStart={onStartResize}
                  className={styles.resizeBlock}
                >
                  <IconResize />
                </div>
              )}
            </div>
            <UpgradeAccountBanner
              wrapperClass={styles.upgradePlan}
              buttonClass={styles.upgradePlan_button}
              tooltipClass={styles.comingSoonScreener}
              buttonTitle={t('upgradeYourAccountToSeeMore')}
              profile={profile}
            />
            {!isWidget && data?.length ? (
              <>
                <SymbolCounter
                  selectedItems={hideColumnsSettings ? getActiveAlertIndex() : activePosition + 1}
                  counterSymbols={hideColumnsSettings ? data?.length : counterSymbols}
                  checkedItem={selectedItems.length}
                  isHorizontalScroll={isHorizontalScroll}
                />
              </>
            ) : <span />}
          </div>
        </div>
      </div>
      {viewVisibilitySettingModal && (
        <ModalLayout typeModal="modalMobile">
          <VisibilitySetting
            text={t('visibilitySetting')}
            closeModal={toggleIconHandler}
            active={activeVisibility}
            setActiveHandler={setActiveHandler}
          />
        </ModalLayout>
      )}
    </>
  );
};

ScreenerTable.propTypes = {
  sectorIndustryWidgetHandler: PropTypes.func,
  widgetSymbol: PropTypes.number,
  scrollTop: PropTypes.number.isRequired,
  activePosition: PropTypes.number,
  watchList: PropTypes.bool,
  profile: PropTypes.shape({
    role: PropTypes.string
  }),
  setIsVisibleFilters: PropTypes.func.isRequired,
  setLoader: PropTypes.func,
  setItems: PropTypes.func.isRequired,
  sortStatus: PropTypes.func.isRequired,
  isScrolled: PropTypes.bool,
  sortHandler: PropTypes.func.isRequired,
  setAllItems: PropTypes.func.isRequired,
  keyDownHandler: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
  )).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, type: PropTypes.string, index: PropTypes.number })
  ).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.number || PropTypes.string).isRequired,
  loaderStatus: PropTypes.bool,
  clearSorting: PropTypes.func,
  handleChangeLayoutSize: PropTypes.func,
  hideChart: PropTypes.bool,
  handleOpenChart: PropTypes.func,
  setHideEvents: PropTypes.func,
  hideColumnsSettings: PropTypes.bool,
  activeAlert: PropTypes.number,
  setActiveAlert: PropTypes.func,
  isSearch: PropTypes.bool,
  isWidget: PropTypes.bool,
  additionalDataWidget: PropTypes.shape({}),
  volumeBarsWidget: PropTypes.shape({}),
  setVisibleItemsTable: PropTypes.func,
  selectedItemFromWidget: PropTypes.number,
  selectedColumnIdFromWidget: PropTypes.number,
  handleSetActiveOnWidget: PropTypes.func,
  sortData: PropTypes.arrayOf(PropTypes.shape({})),
  widgetId: PropTypes.string,
  setOpenEtfWidget: PropTypes.func,
  isHoldingViewFromWidget: PropTypes.bool,
  clearSelections: PropTypes.func,
  setTopIndexWidget: PropTypes.func,
};

ScreenerTable.defaultProps = {
  sectorIndustryWidgetHandler: () => {},
  watchList: false,
  profile: {},
  activePosition: 0,
  isScrolled: false,
  setLoader: () => undefined,
  loaderStatus: false,
  clearSorting: () => undefined,
  handleChangeLayoutSize: () => undefined,
  hideChart: false,
  handleOpenChart: () => undefined,
  setHideEvents: () => undefined,
  hideColumnsSettings: false,
  activeAlert: 0,
  setActiveAlert: () => undefined,
  isSearch: false,
  isWidget: false,
  additionalDataWidget: null,
  volumeBarsWidget: null,
  setVisibleItemsTable: () => undefined,
  selectedItemFromWidget: null,
  selectedColumnIdFromWidget: null,
  handleSetActiveOnWidget: () => undefined,
  sortData: [],
  widgetId: '',
  setOpenEtfWidget: () => undefined,
  isHoldingViewFromWidget: false,
  widgetSymbol: undefined,
  clearSelections: () => undefined,
  setTopIndexWidget: () => undefined,
};

export default React.memo(ScreenerTable, areEqual);
