import { CustomIndicator, IPineStudyResult, LibraryPineStudy, PineJS, RawStudyMetaInfoId, StudyInputType, StudyPlotType, StudyShapesPlotPreferences, StudyStylesInfo } from '../../charting_library/charting_library';
import getInputArray from '../utilities/getInputArrayTs';
import { computePowerOfThree } from './functions/computePowerOfThree';
import { calculateRMV, checkThreeWeeksTight, computeTLTechnicals } from './functions/computeTLTechnicals';
import isLaunchPad from './functions/isLaunchPad';
import updateAntsState from './functions/updateAntsState';
import updatePowerTrendState from './functions/updatePowerTrendState';

export default function technicalPatternsV2(mPineJS: PineJS): CustomIndicator {
  return {
    name: 'DV - Technical Patterns',
    metainfo: {
      id: 'TL-technicalPatternsv2@tv-basicstudies-1' as RawStudyMetaInfoId,
      shortDescription: 'DV - Technical Patterns',
      description: 'DV - Technical Patterns',
      isCustomIndicator: true,
      is_price_study: true,
      linkedToSeries: true,
      format: {
        type: 'percent',
        precision: 1
      },
      inputs: [
        {
          id: 'openEqualHighEnabled',
          name: 'Open Equal High',
          type: 'bool' as StudyInputType.Bool,
          defval: false
        },
        {
          id: 'openEqualLowEnabled',
          name: 'Open Equal Low',
          type: 'bool' as StudyInputType.Bool,
          defval: false
        },
        {
          id: 'insideDayEnabled',
          name: 'Inside Day',
          type: 'bool' as StudyInputType.Bool,
          defval: false
        },
        {
          id: 'outsideBullishEnabled',
          name: 'Outside Bullish',
          type: 'bool' as StudyInputType.Bool,
          defval: false
        },
        {
          id: 'outsideBearishEnabled',
          name: 'Outside Bearish',
          type: 'bool' as StudyInputType.Bool,
          defval: false
        },
        {
          id: 'upsideReversalEnabled',
          name: 'Upside Reversal',
          type: 'bool' as StudyInputType.Bool,
          defval: false
        },
        {
          id: 'oopsReversalEnabled',
          name: 'Oops Reversal',
          type: 'bool' as StudyInputType.Bool,
          defval: false
        },
        {
          id: 'slingshotEnabled',
          name: 'Slingshot',
          type: 'bool' as StudyInputType.Bool,
          defval: false
        },
        {
          id: 'threeWeeksTightEnabled',
          name: 'Three Weeks Tight',
          type: 'bool' as StudyInputType.Bool,
          defval: true
        },
        {
          id: 'antsEnabled',
          name: 'Ants',
          type: 'bool' as StudyInputType.Bool,
          defval: true
        },
        {
          id: 'powerTrendEnabled',
          name: 'Power Trend',
          type: 'bool' as StudyInputType.Bool,
          defval: true
        },
        {
          id: 'powerOfThreeEnabled',
          name: 'Power Of Three',
          type: 'bool' as StudyInputType.Bool,
          defval: true
        },
        {
          id: 'launchpad',
          name: 'Launch Pad',
          type: 'bool' as StudyInputType.Bool,
          defval: true,
        },
        {
          id: 'rmvEnabled',
          name: 'Relative Measured Volatility (RMV)',
          type: 'bool' as StudyInputType.Bool,
          defval: false
        },
        {
          id: 'rmv_loopback_period',
          name: 'RMV Loopback Period',
          type: 'integer' as StudyInputType.Integer,
          defval: 15
        },
        {
          id: 'rmv_compare',
          name: 'RMV < (input)',
          type: 'integer' as StudyInputType.Integer,
          defval: 5
        },
      ],
      plots: [
        {
          id: 'open_equal_high',
          type: 'shapes' as StudyPlotType.Shapes
        },
        {
          id: 'open_equal_low',
          type: 'shapes' as StudyPlotType.Shapes
        },
        {
          id: 'inside_day',
          type: 'shapes' as StudyPlotType.Shapes
        },
        {
          id: 'outside_bullish',
          type: 'shapes' as StudyPlotType.Shapes
        },
        {
          id: 'outside_bearish',
          type: 'shapes' as StudyPlotType.Shapes
        },
        {
          id: 'upside_reversal',
          type: 'shapes' as StudyPlotType.Shapes
        },
        {
          id: 'oops_reversal',
          type: 'shapes' as StudyPlotType.Shapes
        },
        {
          id: 'sling_shot',
          type: 'shapes' as StudyPlotType.Shapes
        },
        {
          id: 'plot_bar_color',
          palette: 'palette_bar',
          type: 'bar_colorer' as StudyPlotType.BarColorer
        },
        {
          id: 'green_ants',
          type: 'shapes' as StudyPlotType.Shapes
        },
        {
          id: 'power_trend',
          type: 'shapes' as StudyPlotType.Shapes
        },
        {
          id: 'power_of_three',
          type: 'shapes' as StudyPlotType.Shapes
        },
        {
          id: 'launchpad',
          type: 'shapes' as StudyPlotType.Shapes
        },
        {
          id: 'rmv',
          type: 'shapes' as StudyPlotType.Shapes
        },
      ],
      palettes: {
        palette_bar: {
          colors: [{ name: 'Color' }],
          valToIndex: {
            0: 0
          }
        },
      },
      styles: {
        plot_bar_color: {
          title: "Three Weeks Tight (Weekly)"
        },
        power_trend: {
          title: 'Power Trend',
          size: 'tiny' as StudyStylesInfo['size']
        },
        green_ants: {
          title: 'Ants',
          size: 'tiny' as StudyStylesInfo['size']
        },
        oops_reversal: {
          title: 'Oops Reversal',
          size: 'tiny' as StudyStylesInfo['size']
        },
        upside_reversal: {
          title: 'Upside Reversal',
          size: 'tiny' as StudyStylesInfo['size']
        },
        outside_bullish: {
          title: 'Outside Bullish',
          size: 'tiny' as StudyStylesInfo['size']
        },
        outside_bearish: {
          title: 'Outside Bearish',
          size: 'tiny' as StudyStylesInfo['size']
        },
        power_of_three: {
          title: 'Power Of Three',
          size: 'tiny' as StudyStylesInfo['size']
        },
        sling_shot: {
          title: 'Slingshot',
          size: 'tiny' as StudyStylesInfo['size']
        },
        open_equal_high: {
          title: 'Open Equal High',
          size: 'tiny' as StudyStylesInfo['size']
        },
        open_equal_low: {
          title: 'Open Equal Low',
          size: 'tiny' as StudyStylesInfo['size']
        },
        inside_day: {
          title: 'Inside Day',
          size: 'tiny' as StudyStylesInfo['size']
        },
        rmv: {
          title: 'Relative Measure Volatility',
          size: 'tiny' as StudyStylesInfo['size']
        },
        launchpad: {
          title: 'Launch Pad',
          size: 'tiny' as StudyStylesInfo['size']
        },
      },
      defaults: {
        inputs: {
          powerTrendEnabled: true,
          antsEnabled: true,
          oopsReversalEnabled: false,
          upsideReversalEnabled: false,
          outsideBullishEnabled: false,
          outsideBearishEnabled: false,
          powerOfThreeEnabled: true,
          slingshotEnabled: false,
          openEqualHighEnabled: false,
          openEqualLowEnabled: false,
          threeWeeksTightEnabled: true,
          insideDayEnabled: false,
          rmvEnabled: false,
          rmv_loopback_period: 15,
          rmv_compare: 5,
          launchpad: false,
        },
        palettes: {
          palette_bar: {
            colors: [{ color: '#FFA500' }]
          }
        },
        styles: {
          plot_bar_color: {
            color: '#FFA500',
          },
          power_trend: {
            color: '#0c9146',
            location: 'AboveBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_xcross',
          },
          green_ants: {
            color: '#0c9146',
            location: 'BelowBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_xcross',
          },
          oops_reversal: {
            color: '#0c9146',
            location: 'BelowBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_arrow_up',
          },
          upside_reversal: {
            color: '#0c9146',
            location: 'AboveBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_triangle_up',
          },
          outside_bullish: {
            color: '#0c9146',
            location: 'AboveBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_triangle_up',
          },
          outside_bearish: {
            color: '#f55bae',
            location: 'AboveBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_triangle_down',
          },
          power_of_three: {
            color: '#0c9146',
            location: 'AboveBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_circle',
          },
          sling_shot: {
            color: '#0c9146',
            location: 'BelowBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_cross',
          },
          open_equal_high: {
            color: '#0c9146',
            location: 'BelowBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_square',
          },
          open_equal_low: {
            color: '#0c9146',
            location: 'BelowBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_square',
          },
          inside_day: {
            color: '#0c9146',
            location: 'BelowBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_triangle_up',
          },
          rmv: {
            color: '#0c9146',
            location: 'BelowBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_circle',
          },
          launchpad: {
            color: '#0c9146',
            location: 'BelowBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_xcross',
          },
        }
      },
    },
    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = (context) => {
        this._context = context;
        this.powerTrendState = {
          powerTrendOn: false,
          daysLowAboveEMA21: 0,
          daysEMA21AboveSMA50: 0,
          recentHigh: 0,
          prevSMA50: 0,
          prevTime: 0,
          isDaysLowAboveEMA21Added: false,
          isdaysEMA21AboveSMA50Added: false,
        };
      };
      this.main = (context, inputs): IPineStudyResult => {
        this._context = context;
        const [
          openEqualHighEnabled,
          openEqualLowEnabled,
          insideDayEnabled,
          outsideBullishEnabled,
          outsideBearishEnabled,
          upsideReversalEnabled,
          oopsReversalEnabled,
          slingshotEnabled,
          threeWeeksTight,
          antsEnabled,
          powerTrendEnabled,
          powerOfThreeEnabled,
          launchpad,
          rmvEnabled,
          rmv_loopback_period,
          rmv_compare,
        ] = getInputArray({
          inputs,
          length: 17
        });

        const open = mPineJS.Std.open(this._context);
        const high = mPineJS.Std.high(this._context);
        const low = mPineJS.Std.low(this._context);
        const close = mPineJS.Std.close(this._context);
        const showThreeWeeksTight = mPineJS.Std.isweekly(this._context) && threeWeeksTight;
        const closeSeries = this._context.new_unlimited_var(close);
        const highSeries = this._context.new_var(high);
        let barColorValue = NaN;
        let rmvValue = null;
        let launchpadValue = null;
        let isAnts = NaN;

        if (powerTrendEnabled) {
          const ema21 = mPineJS.Std.ema(closeSeries, 21, this._context);
          const sma50 = mPineJS.Std.sma(closeSeries, 50, this._context);
          this.powerTrendState = updatePowerTrendState(
            this.powerTrendState,
            ema21,
            sma50,
            highSeries,
            closeSeries,
            mPineJS.Std,
            this._context,
          );
        }
        if (antsEnabled) {
          const volumeSeries = context.new_var(mPineJS.Std.volume(this._context));
          isAnts = updateAntsState(closeSeries, volumeSeries);
        }
        const TLTechnicals = computeTLTechnicals(open, high, low, close, mPineJS.Std, this._context);
        let powerOfThree = false;
        if (powerOfThreeEnabled) {
          powerOfThree = computePowerOfThree(open, close, closeSeries, mPineJS.Std, this._context);
        }
        if (showThreeWeeksTight) {
          const isThreeWeeksTight = checkThreeWeeksTight(close, this._context);
          barColorValue = isThreeWeeksTight ? 0 : barColorValue;
        }

        if (rmvEnabled) {
          rmvValue = calculateRMV(rmv_loopback_period, mPineJS.Std, this._context);
        }
        if (launchpad) {
          launchpadValue = isLaunchPad(close, mPineJS.Std, this._context)
        }

        return [
          openEqualHighEnabled && (open === high) ? 1 : NaN,
          openEqualLowEnabled && (open === low) ? 1 : NaN,
          insideDayEnabled && TLTechnicals.insideDay ? 1 : NaN,
          outsideBullishEnabled && TLTechnicals.outsideBullishDay ? 1 : NaN,
          outsideBearishEnabled && TLTechnicals.outsideBearishDay ? 1 : NaN,
          upsideReversalEnabled && TLTechnicals.upsideReversal ? 1 : NaN,
          oopsReversalEnabled && TLTechnicals.oopsReversal ? 1 : NaN,
          slingshotEnabled && TLTechnicals.slingShot ? 1 : NaN,
          barColorValue,
          antsEnabled && isAnts ? 1 : NaN,
          powerTrendEnabled && this.powerTrendState.powerTrendOn ? 1 : NaN,
          powerOfThreeEnabled && powerOfThree ? 1 : NaN,
          launchpad && launchpadValue ? 1 : NaN,
          rmvEnabled && rmvValue != null && (rmvValue < rmv_compare) ? 1 : NaN,
        ];
      };
    }
  };
}
