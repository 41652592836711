import {
  useEffect,
  useState,
  useRef,
  useContext
} from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
  defaultPage, FIXED_COLUMNS, TABLE_SCREEN_PATH
} from '../../../constants/screener';
import {
  setDataPanelsQueryId,
  setIndustryDataColumns,
  setIndustryDataRows,
  setIndustryQueryScreenerId, updateIndustryDataRows
} from '../../../store/tableData/slice';
import {
  ADDRESS, WS_CHANNELS, WS_PING_TYPES
} from '../../../constants/socketConnection';
import usePrepareLastSymbolState from '../../../utils/hooks/usePrepareLastSymbolState';
import { repeatItems, useRepeat } from '../../../utils/hooks/useRepeat';
import SocketContext from '../../../context/SocketContext';
import { messageIdGenerator } from '../../ChartContainer/utils/chartingHelper';
import RootStateTypes from '../../../store/RootStateTypes';
import {
  Filter,
  ScreenerQueryMessage,
  SocketDataArrayTypes,
  SocketPingResponse,
  SocketResponse,
  SocketScreenerAllResponse
} from '../../../services/Interfaces';
import {
  groupIndex,
  industryColumns,
  industryDataColumnsNames,
  industryDataRowsNames, industryDataScreenerIds,
  IndustryGroupNames, industryIndex, sectorIndex, subIndustryIndex
} from '../../../constants/dataPanel';

let tempQueryId: number | null = null;

const dataPanelMessageId = messageIdGenerator();
const industryMessageId = messageIdGenerator();
const sectorMessageId = messageIdGenerator();
const groupMessageId = messageIdGenerator();
const subIndustryMessageId = messageIdGenerator();

type TDataPointsData = {
  [key: string]: number | string | null;
};

interface IDataPanelConnection {
  (
    isWatchList: boolean
  ): {
    dataPoints: TDataPointsData;
    screenerId: number | null;
    symbol: number | null;
  };
}

const useDataPanelConnection: IDataPanelConnection = (isWatchList) => {
  const {
    Socket,
    Authorized,
    Connection,
    awakeSocketWidget
  } = useContext(SocketContext);
  const gueryDataPanelRef = useRef<number | null>(null);
  const gueryDataPanelIndustryRef = useRef<number | null>(null);
  const gueryDataPanelSectorRef = useRef<number | null>(null);
  const gueryDataPanelGroupRef = useRef<number | null>(null);
  const gueryDataPanelSubIndustryRef = useRef<number | null>(null);
  const currentColumnsRef = useRef<number[] | null>(null);

  const { currentSymbolHandler } = usePrepareLastSymbolState();
  const dispatch = useDispatch();
  const [columnsListIds, setColumnsListIds] = useState<number[]>([]);
  const [symbol, setSymbol] = useState<number | null>(null);
  const [dataPointsData, setDataPointsData] = useState<TDataPointsData>({});

  const storedDataPanels = useSelector((state: RootStateTypes) => state.newScreenState?.dataPanels, shallowEqual);
  const selectedDataPanel = useSelector(
    (state: RootStateTypes) => state.newScreenState?.selectedDataPanel, shallowEqual
  );
  const dataPanelsQueryId = useSelector(
    (state: RootStateTypes) => state.tableDataState.dataPanelsQueryId, shallowEqual
  );
  const industryScreenerId = useSelector(
    (state: RootStateTypes) => state.tableDataState.industryScreenerId, shallowEqual
  );
  const sectorScreenerId = useSelector(
    (state: RootStateTypes) => state.tableDataState.sectorScreenerId, shallowEqual
  );
  const groupScreenerId = useSelector(
    (state: RootStateTypes) => state.tableDataState.groupScreenerId, shallowEqual
  );
  const subIndustryScreenerId = useSelector(
    (state: RootStateTypes) => state.tableDataState.subIndustryScreenerId, shallowEqual
  );
  const lastScreenerSymbol = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.lastScreenerSymbol, shallowEqual
  );
  const lastDeepListSymbol = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.lastDeepListSymbol, shallowEqual
  );
  const lastAlertsSymbol = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.lastAlertsSymbol, shallowEqual
  );
  const searchValue = useSelector((state: RootStateTypes) => state.screenerState.searchValue, shallowEqual);
  const disconnectPopup = useSelector((state: RootStateTypes) => state.authState.disconnectPopup, shallowEqual);
  const columnsList = useSelector(
    (state: RootStateTypes) => state.screenerState.columnsList, shallowEqual
  );
  const selectedTab = useSelector((state: RootStateTypes) => state.watchlistState.selectedTab, shallowEqual);
  const alertTab = useSelector((state: RootStateTypes) => state.watchlistState.alertsTab, shallowEqual);
  const pagePath = useSelector((state: RootStateTypes) => state.screenerState.pagePath, shallowEqual);
  const initialFilterList = useSelector((state: RootStateTypes) => state.screenerState.initialFilterList, shallowEqual);
  const userSettings = useSelector((state: RootStateTypes) => state.accountState.userSettings, shallowEqual);
  const industryOfSymbols = useSelector((state:RootStateTypes) => state.tableDataState.industryOfSymbols, shallowEqual);

  const isIndustryMessageId = (messageId: number | undefined) => messageId === industryMessageId
    || messageId === sectorMessageId || messageId === groupMessageId || messageId === subIndustryMessageId;

  const getIndustryGroupName = (messageId: number | undefined,
    constant: { '0': string, '1': string, '2': string, '3': string }) => {
    if (messageId === industryMessageId) return constant[IndustryGroupNames.INDUSTRY];
    if (messageId === sectorMessageId) return constant[IndustryGroupNames.SECTOR];
    if (messageId === groupMessageId) return constant[IndustryGroupNames.GROUP];
    if (messageId === subIndustryMessageId) return constant[IndustryGroupNames.SUBINDUSTRY];
    return null;
  };

  const getIndustryGroupNameByQuery = (screenerId: number | undefined,
    constant: { '0': string, '1': string, '2': string, '3': string }) => {
    if (screenerId === gueryDataPanelIndustryRef.current) return constant[IndustryGroupNames.INDUSTRY];
    if (screenerId === gueryDataPanelSectorRef.current) return constant[IndustryGroupNames.SECTOR];
    if (screenerId === gueryDataPanelGroupRef.current) return constant[IndustryGroupNames.GROUP];
    if (screenerId === gueryDataPanelSubIndustryRef.current) return constant[IndustryGroupNames.SUBINDUSTRY];
    return null;
  };

  const getIndustryFilter = (industryIndexValue: number, stringValue: string, value: string) => {
    const isAlertsTab = selectedTab?.id
      ? selectedTab?.id === alertTab?.id && pagePath !== TABLE_SCREEN_PATH.SCREENER : false;

    let industryFilter;
    const industryList = initialFilterList.find(
      (filter: { name: string }) => filter?.name === 'Sector & Industry'
    )?.items?.find((item: { name: string }) => item?.name === stringValue)?.fVal;

    if (Object.keys(searchValue).length && industryOfSymbols?.length) {
      industryFilter = industryList?.find(
        (industry: { title: string }) => industry?.title === industryOfSymbols[0][value]
      )?.value || [];
    } else {
      if (pagePath !== TABLE_SCREEN_PATH.SCREENER && isAlertsTab) {
        industryFilter = industryList?.find(
          (industry: { title: string }) => industry?.title === lastAlertsSymbol[value]
        )?.value || [];
      }
      if (pagePath === TABLE_SCREEN_PATH.SCREENER) {
        industryFilter = industryList?.find(
          (industry: { title: string }) => industry?.title === lastScreenerSymbol[value]
        )?.value || [];
      }
      if (pagePath !== TABLE_SCREEN_PATH.SCREENER && !isAlertsTab) {
        industryFilter = industryList?.find(
          (industry: { title: string }) => industry?.title === lastDeepListSymbol[value]
        )?.value || [];
      }
    }
    return industryFilter;
  };

  const setRefs = (messageId: number | undefined, screenerId: number) => {
    if (messageId === industryMessageId) gueryDataPanelIndustryRef.current = screenerId;
    if (messageId === sectorMessageId) gueryDataPanelSectorRef.current = screenerId;
    if (messageId === groupMessageId) gueryDataPanelGroupRef.current = screenerId;
    if (messageId === subIndustryMessageId) gueryDataPanelSubIndustryRef.current = screenerId;
  };

  const updateColumnsData = (dataResp: SocketScreenerAllResponse) => {
    const screenerId = gueryDataPanelRef?.current || tempQueryId;
    if (dataResp?.screenerId === screenerId) {
      const columns = currentColumnsRef.current ? currentColumnsRef.current : [0].concat(columnsListIds);
      const preparedData = {} as TDataPointsData;
      const data = dataResp.data as unknown as SocketDataArrayTypes[][];
      if (!data?.length) return;
      const dataFields = data[0];
      columns?.forEach((column, index) => {
        preparedData[column] = dataFields[index];
      });
      setDataPointsData(preparedData);
      tempQueryId = null;
    }
  };

  const getColumnsIdsList = (): void => {
    const { leftColumn, rightColumn } = selectedDataPanel ? selectedDataPanel?.data : storedDataPanels[0]?.data;

    const columnsIds: number[] = [];
    if (Array.isArray(leftColumn) && Array.isArray(rightColumn)) {
      leftColumn?.concat(rightColumn)?.forEach((panel) => {
        panel?.columns?.forEach((column: number) => {
          columnsIds.push(column);
        });
      });
    }

    setColumnsListIds(columnsIds);
  };

  const dataPartialResponse = (resp: SocketResponse) => {
    if (resp.errors?.length && awakeSocketWidget) {
      awakeSocketWidget();
      return;
    }
    const readingCondition = (resp.screenerId === gueryDataPanelIndustryRef.current)
      || (resp.screenerId === gueryDataPanelSectorRef.current)
      || (resp.screenerId === gueryDataPanelGroupRef.current)
      || (resp.screenerId === gueryDataPanelSubIndustryRef.current);

    if (readingCondition) {
      dispatch(updateIndustryDataRows(
        { data: resp.data, name: getIndustryGroupNameByQuery(resp?.screenerId, industryDataRowsNames) }
      ));

      return;
    }

    if (resp?.screenerId === gueryDataPanelRef.current) {
      const dataResp = resp.data;

      setDataPointsData((prevTableData) => {
        const updatedObject = { ...prevTableData };
        for (let i = 0; i < dataResp.length; i += 2) {
          const key = `${dataResp[i + 1]}`;
          const value = dataResp[i + 2];
          if (key in updatedObject) {
            updatedObject[key] = value;
          }
        }
        return updatedObject;
      });
    }
  };

  const dataCallback = (resp: SocketScreenerAllResponse) => {
    if (resp.errors?.length && awakeSocketWidget) {
      awakeSocketWidget();
      return;
    }
    if (resp?.messageId === dataPanelMessageId) {
      tempQueryId = resp.screenerId;
      dispatch(setDataPanelsQueryId(resp.screenerId));
      updateColumnsData(resp);
    } else if (isIndustryMessageId(resp.messageId) && resp.screenerId) {
      setRefs(resp.messageId, resp.screenerId);
      dispatch(setIndustryQueryScreenerId({
        data: resp?.screenerId,
        name: getIndustryGroupName(resp.messageId, industryDataScreenerIds)
      }));
      const { data, columns } = resp;
      const filteredColumns = columns.filter((col) => [0, 17, ...industryColumns].includes(col));

      const filteredData = (data as (number | string)[][]).map((row) => filteredColumns.map((col) => {
        const index = columns.indexOf(col);
        return row[index];
      }));

      const tableColumnsData = filteredColumns.map((item: number) => columnsList?.find(
        (column: { index: number }) => column.index === item
      ));

      dispatch(setIndustryDataRows(
        { data: filteredData, name: getIndustryGroupName(resp?.messageId, industryDataRowsNames) }
      ));
      dispatch(setIndustryDataColumns(
        { data: tableColumnsData, name: getIndustryGroupName(resp?.messageId, industryDataColumnsNames) }
      ));
    }

    if (resp?.errors && resp?.errors?.length > 0) {
      // eslint-disable-next-line no-console
      console.log(resp?.errors[0], 'socket outages data_panel');
      useRepeat(ADDRESS.DATA_PANEL);
    }
  };

  const getColumnsData = (initial?: boolean): void => {
    if (Socket?.readyState === WebSocket.OPEN && Authorized) {
      const columnsIds = [0].concat(columnsListIds);
      currentColumnsRef.current = columnsIds as number[];
      const queryMessage: ScreenerQueryMessage = {
        messageId: dataPanelMessageId,
        columns: [0].concat(columnsListIds),
        filters: [[[0, 0, [symbol as number]]]],
        sortBy: [],
        range: defaultPage,
        refreshTime: 0,
        symbolIndex: currentSymbolHandler(false).index
      };

      if (dataPanelsQueryId && !initial) {
        queryMessage.screenerId = dataPanelsQueryId;
        const emitter = () => Connection?.sendMessage(
          WS_CHANNELS.SCREENER_PATCH,
          queryMessage
        );
        emitter();

        repeatItems[WS_CHANNELS.DATA_PANEL] = emitter;
      } else {
        Connection?.sendMessage(WS_CHANNELS.SCREENER_SUBSCRIBE, queryMessage);
      }
    }
  };

  const getIndustryData = (screenerId: number | null, industryIndexValue: number, stringValue: string,
    value: string, messageId: number, initial = false) => {
    const isSearchConditionMet = Object.keys(searchValue).length && industryOfSymbols.length
      ? industryOfSymbols.length === 1
      : true;

    if (Socket?.readyState === WebSocket.OPEN && Authorized && isSearchConditionMet) {
      const columnsIds = [0].concat(columnsListIds);
      const industryFilter = getIndustryFilter(industryIndexValue, stringValue, value);
      currentColumnsRef.current = columnsIds;
      const mainFilter: Filter = [[industryIndexValue, 0, [industryFilter]]];
      const additionalVolumeFilter: Filter = [[225, 1, [10000000, null]]];
      const additionalPriceFilter: Filter = [[3, 1, [10, null]]];

      const queryMessage: ScreenerQueryMessage = {
        messageId,
        columns: [...FIXED_COLUMNS, ...industryColumns],
        etfSymbolIndex: -1,
        filters: industryFilter !== 223
          ? [additionalVolumeFilter, additionalPriceFilter, mainFilter] : [[[0, 0, [10000000, null]]]],
        sortBy: [[1741, 1]],
        range: [0, 10],
        refreshTime: 0,
        symbolIndex: 0
      };

      if (screenerId && !initial) {
        queryMessage.screenerId = screenerId;
        const emitter = () => Connection?.sendMessage(
          WS_CHANNELS.SCREENER_PATCH,
          queryMessage
        );
        emitter();

        repeatItems[WS_CHANNELS.DATA_PANEL] = emitter;
      } else {
        Connection?.sendMessage(WS_CHANNELS.SCREENER_SUBSCRIBE, queryMessage);
      }
    }
  };

  const cleanChanels = (): void => {
    if (dataPanelsQueryId) {
      Connection?.sendMessage(WS_CHANNELS.SCREENER_UNSUBSCRIBE,
        { messageId: dataPanelMessageId, screenerId: gueryDataPanelRef?.current as number });
      gueryDataPanelRef.current = null;
      dispatch(setDataPanelsQueryId(null));
    }
    if (industryScreenerId) {
      Connection?.sendMessage(WS_CHANNELS.SCREENER_UNSUBSCRIBE,
        { messageId: industryMessageId, screenerId: gueryDataPanelIndustryRef?.current as number });
      dispatch(
        setIndustryQueryScreenerId({ data: null, name: industryDataScreenerIds[IndustryGroupNames.INDUSTRY] })
      );
    }
    if (groupScreenerId) {
      Connection?.sendMessage(WS_CHANNELS.SCREENER_UNSUBSCRIBE,
        { messageId: groupMessageId, screenerId: gueryDataPanelGroupRef?.current as number });
      dispatch(setIndustryQueryScreenerId({ data: null, name: industryDataScreenerIds[IndustryGroupNames.GROUP] }));
    }
    if (sectorScreenerId) {
      Connection?.sendMessage(WS_CHANNELS.SCREENER_UNSUBSCRIBE,
        { messageId: sectorMessageId, screenerId: gueryDataPanelSectorRef?.current as number });
      dispatch(setIndustryQueryScreenerId({ data: null, name: industryDataScreenerIds[IndustryGroupNames.SECTOR] }));
    }
    if (subIndustryScreenerId) {
      Connection?.sendMessage(WS_CHANNELS.SCREENER_UNSUBSCRIBE,
        { messageId: subIndustryMessageId, screenerId: gueryDataPanelSubIndustryRef?.current as number });
      dispatch(setIndustryQueryScreenerId(
        { data: null, name: industryDataScreenerIds[IndustryGroupNames.SUBINDUSTRY] }
      ));
    }
  };

  useEffect(() => {
    if (storedDataPanels.length) {
      getColumnsIdsList();
    }
  }, [storedDataPanels, userSettings.selectedDataPanelId]);

  useEffect(() => {
    if (Socket?.readyState === WebSocket.OPEN
        && Authorized
        && !dataPanelsQueryId
        && userSettings.openDataPanel
    ) {
      getColumnsData(true);
    }
  }, [
    userSettings.openDataPanel,
    userSettings.selectedDataPanelId,
    dataPanelsQueryId,
    Socket?.readyState,
    Authorized,
  ]);

  useEffect(() => {
    if (Socket?.readyState === WebSocket.OPEN
      && Authorized
      && userSettings.openDataPanel
    ) {
      if (!industryScreenerId && userSettings.dataPanelSettings.isIndustryOpen) {
        getIndustryData(null, industryIndex,
          'Industry', 'industry', industryMessageId as unknown as number, true);
      }
      if (!groupScreenerId && userSettings.dataPanelSettings.isGroupOpen) {
        getIndustryData(null, groupIndex,
          'Group', 'group', groupMessageId as unknown as number, true);
      }
      if (!sectorScreenerId && userSettings.dataPanelSettings.isSectorOpen) {
        getIndustryData(null, sectorIndex,
          'Sector', 'sector', sectorMessageId as unknown as number, true);
      }
      if (!subIndustryScreenerId && userSettings.dataPanelSettings.isSubIndustryOpen) {
        getIndustryData(null, subIndustryIndex,
          'Sub-Industry', 'subIndustry', subIndustryMessageId as unknown as number, true);
      }
      if (industryScreenerId && !userSettings.dataPanelSettings.isIndustryOpen) {
        Connection?.sendMessage(WS_CHANNELS.SCREENER_UNSUBSCRIBE,
          { messageId: industryMessageId, screenerId: gueryDataPanelIndustryRef?.current as number });
        dispatch(
          setIndustryQueryScreenerId({ data: null, name: industryDataScreenerIds[IndustryGroupNames.INDUSTRY] })
        );
      }
      if (groupScreenerId && !userSettings.dataPanelSettings.isGroupOpen) {
        Connection?.sendMessage(WS_CHANNELS.SCREENER_UNSUBSCRIBE,
          { messageId: groupMessageId, screenerId: gueryDataPanelGroupRef?.current as number });
        dispatch(setIndustryQueryScreenerId({ data: null, name: industryDataScreenerIds[IndustryGroupNames.GROUP] }));
      }
      if (sectorScreenerId && !userSettings.dataPanelSettings.isSectorOpen) {
        Connection?.sendMessage(WS_CHANNELS.SCREENER_UNSUBSCRIBE,
          { messageId: sectorMessageId, screenerId: gueryDataPanelSectorRef?.current as number });
        dispatch(setIndustryQueryScreenerId({ data: null, name: industryDataScreenerIds[IndustryGroupNames.SECTOR] }));
      }
      if (subIndustryScreenerId && !userSettings.dataPanelSettings.isSubIndustryOpen) {
        Connection?.sendMessage(WS_CHANNELS.SCREENER_UNSUBSCRIBE,
          { messageId: subIndustryMessageId, screenerId: gueryDataPanelSubIndustryRef?.current as number });
        dispatch(setIndustryQueryScreenerId(
          { data: null, name: industryDataScreenerIds[IndustryGroupNames.SUBINDUSTRY] }
        ));
      }
    }
  }, [
    userSettings.openDataPanel,
    userSettings.selectedDataPanelId,
    Socket?.readyState,
    Authorized,
    userSettings.dataPanelSettings.isGroupOpen,
    userSettings.dataPanelSettings.isSectorOpen,
    userSettings.dataPanelSettings.isIndustryOpen,
    userSettings.dataPanelSettings.isSubIndustryOpen
  ]);

  const onlineHandler = (): void => {
    dispatch(setDataPanelsQueryId(null));
  };

  const pingCallback = (resp: SocketPingResponse): void => {
    if (resp?.screenerId === gueryDataPanelRef?.current || resp?.screenerId === gueryDataPanelIndustryRef?.current
      || resp?.screenerId === gueryDataPanelSectorRef?.current || resp?.screenerId === gueryDataPanelGroupRef?.current
      || resp?.screenerId === gueryDataPanelSubIndustryRef?.current) {
      Connection?.sendMessage(
        WS_CHANNELS.PONG_PERIODICAL_DATA,
        {
          periodicalId: resp.screenerId,
          type: WS_PING_TYPES.SCREENER,
          socketId: Connection?.socketId
        }
      );
    }
  };

  useEffect(() => {
    window.addEventListener('online', onlineHandler);

    return () => {
      window.removeEventListener('online', onlineHandler);
    };
  }, []);

  useEffect(() => {
    if (!userSettings.openDataPanel) {
      cleanChanels();
    }
  }, [userSettings.openDataPanel,
    userSettings.dataPanelSettings.isGroupOpen,
    userSettings.dataPanelSettings.isSectorOpen,
    userSettings.dataPanelSettings.isIndustryOpen,
    userSettings.dataPanelSettings.isSubIndustryOpen
  ]);

  useEffect(() => {
    if (Socket?.readyState === WebSocket.OPEN
      && Authorized
      && userSettings.openDataPanel
    ) {
      Connection?.messageEmitter?.on(WS_CHANNELS.SCREENER_PATCH, dataCallback);
      Connection?.messageEmitter?.on(WS_CHANNELS.SCREENER_SUBSCRIBE, dataCallback);
      Connection?.messageEmitter.on(WS_CHANNELS.PING_PERIODICAL_DATA, pingCallback);
      Connection?.messageEmitter.on(WS_CHANNELS.SCREENER_RESPONSE_PARTIAL, dataPartialResponse);
    }
  }, [Socket?.readyState, Authorized, userSettings.openDataPanel]);

  useEffect(() => {
    setSymbol(currentSymbolHandler(isWatchList).index || 0);
  }, [lastScreenerSymbol, lastAlertsSymbol, lastDeepListSymbol, searchValue]);

  useEffect(() => {
    if (columnsListIds.length > 0 && dataPanelsQueryId) {
      getColumnsData();
    }
    if (userSettings.dataPanelSettings.isIndustryOpen && industryScreenerId) {
      getIndustryData(industryScreenerId as unknown as number, industryIndex,
        'Industry', 'industry', industryMessageId as unknown as number, false);
    }
    if (userSettings.dataPanelSettings.isGroupOpen && groupScreenerId) {
      getIndustryData(groupScreenerId as unknown as number, groupIndex,
        'Group', 'group', groupMessageId as unknown as number, false);
    }
    if (userSettings.dataPanelSettings.isSectorOpen && sectorScreenerId) {
      getIndustryData(sectorScreenerId as unknown as number, sectorIndex,
        'Sector', 'sector', sectorMessageId as unknown as number, false);
    }
    if (userSettings.dataPanelSettings.isSubIndustryOpen && subIndustryScreenerId) {
      getIndustryData(subIndustryScreenerId as unknown as number, subIndustryIndex,
        'Sub-Industry', 'subIndustry', subIndustryMessageId as unknown as number, false);
    }
  }, [columnsListIds, symbol, industryOfSymbols]);

  useEffect(() => {
    if (dataPanelsQueryId) {
      gueryDataPanelRef.current = dataPanelsQueryId;
    }
  }, [dataPanelsQueryId]);

  useEffect(() => {
    if (industryScreenerId) {
      gueryDataPanelIndustryRef.current = industryScreenerId;
    }
  }, [industryScreenerId]);

  useEffect(() => {
    if (sectorScreenerId) {
      gueryDataPanelSectorRef.current = sectorScreenerId;
    }
  }, [sectorScreenerId]);

  useEffect(() => {
    if (groupScreenerId) {
      gueryDataPanelGroupRef.current = groupScreenerId;
    }
  }, [groupScreenerId]);

  useEffect(() => {
    if (subIndustryScreenerId) {
      gueryDataPanelSubIndustryRef.current = subIndustryScreenerId;
    }
  }, [subIndustryScreenerId]);

  useEffect(() => {
    if (disconnectPopup || !Connection) {
      cleanChanels();
    }
  }, [disconnectPopup, Connection]);

  return {
    dataPoints: dataPointsData,
    screenerId: gueryDataPanelRef.current,
    symbol,
  };
};

export default useDataPanelConnection;
