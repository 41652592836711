import getInputArray from '../utilities/getInputArrayTs';
import { CustomIndicator, IContext, IPineStudyResult, LibraryPineStudy, PineJS, PineJSStd, RawStudyMetaInfoId, StudyInputType, StudyPlotType } from '../../charting_library/charting_library';

export default function larryWilliamsBlastOff(mPineJS: PineJS): CustomIndicator {
  return {
    name: 'DV - Larry Williams Blast Off',
    metainfo: {
      id: 'TL-lwblastoff@tv-basicstudies-1' as RawStudyMetaInfoId,
      shortDescription: 'DV - Larry Williams Blast Off',
      description: 'DV - Larry Williams Blast Off',
      is_price_study: false,
      isCustomIndicator: true,
      precision: 10,
      format: {
        type: 'inherit',
      },
      palettes: {
        palette_0: {
          colors: {
            0: {
              name: '',
            }
          },
          valToIndex: {
            0: 0
          }
        },
        palette_1: {
          colors: {
            0: {
              name: ''
            }
          },
          valToIndex: {
            0: 0
          }
        },
        palette_2: {
          colors: {
            0: {
              name: ''
            }
          },
          valToIndex: {
            0: 0
          }
        },
        palette_3: {
          colors: {
            0: {
              name: 'Center Line'
            },
            1: {
              name: 'Above Upper Threshold'
            },
            2: {
              name: 'Positive Bar Momentum'
            },
            3: {
              name: 'Negative Bar Momentum'
            },
            4: {
              name: 'Below Lower Threshold'
            }
          },
          valToIndex: {
            0: 0,
            1: 1,
            2: 2,
            3: 3,
            4: 4
          }
        },
        palette_4: {
          colors: {
            0: {
              name: ''
            }
          },
          valToIndex: {
            0: 0
          }
        }
      },
      inputs: [
        {
          defval: 34,
          id: 'in_0',
          min: 1,
          name: 'Bar Smoothing Period',
          type: 'integer' as StudyInputType.Integer
        },
        {
          defval: 9,
          id: 'in_1',
          min: 1,
          name: 'Line Smoothing Period',
          type: 'integer' as StudyInputType.Integer
        },
        {
          defval: 10,
          id: 'in_2',
          min: 0,
          name: 'Threshold Distance',
          type: 'integer' as StudyInputType.Integer
        },
      ],
      plots: [
        {
          id: 'plot_0',
          type: 'line' as StudyPlotType.Line,
        },
        {
          id: 'plot_1',
          palette: 'palette_0',
          target: 'plot_0',
          type: 'colorer' as StudyPlotType.Colorer
        },
        {
          id: 'plot_2',
          type: 'line' as StudyPlotType.Line
        },
        {
          id: 'plot_3',
          palette: 'palette_1',
          target: 'plot_2',
          type: 'colorer' as StudyPlotType.Colorer
        },
        {
          id: 'plot_4',
          type: 'line' as StudyPlotType.Line
        },
        {
          id: 'plot_5',
          palette: 'palette_2',
          target: 'plot_4',
          type: 'colorer' as StudyPlotType.Colorer
        },
        {
          id: 'plot_6',
          type: 'line' as StudyPlotType.Line
        },
        {
          id: 'plot_7',
          palette: 'palette_3',
          target: 'plot_6',
          type: 'colorer' as StudyPlotType.Colorer
        },
        {
          id: 'plot_8',
          type: 'line' as StudyPlotType.Line
        },
        {
          id: 'plot_9',
          palette: 'palette_4',
          target: 'plot_8',
          type: 'colorer' as StudyPlotType.Colorer
        }
      ],
      styles: {
        plot_0: {
          histogramBase: 0,
          isHidden: false,
          joinPoints: false,
          title: 'Upper Threshold',
        },
        plot_2: {
          histogramBase: 0,
          isHidden: false,
          joinPoints: false,
          title: 'Center Line'
        },
        plot_4: {
          histogramBase: 0,
          isHidden: false,
          joinPoints: false,
          title: 'Lower Threshold'
        },
        plot_6: {
          histogramBase: 0,
          isHidden: false,
          joinPoints: false,
          title: 'BOM'
        },
        plot_8: {
          histogramBase: 0,
          isHidden: false,
          joinPoints: false,
          title: 'Smoothed Line'
        }
      },
      defaults: {
        inputs: {
          in_0: 34,
          in_1: 9,
          in_2: 10,
        },
        palettes: {
          palette_0: {
            colors: {
              0: {
                color: '#4CAF50',
                style: 0,
                width: 1
              }
            }
          },
          palette_1: {
            colors: {
              0: {
                color: '#FFFFFF',
                style: 0,
                width: 1
              }
            }
          },
          palette_2: {
            colors: {
              0: {
                color: '#9C27B0',
                style: 0,

                width: 1
              }
            }
          },
          palette_3: {
            colors: {
              0: {
                color: '#FFFFFF',
                style: 0,
                width: 1
              },
              1: {
                color: '#00E676',
                style: 0,
                width: 1
              },
              2: {
                color: '#4CAF50',
                style: 0,
                width: 1
              },
              3: {
                color: '#9C27B0',
                style: 0,
                width: 1
              },
              4: {
                color: '#E040FB',
                style: 0,
                width: 1
              }
            }
          },
          palette_4: {
            colors: {
              0: {
                color: '#00BCD4',
                style: 0,
                width: 1
              }
            }
          }
        },
        styles: {
          plot_0: {
            color: '#2962ff',
            display: 15,
            linestyle: 0,
            linewidth: 1,
            plottype: 0,
            trackPrice: false,
            transparency: 0,
          },
          plot_2: {
            color: '#2962ff',
            display: 15,
            linestyle: 0,
            linewidth: 1,
            plottype: 0,
            trackPrice: false,
            transparency: 0
          },
          plot_4: {
            color: '#2962ff',
            display: 15,
            linestyle: 0,
            linewidth: 1,
            plottype: 0,
            trackPrice: false,
            transparency: 0
          },
          plot_6: {
            color: '#2962ff',
            display: 15,
            linestyle: 0,
            linewidth: 1,
            plottype: 5,
            trackPrice: false,
            transparency: 0
          },
          plot_8: {
            color: '#2962ff',
            display: 15,
            linestyle: 0,
            linewidth: 1,
            plottype: 0,
            trackPrice: false,
            transparency: 0
          }
        }
      }
    },
    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = (context) => {
        this._context = context;
      };
      this.main = (context, inputs): IPineStudyResult => {
        this._context = context;
        const [per, sigper, tdist] = getInputArray({
          inputs,
          length: 3
        });

        const open = mPineJS.Std.open(this._context);
        const high = mPineJS.Std.high(this._context);
        const low = mPineJS.Std.low(this._context);
        const close = mPineJS.Std.close(this._context);

        const [bom, sig] = BOM(per, sigper, close, open, high, low, mPineJS.Std, this._context);
        const uth = tdist
        const lth = -tdist

        // Color Conditions
        const bomcolor = (bom > 0 && bom < uth) ? 2 : (bom < 0 && bom > lth) ? 3 : (bom > uth) ? 1 : (bom < lth) ? 4 : 0;
        return [
          uth,
          0,
          0,
          0,
          lth,
          0,
          bom,
          bomcolor,
          sig,
          0,
        ];
      };
    }
  };
}

export const BOM = (t1: number, t2: number, close: number, open: number, high: number, low: number, mPineJS: PineJSStd, mContext: IContext): [number, number] => {
  const ocrng = close - open;
  const hlrng = high - low;
  const bo = ocrng / hlrng * 100;
  const bom = mPineJS.ema(mContext.new_var(bo), t1, mContext);
  const sig = mPineJS.ema(mContext.new_var(bom), t2, mContext);
  return [bom, sig];
};
