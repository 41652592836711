export const WS_EVENTS = {
  CONNECT: 'connect',
  CONNECTION: 'connection',
  DISCONNECT: 'disconnect',
  DATA: 'data',
  RECONNECT: 'reconnect',
  ERROR: 'connect_error',
  EXCEPTION: 'exception',
  REMOVE_FROMSERVER: 'RemoveConnect',
};

export const WS_CHANNELS = {
  CONNECTION_CHECK_PONG: 'Pong.Check',
  CONNECTION_CHECK_PING: 'Ping.Check',
  AUTHORIZED: 'Connect.Authorized',
  CHART_SUBSCRIBE: 'Chart.Subscribe',
  CHART_UNSUBSCRIBE: 'Chart.Unsubscribe',
  CHART_TICK: 'Chart.Tick',
  SCREENER_INDICES_LIST: 'Screener.IndicesList',
  SCREENER_SUBSCRIBE: 'Screener.Subscribe',
  SCREENER_PATCH: 'Screener.Patch',
  SCREENER_UNSUBSCRIBE: 'Screener.Unsubscribe',
  SCREENER_RESPONSE_ALL: 'Screener.ResponceAll',
  SCREENER_RESPONSE_PARTIAL: 'Screener.ResponcePartial',
  DATA_PANEL: 'DataPanel',
  SCREENER_GET_ETF: 'Symbol.GetETF',
  SYMBOL_DATA_BATCH: 'Symbols.GetDataBatch',
  PING_PERIODICAL_DATA: 'Ping.PeriodicalData',
  PONG_PERIODICAL_DATA: 'Pong.PeriodicalData',
  PING_AUTH: 'Ping.Auth',
  PONG_AUTH: 'Pong.Auth',
  CONNECTION_EXCEEDED: 'Connect.Exceeded',
  CLOSE_CONNECTION: 'Connect.Close',
  DEFINITIONS_SEND_UPDATE: 'Definitions.SendUpdate',
};

export const ADDRESS = {
  CHART: 'CHART',
  TABLE: 'TABLE',
  MULTI_CHART: 'MULTI_CHART',
  DATA_PANEL: 'DATA_PANEL',
  WIDGET: 'WIDGET'
};

export const CONNECTION_STATUS = {
  ONLINE: 'online',
};

export const EROR_MESSAGES = {
  LIMIT_EXCEEDED: 'Connection removed due to device limit exceeded',
};

export const WS_PING_TYPES = {
  SCREENER: 'screener',
  CHART: 'chart',
  AUTH: 'auth',
};
