// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import moment from 'moment-timezone';
import {
  DISABLED_FEATURES,
  HEIGHT,
  STUDIES_OVERRIDES,
  WIDTH,
  WATERMARK,
  PLUS_BUTTON,
  alertContextAction,
  FAVORITES_KEYS,
  ENABLED_FEATURES,
  AUTO_SETS_TOOLS,
  OVERRIDES_FONTS,
  OVERRIDES_GRID,
  SESSIONS,
  OVERRIDES,
  BUTTONS_KEYS,
  SYNC_CROSSHAIR,
  EXTENDED_SESSION_SETTINGS,
  REGULAR_SESSION_SETTINGS,
  ENABLED_FEATURES_WIDGET,
  CUSTOM_COLORS,
  SETTINGS_PROPERTIES,
} from '../constants/tvWidgetOptions';
import { FREE } from '../constants/screener';
import enhancedVolumeTs from '../indicators/enhancedVolumeTs/enhancedVolume';
import webbyRsi from '../indicators/webbyRsi/webbyRsi';
import webbyRsi2 from '../indicators/webbyRsi2/webbyRsi2';
import webby52 from '../indicators/webby52/webby52';
import webbyTight from '../indicators/webbyTight/webbyTight';
import rmv from '../indicators/rvm/relativeMeasuredVolatility';
import webbyRSLine from '../indicators/webbyRsLine/webbyRsLine';
import ripsterClouds from '../indicators/ripsterCloud/ripsterClouds';
import upDownVolRatio from '../indicators/upDownVolRatio/upDownVolRatio';
import webbyQuick from '../indicators/webbyQuick/webbyQuick';
import { IItemsProcessor, IGetContextMenu, IWidgetOptions } from './HelpersInterfaces';
import {
  ActionOptions,
  ActionsFactory,
  IActionVariant,
  Timezone
} from '../charting_library';
import netNewHighsLows from '../indicators/netNewHighsLows/netNewHighsLows';
import {
  ACTIVE_CHART_LAYOUT_ID,
  MOBILE_ACTIVE_CHART_LAYOUT_ID,
  RESET_CHART_VIEW,
  STORED_SESSION,
  STORED_SESSION_MOBILE
} from '../constants/storage';
import {
  convertResolutionTimeframe,
  layoutStructureValidator
} from '../components/ChartContainer/utils/tradingViewHelper';
import earningsLine from '../indicators/earningsLine/earningsLine';
import salesLine from '../indicators/salesLine/salesLine';
import technicalPatternsV2 from '../indicators/technicalPatternsV2/technicalPatternsV2';
import candlePattern from '../indicators/candlePattern/candlePattern';
import stageAnalysisV2 from '../indicators/stageAnalysisTs/stageAnalysisV2';
import mansfieldRs from '../indicators/mansfieldRS/mansfieldRs';
import { MarketSession } from '../components/MarketDropdown/types/MarketEnums';
import percentSmaEma from '../indicators/percentSmaEma/percentSmaEma';
import keyMovingAverages from '../indicators/keyMovingAverages/keyMovingAverages';
import { getFromLocalStorage } from './storageWorks';
import zangerVolumeRatio from '../indicators/zangerVolumeRatio/zangerVolumeRatio';
import dollarVolume from '../indicators/dollarVolume/dollarVolume';
import relativeStrengthLineV2 from '../indicators/relativeStrengthLineV2/relativeStrengthLineV2';
import saveLoadAdapterNew from '../services/DataFeedServices/saveLoadAdapterNew';
import quallamaggieMovingAverages from '../indicators/qullamaggieMovingAverages/qullamaggieMovingAverages';
import marketTrend from '../indicators/marketTrend/marketTrend';
import marketNavigator from '../indicators/marketNavigator/marketNavigator';
import ttmSqueeze from '../indicators/ttmSqueeze/ttmSqueeze';
import larryWilliamsBlastOff from '../indicators/larryWilliamsBlastOff/larryWilliamsBlastOff';

const itemsProcessor: IItemsProcessor = async (items, actionsFactory, alertAction) => {
  if (items.length <= 19) {
    // eslint-disable-next-line no-underscore-dangle
    const isRightClickMenu = items[0]._options.label === RESET_CHART_VIEW;
    const newSeparator = await actionsFactory.createSeparator();
    items.unshift(newSeparator);
    const alertContext = alertContextAction(() => alertAction(!isRightClickMenu)) as unknown as ActionOptions;
    const addAlertAction = await actionsFactory.createAction(alertContext);
    items.unshift(addAlertAction);
    return items;
  }
  return items;
};

const getContextMenu: IGetContextMenu = (profile, alertAction) => (profile?.role !== FREE ? {
  items_processor: (
    items: IActionVariant[],
    actionsFactory: ActionsFactory
  ) => itemsProcessor(items, actionsFactory, alertAction)
} : {});

const applyLayoutHelper = (layout: string): string => {
  if (layout && JSON.parse(layout)?.content) {
    return JSON.parse(JSON.parse(layout)?.content);
  }
  return '';
};

const isDrawingKey = (key: string) => key.includes('tool');

const filterDrawingKey = (inputObject: Record<string, string>): Record<string, string> => (
  Object.entries(inputObject).reduce((result, [key, value]) => (
    isDrawingKey(key) ? { ...result, [key]: value } : result
  ), {})
);

const getActiveChartLayoutId = () => (getFromLocalStorage('isWebView')
  ? MOBILE_ACTIVE_CHART_LAYOUT_ID
  : ACTIVE_CHART_LAYOUT_ID);

const postMessageToWebView = (item) => {
  const resolution = item?.content?.resolution || '1D';
  const message = JSON.stringify({ resolution: convertResolutionTimeframe(resolution) });
  window?.ReactNativeWebView?.postMessage(message);
};

const findLayoutById = (id, tvLayoutsList) => tvLayoutsList.find((layout) => layout?.content?.id === id);

const fetchAndValidateLayout = (existedItem) => {
  const layout = layoutStructureValidator(JSON.stringify(existedItem?.content));
  return {
    saved_data: applyLayoutHelper(layout),
    saved_data_meta_info: {
      name: existedItem?.content?.name,
      uid: existedItem?.content?.id,
    },
  };
};

const widgetOptions: IWidgetOptions = ({
  setLayoutId, setSymbol, symbol, interval, datafeed, errorHandler,
  alertAction, userProfile, theme,
  userFavorite, favoriteCallback,
  updateStoreHandler, userLastState, dashWidget, tvLayoutsList,
  isMobileWidget = false, saveChartLayoudId, updateCrosshairSync,
  successHandler, defaultLayoutId, setUpdateInstance
}) => {
  const lastStateSessionKey = isMobileWidget ? STORED_SESSION_MOBILE : STORED_SESSION;

  const checkExistingLayout = () => {
    const activeChartLayoutId = getActiveChartLayoutId();
    const userLayoutId = userLastState[activeChartLayoutId];
    const widgetLayoutId = dashWidget?.chartLayoutId;

    if (!userLayoutId && !widgetLayoutId && isMobileWidget) {
      return postMessageToWebView({});
    }

    const conditionId = widgetLayoutId && widgetLayoutId > 0 ? widgetLayoutId : userLayoutId;
    const existedItem = findLayoutById(conditionId || defaultLayoutId, tvLayoutsList);

    if (isMobileWidget) postMessageToWebView(existedItem || {});

    if (existedItem) {
      return fetchAndValidateLayout(existedItem);
    }

    updateStoreHandler(activeChartLayoutId, defaultLayoutId);
    return {};
  };

  const updateSessionStore = (session: string): void => {
    updateStoreHandler(isMobileWidget ? STORED_SESSION_MOBILE : STORED_SESSION, session);
  };

  const indicatorsArray = (PineJS) => (process.env.REACT_APP_ENVIRONMENT === 'staging' ? [
    enhancedVolumeTs(PineJS),
    webbyRsi(PineJS),
    webbyRsi2(PineJS),
    webby52(PineJS),
    webbyTight(PineJS),
    rmv(PineJS),
    ripsterClouds(PineJS),
    webbyRSLine(PineJS),
    upDownVolRatio(PineJS),
    webbyQuick(PineJS),
    netNewHighsLows(PineJS),
    technicalPatternsV2(PineJS),
    candlePattern(PineJS),
    stageAnalysisV2(PineJS),
    mansfieldRs(PineJS),
    percentSmaEma(PineJS),
    keyMovingAverages(PineJS),
    zangerVolumeRatio(PineJS),
    relativeStrengthLineV2(PineJS),
    dollarVolume(PineJS),
    earningsLine(PineJS),
    salesLine(PineJS),
    quallamaggieMovingAverages(PineJS),
    marketTrend(PineJS),
    marketNavigator(PineJS),
    ttmSqueeze(PineJS),
    larryWilliamsBlastOff(PineJS),
  ] : [
    enhancedVolumeTs(PineJS),
    webbyRsi(PineJS),
    webbyRsi2(PineJS),
    webby52(PineJS),
    webbyTight(PineJS),
    rmv(PineJS),
    ripsterClouds(PineJS),
    webbyRSLine(PineJS),
    upDownVolRatio(PineJS),
    webbyQuick(PineJS),
    stageAnalysisV2(PineJS),
    technicalPatternsV2(PineJS),
    netNewHighsLows(PineJS),
    mansfieldRs(PineJS),
    candlePattern(PineJS),
    percentSmaEma(PineJS),
    keyMovingAverages(PineJS),
    zangerVolumeRatio(PineJS),
    relativeStrengthLineV2(PineJS),
    dollarVolume(PineJS),
    earningsLine(PineJS),
    salesLine(PineJS),
    quallamaggieMovingAverages(PineJS),
    marketTrend(PineJS),
    marketNavigator(PineJS),
    ttmSqueeze(PineJS),
    larryWilliamsBlastOff(PineJS),
  ]);

  return {
    symbol,
    width: WIDTH as unknown as number,
    height: HEIGHT as unknown as number,
    datafeed,
    interval,
    theme,
    timezone: moment.tz.guess() as Timezone,
    container: dashWidget?.id
      ? `tv_chart_container_${dashWidget?.id}`
      : 'tv_chart_container',
    library_path: '/charting_library/',
    locale: 'en',
    disabled_features: DISABLED_FEATURES,
    enabled_features: isMobileWidget ? ENABLED_FEATURES_WIDGET : ENABLED_FEATURES,
    fullscreen: isMobileWidget,
    overrides: { ...OVERRIDES_FONTS, ...OVERRIDES, ...OVERRIDES_GRID },
    custom_font_family: 'Quicksand, sans-serif',
    loading_screen: { backgroundColor: '#F1F5FF', foregroundColor: '#789AF8' },
    studies_overrides: STUDIES_OVERRIDES,
    custom_indicators_getter: (PineJS) => Promise.resolve(indicatorsArray(PineJS)),
    save_load_adapter: saveLoadAdapterNew(
      errorHandler,
      updateStoreHandler,
      userLastState,
      setSymbol,
      setLayoutId,
      tvLayoutsList,
      dashWidget?.id,
      saveChartLayoudId,
      defaultLayoutId,
      setUpdateInstance,
      successHandler
    ),
    auto_save_delay: 1,
    custom_css_url: isMobileWidget ? 'tradingviewWidget.css' : 'tradingview.css',
    settings_adapter: {
      initialSettings: {
        'loadChartDialog.favorites': JSON.stringify(userFavorite?.layouts) || '',
        'StudyTemplates.quicks': JSON.stringify(userFavorite?.studyTemplates) || '',
        symbolWatermark: userLastState?.symbolWatermark || '',
        add_plus_button: userLastState?.add_plus_button || false,
        drawings: userLastState?.drawings || '',
        'chart.syncCrosshair': dashWidget?.crosshairSync || false,
        'BarsMarksContainer.visibile': userLastState['BarsMarksContainer.visibile'] || 'true',
        'NavigationButtons.visibility': userLastState['NavigationButtons.visibility'] || 'visibleOnMouseOver',
        'PaneButtons.visibility': userLastState['PaneButtons.visibility'] || 'visibleOnMouseOver',
        'PriceAxisAutoLogButtons.visibility': userLastState['PriceAxisAutoLogButtons.visibility'] || 'alwaysOn',
        time_hours_format: userLastState.time_hours_format || '12-hours',
        date_format: userLastState.date_format || 'dd-MM-yy',
        pickerCustomColors: userLastState?.pickerCustomColors || '',
        sessions: userLastState[lastStateSessionKey] === MarketSession.EXTENDED
          ? EXTENDED_SESSION_SETTINGS : REGULAR_SESSION_SETTINGS,
        ...filterDrawingKey(userLastState)
      },

      setValue: (key: string, value: string) => {
        if (key === SETTINGS_PROPERTIES) {
          const parsedValue = JSON.parse(value);
          updateSessionStore(parsedValue?.sessionId);
        }
        if (key === CUSTOM_COLORS) {
          updateStoreHandler(CUSTOM_COLORS, value);
        }
        if (key === WATERMARK) {
          updateStoreHandler(WATERMARK, value);
        }
        if (Object.values(FAVORITES_KEYS).includes(key)) {
          favoriteCallback(key, value);
        }
        if (key === PLUS_BUTTON) {
          updateStoreHandler(PLUS_BUTTON, value);
        }
        if (AUTO_SETS_TOOLS.includes(key)) {
          updateStoreHandler(key, value);
        }
        if (isDrawingKey(key)) {
          updateStoreHandler(key, value);
        }
        if (Object.values(BUTTONS_KEYS).includes(key)) {
          updateStoreHandler(key, value);
        }
        if (key === SESSIONS) {
          updateStoreHandler(SESSIONS, value);
        }
        if (key === SYNC_CROSSHAIR && dashWidget?.id) {
          updateCrosshairSync?.(value === 'true', dashWidget?.id);
        }
      },
      removeValue: () => { }
    },
    ...checkExistingLayout(),
    context_menu: getContextMenu(userProfile, alertAction),
    favorites: {
      intervals: userFavorite?.intervals,
      chartTypes: userFavorite?.chartTypes,
      drawingTools: userFavorite?.drawingTools,
      indicators: userFavorite?.indicators,
    },
  };
};

export default widgetOptions;
