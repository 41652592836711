import React, { useState, useEffect } from 'react';

import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from '../sass/DashboardsItemsDialog.module.scss';
import { TDashboardItemsData } from '../../../utils/Types';
import { MAX_LENGTH_COUNT } from '../../../constants/screener';
import { getLabelModal } from '../../../utils/helperNewScreenModal';
import Input from '../../Input/Input';
import Select from '../../Select/Select';
import RootStateTypes from '../../../store/RootStateTypes';
import { DialogItemDataTypes } from '../types/constants';
import TextField from '../../TextField/TextField';
import TextArea from '../../TextArea/TextArea';
import getNewTitle from '../../../utils/getDuplicateTitle';

type TDashboardItemExt = TDashboardItemsData & { folderId?: string | null };

interface IDialogContentDuplicate {
  onChange: (item: TDashboardItemExt) => void,
  item: TDashboardItemsData,
  isFirstDuplicate: boolean,
  theme: string,
  cbFolderId: string | null,
  createNewFolder: () => void,
  type: DialogItemDataTypes
}
const DialogContentDuplicate = ({
  onChange, item, theme, createNewFolder, cbFolderId, type, isFirstDuplicate
}: IDialogContentDuplicate): React.ReactElement => {
  const { t } = useTranslation();
  const [valueSelect, setValueSelect] = useState<string>('');
  const [description, setDescription] = useState(
    item?.data?.description || ''
  );
  const folders = useSelector((state: RootStateTypes) => state.newScreenState.folders, shallowEqual);
  const columnSetFolder = useSelector((state: RootStateTypes) => state.newScreenState.columnSetFolder, shallowEqual);
  const dataPanelsFolders = useSelector(
    (state: RootStateTypes) => state.newScreenState.dataPanelsFolders, shallowEqual
  );
  const deeplistFolders = useSelector((state: RootStateTypes) => state.watchlistState.folders, shallowEqual);
  const dashboardFolders = useSelector((state: RootStateTypes) => state.dashboardsState.dashboardFolders, shallowEqual);
  const screens = useSelector((state: RootStateTypes) => state.newScreenState.screens, shallowEqual);
  const columnSets = useSelector((state: RootStateTypes) => state.newScreenState.columnSets, shallowEqual);
  const dataPanels = useSelector(
    (state: RootStateTypes) => state.newScreenState.dataPanels, shallowEqual
  );
  const deeplists = useSelector((state: RootStateTypes) => state.watchlistState.tabsList, shallowEqual);
  const dashboards = useSelector((state: RootStateTypes) => state.dashboardsState.dashboards, shallowEqual);
  const currentItem = item as TDashboardItemExt;

  const handleChanges = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onChange({
      ...currentItem,
      title: e.target.value,
      folderId: valueSelect,
      data: {
        ...currentItem.data,
        description
      }
    });
  };

  const handleSelect = (value: string): void => {
    setValueSelect(value);
    onChange({
      ...currentItem,
      folderId: value
    });
  };

  const addFolderHandler = (): void => {
    createNewFolder();
  };

  useEffect(() => {
    if (cbFolderId) {
      setValueSelect(cbFolderId);
    }
  }, [cbFolderId]);

  const getData = () => {
    switch (type) {
      case (DialogItemDataTypes.DEEP_LIST): {
        return deeplistFolders;
      }
      case (DialogItemDataTypes.SCREENER): {
        return folders;
      }
      case (DialogItemDataTypes.DATA_PANEL): {
        return dataPanelsFolders;
      }
      case (DialogItemDataTypes.DASHBOARD): {
        return dashboardFolders;
      }
      case (DialogItemDataTypes.COLUMN_SET): {
        return columnSetFolder;
      }
      default: return folders;
    }
  };
  const getItems = () => {
    switch (type) {
      case (DialogItemDataTypes.DEEP_LIST): {
        return deeplists;
      }
      case (DialogItemDataTypes.SCREENER): {
        return screens;
      }
      case (DialogItemDataTypes.DATA_PANEL): {
        return dataPanels;
      }
      case (DialogItemDataTypes.DASHBOARD): {
        return dashboards;
      }
      case (DialogItemDataTypes.COLUMN_SET): {
        return columnSets;
      }
      default: return screens;
    }
  };

  useEffect(() => {
    if (cbFolderId) {
      onChange({
        ...currentItem,
        folderId: cbFolderId
      });
    }
  }, [cbFolderId]);

  useEffect(() => {
    const newTitle = getNewTitle(currentItem, getItems());
    if (newTitle && newTitle !== currentItem?.title && isFirstDuplicate) {
      onChange({
        ...currentItem,
        title: newTitle,
      });
    }
  }, []);

  return (
    <div className={`${styles.dialogContent} ${theme}`}>
      <div className={`${styles.dialogContentFieldItem} ${styles.cleanBoarder}`}>
        <label className={styles.dialogContentFieldItemLabel} htmlFor="folderName">
          {getLabelModal(currentItem?.type, t)}
        </label>
        <Input
          type="text"
          autoFocus
          inputClass={styles.dialogContentFieldItemInput}
          handlerChange={handleChanges}
          placeholder={t('upOnVolume')}
          value={currentItem?.title || ''}
          maxLength={MAX_LENGTH_COUNT}
        />
      </div>
      <div className={styles.dialogContentFieldItem}>
        <Select
          wrapperClass={styles.dialogContentFieldItemSelect}
          title={t('folder')}
          valueSelect={valueSelect}
          setValueSelect={handleSelect}
          data={getData()}
          placeholder={t('selectedFolder')}
          addFolderHandler={addFolderHandler}
        />
      </div>
      <div className={styles.dialogContentInputWrapper}>
        <TextField text={t('description')} styleType="createFolderModalBody" />
        <TextArea
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.currentTarget.value)}
          value={description}
        />
      </div>
    </div>
  );
};

export default DialogContentDuplicate;
