import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import HeaderModal from '../HederModal/HederModal';
import styles from './sass/VisibilitySetting.module.scss';
import listVisibilitySetting from '../../lists/listVisibilitySetting';
import SwitcherItem from '../SwitcherItem/SwitcherItem';

const VisibilitySetting = ({
  text, closeModal, active, setActiveHandler
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      closeModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      ref.current = null;
    };
  }, []);

  return useMemo(() => {
    return (
      <div className={styles.container} ref={ref}>
        <HeaderModal closeModal={() => closeModal(false)} text={text} />
        {listVisibilitySetting.map((item, index) => (
          <SwitcherItem
            id={item.title}
            active={active[item.title]}
            Icon={item.icon}
            setActive={setActiveHandler}
            text={t(`${item.title}`)}
            key={[item.title, index].join('_')}
          />
        ))}
      </div>
    );
  }, [active, setActiveHandler]);
};

VisibilitySetting.propTypes = {
  text: PropTypes.string.isRequired,
  active: PropTypes.shape({}).isRequired,
  setActiveHandler: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default VisibilitySetting;
