import moment from 'moment-timezone';
import { DEFAULT_TIMEZONE } from './account';
import { ALERT_TV_SHORT_CUTS } from './shortcuts';

import brushIcon from '../assets/images/icons/tvIcons/drawing.svg';
import calendarIcon from '../assets/images/icons/tvIcons/calendar.svg';
import alertIcon from '../assets/images/icons/tvIcons/alert.svg';
import dataPanelIcon from '../assets/images/icons/tvIcons/dataPanel.svg';
import dataPanelIconWhite from '../assets/images/icons/tvIcons/dataPanel_white.svg';
import statsChartIcon from '../assets/images/icons/tvIcons/statsChart.svg';
import statsChartIconWhite from '../assets/images/icons/tvIcons/statsChart_white.svg';

export const SYMBOL = 'symbol';
export const STOCKS = 'stocks';
export const SESSION = '0930-1600';
export const EXTENDED_SESSION = '0400-2000';
export const MIN_MOV = 1;
export const PRICE_SCALE = 100;
export const WIDTH = '100%';
export const HEIGHT = '100%';
export const SECOND = 1000;

export const NORMALIZE_RESOLUTIONS = [
  '1m', '3m', '5m', '10m', '15m', '30m', '39m', '60m', '65m', '78m',
  '120m', '130m', '195m', '240m', '1d', '1w', '1M', '3M', '6M', '12M'
];

export const SUPPORTED_RESOLUTIONS = [
  '1', '3', '5', '10', '15', '30', '39', '60', '65', '78',
  '120', '130', '195', '240', '1D', '1W', '1M', '3M', '6M', '12M'
];

export const ADAPTER_RESOLUTIONS = [
  '1m', '5m', '15m', '39m', '1h', '65m', '78m', '1d', '1w', '1M'
];

export const OVERRIDES_FONTS = {
  editorFontsList: ['Quicksand', 'Source Sans', 'Verdana', 'Courier New', 'Times New Roman', 'Arial'],
};

export const TV_ACTIONS = ['chartProperties', 'compareOrAdd', 'scalesProperties', 'paneObjectTree', 'insertIndicator',
  'symbolSearch', 'changeInterval', 'timeScaleReset', 'chartReset', 'seriesHide', 'studyHide', 'lineToggleLock',
  'lineHide', 'scaleSeriesOnly', 'drawingToolbarAction', 'stayInDrawingModeAction', 'hideAllMarks', 'showCountdown',
  'showSeriesLastValue', 'showSymbolLabelsAction', 'showStudyLastValue', 'showStudyPlotNamesAction', 'undo', 'redo',
  'paneRemoveAllStudiesDrawingTools', 'showSymbolInfoDialog', 'gotoDate'
];

export const OVERRIDES_GRID = {
  'paneProperties.vertGridProperties.color': 'rgba(255,255,255,0.24)',
  'paneProperties.horzGridProperties.color': 'rgba(236,241,252,0.24)',
};

export const OVERRIDES = {
  'mainSeriesProperties.candleStyle.upColor': '#3A75E8',
  'mainSeriesProperties.candleStyle.downColor': '#F55BAE',
  'mainSeriesProperties.candleStyle.borderUpColor': '#3A75E8',
  'mainSeriesProperties.candleStyle.borderDownColor': '#F55BAE',
  'mainSeriesProperties.candleStyle.wickUpColor': '#3A75E8',
  'mainSeriesProperties.candleStyle.wickDownColor': '#F55BAE',

  'mainSeriesProperties.hollowCandleStyle.upColor': '#3A75E8',
  'mainSeriesProperties.hollowCandleStyle.downColor': '#F55BAE',
  'mainSeriesProperties.hollowCandleStyle.borderUpColor': '#3A75E8',
  'mainSeriesProperties.hollowCandleStyle.borderDownColor': '#F55BAE',
  'mainSeriesProperties.hollowCandleStyle.wickUpColor': '#3A75E8',
  'mainSeriesProperties.hollowCandleStyle.wickDownColor': '#F55BAE',

  'mainSeriesProperties.haStyle.upColor': '#3A75E8',
  'mainSeriesProperties.haStyle.downColor': '#F55BAE',
  'mainSeriesProperties.haStyle.borderUpColor': '#3A75E8',
  'mainSeriesProperties.haStyle.borderDownColor': '#F55BAE',
  'mainSeriesProperties.haStyle.wickUpColor': '#3A75E8',
  'mainSeriesProperties.haStyle.wickDownColor': '#F55BAE',

  'mainSeriesProperties.barStyle.upColor': '#3A75E8',
  'mainSeriesProperties.barStyle.downColor': '#F55BAE',

  'mainSeriesProperties.lineStyle.color': '#3A75E8',

  'mainSeriesProperties.areaStyle.color1': 'rgba(33, 150, 243, 0.28)',
  'mainSeriesProperties.areaStyle.color2': '#3A75E8',
  'mainSeriesProperties.areaStyle.linecolor': '#3A75E8',

  'mainSeriesProperties.baselineStyle.baselineColor': 'rgba( 117, 134, 150, 1)',
  'mainSeriesProperties.baselineStyle.topFillColor2': 'rgba( 58, 117, 232, 0.28)',
  'mainSeriesProperties.baselineStyle.topFillColor1': 'rgba( 245, 91, 174, 0.05)',
  'mainSeriesProperties.baselineStyle.bottomFillColor2': 'rgba( 58, 117, 232, 0.05)',
  'mainSeriesProperties.baselineStyle.bottomFillColor1': 'rgba( 245, 91, 174, 0.28)',
  'mainSeriesProperties.baselineStyle.topLineColor': 'rgba( 58, 117, 232, 1)',
  'mainSeriesProperties.baselineStyle.bottomLineColor': 'rgba( 245, 91, 174, 1)'
};

export const STUDIES_OVERRIDES = {
  'volume.volume.color.0': 'rgba( 245, 91, 174, 0.5)',
  'volume.volume.color.1': 'rgba( 58, 117, 232, 0.5)',
};

export const DISABLED_FEATURES = [
  'header_symbol_search',
  'header_screenshot',
  'timeframes_toolbar',
  'display_market_status',
  'symbol_search_hot_key',
  'trading_account_manager',
  'widget_logo',
  'popup_hints',
  'right_toolbar',
  'border_around_the_chart',
  'hide_last_na_study_output',
  'request_only_visible_range_on_reset',
  'save_chart_properties_to_local_storage'
];

export const ENABLED_FEATURES = [
  'hide_left_toolbar_by_default',
  'side_toolbar_in_fullscreen_mode',
  'header_in_fullscreen_mode',
  'chart_style_hilo',
  'chart_style_hilo_last_price',
  'show_spread_operators',
  'compare_symbol_search_spread_operators',
  'studies_symbol_search_spread_operators',
  'show_percent_option_for_right_margin',
  'symbol_info_price_source',
  'low_density_bars',
  'study_symbol_ticker_description',
  'confirm_overwrite_if_chart_layout_with_name_exists',
  'study_templates',
  'secondary_series_extend_time_scale',
  'chart_template_storage',
  'dont_show_boolean_study_arguments',
  'drawing_templates',
  'pre_post_market_sessions',
  'studies_extend_time_scale'
];

export const ENABLED_FEATURES_WIDGET = [
  'hide_left_toolbar_by_default',
  'chart_style_hilo',
  'chart_style_hilo_last_price',
  'show_spread_operators',
  'compare_symbol_search_spread_operators',
  'studies_symbol_search_spread_operators',
  'show_percent_option_for_right_margin',
  'symbol_info_price_source',
  'low_density_bars',
  'study_symbol_ticker_description',
  'confirm_overwrite_if_chart_layout_with_name_exists',
  'study_templates',
  'secondary_series_extend_time_scale',
  'chart_template_storage',
  'dont_show_boolean_study_arguments',
  'drawing_templates',
  'pre_post_market_sessions',
  'studies_extend_time_scale'
];

export const ADDITIONAL_DISABLED_FEATURES = [
  'header_widget',
  'left_toolbar',
  'mouse_wheel_scroll',
  'show_zoom_and_move_buttons_on_touch',
  'vert_touch_drag_scroll',
  'horz_touch_drag_scroll',
  'chart_zoom',
  'hide_left_toolbar_by_default',
  'side_toolbar_in_fullscreen_mode',
  'header_in_fullscreen_mode',
  'chart_style_hilo',
  'chart_style_hilo_last_price',
  'show_spread_operators',
  'compare_symbol_search_spread_operators',
  'studies_symbol_search_spread_operators',
  'context_menus',
  'show_percent_option_for_right_margin',
  'symbol_info_price_source',
  'low_density_bars',
  'study_symbol_ticker_description',
  'confirm_overwrite_if_chart_layout_with_name_exists',
  'study_templates',
  'secondary_series_extend_time_scale',
  'chart_template_storage',
  'dont_show_boolean_study_arguments',
  'drawing_templates',
];

export const ENABLED_FOR_MULTICHART = [
  'pre_post_market_sessions'
];

export const BUTTON_SETTINGS = {
  BUTTON_LIST: ['brush', 'calendar'],
  BUTTON_LIST_SHORT: ['brush', 'calendar'],
  IMG_ELEMENT: 'IMG',
  SRC_ATTRIBUTE: 'src',
  CLASS: 'class',
  TITLE_ATTRIBUTE: 'title',
  EVENT: 'click',
  BACK: 'Back',
  ACTION_ID_DATE: 'gotoDate',
  ACTION_ID_DRAW: 'drawingToolbarAction',
  TITLE_DATE: 'Go to date',
  TITLE_DRAW: 'Show/Hide Drawings Toolbar',
  ITEM_NAME: 'calendar',
  STYLE_ATTRIBUTE: 'style',
};

export const BUTTON_IMAGES = {
  calendar: calendarIcon,
  brush: brushIcon,
  alert: alertIcon,
  dataPanel: dataPanelIcon,
  dataPanelActive: dataPanelIconWhite,
  statsChart: statsChartIcon,
  statsChartActive: statsChartIconWhite,
};

export const BUTTON_TITLES = {
  calendar: 'Go to date',
  brush: 'Show/Hide Drawings Toolbar',
  alert: 'Add alert',
  dataPanel: 'Data Panel',
  statsChart: 'Stats Table'
};

export const RESOLUTIONS = {
  [SUPPORTED_RESOLUTIONS[0]]: {
    resolution: ADAPTER_RESOLUTIONS[0]
  },
  [SUPPORTED_RESOLUTIONS[1]]: {
    resolution: ADAPTER_RESOLUTIONS[0]
  },
  [SUPPORTED_RESOLUTIONS[2]]: {
    resolution: ADAPTER_RESOLUTIONS[1]
  },
  [SUPPORTED_RESOLUTIONS[3]]: {
    resolution: ADAPTER_RESOLUTIONS[1]
  },
  [SUPPORTED_RESOLUTIONS[4]]: {
    resolution: ADAPTER_RESOLUTIONS[2]
  },
  [SUPPORTED_RESOLUTIONS[5]]: {
    resolution: ADAPTER_RESOLUTIONS[2]
  },
  [SUPPORTED_RESOLUTIONS[6]]: {
    resolution: ADAPTER_RESOLUTIONS[3]
  },
  [SUPPORTED_RESOLUTIONS[7]]: {
    resolution: ADAPTER_RESOLUTIONS[4]
  },
  [SUPPORTED_RESOLUTIONS[8]]: {
    resolution: ADAPTER_RESOLUTIONS[5]
  },
  [SUPPORTED_RESOLUTIONS[9]]: {
    resolution: ADAPTER_RESOLUTIONS[6]
  },
  [SUPPORTED_RESOLUTIONS[10]]: {
    resolution: ADAPTER_RESOLUTIONS[4]
  },
  [SUPPORTED_RESOLUTIONS[11]]: {
    resolution: ADAPTER_RESOLUTIONS[5]
  },
  [SUPPORTED_RESOLUTIONS[12]]: {
    resolution: ADAPTER_RESOLUTIONS[5]
  },
  [SUPPORTED_RESOLUTIONS[13]]: {
    resolution: ADAPTER_RESOLUTIONS[4]
  },
  [SUPPORTED_RESOLUTIONS[14]]: {
    resolution: ADAPTER_RESOLUTIONS[7]
  },
  [SUPPORTED_RESOLUTIONS[15]]: {
    resolution: ADAPTER_RESOLUTIONS[8]
  },
  [SUPPORTED_RESOLUTIONS[16]]: {
    resolution: ADAPTER_RESOLUTIONS[9]
  },
  [SUPPORTED_RESOLUTIONS[17]]: {
    resolution: ADAPTER_RESOLUTIONS[9]
  },
  [SUPPORTED_RESOLUTIONS[18]]: {
    resolution: ADAPTER_RESOLUTIONS[9]
  },
  [SUPPORTED_RESOLUTIONS[19]]: {
    resolution: ADAPTER_RESOLUTIONS[9]
  },
  default: {
    resolution: ADAPTER_RESOLUTIONS[0]
  }
};

export const refreshTime = moment().tz(DEFAULT_TIMEZONE).startOf('day');
refreshTime.set('hour', 9);
refreshTime.set('minute', 30);
refreshTime.set('second', 5);

export const ALERT_ICON = `<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28">
<g fill="none" fill-rule="evenodd" stroke="currentColor">
<path stroke-linecap="square" d="M21.5 14.5a8 8 0 1 0-8 8m6-6v8m-4-4h8"></path>
<path d="M10 14.5h3.5V9m-3.277-1.76a3 3 0 1 0-3.993 3.979m14.565-.012a3 3 0 1 0-4.019-3.966"></path>
</g></svg>`;

export const alertContextAction = (action) => ({
  actionId: 'addDeepAlert',
  active: 'true',
  label: 'Add alert',
  disabled: false,
  subItems: [],
  checkable: false,
  checked: false,
  hint: navigator?.userAgent?.indexOf('Mac OS X') !== -1 ? ALERT_TV_SHORT_CUTS.MAC : ALERT_TV_SHORT_CUTS.WINDOWS,
  icon: ALERT_ICON,
  onExecute: (e) => action(e)
});

export const DEFAULT_COUNT_BACK = 500;

export const GET_BARS_SETTINGS = {
  COLUMNS: ['timestamp', 'open', 'close', 'high', 'low', 'volume'],
  MARKET_TYPES: ['regular'],
  MARKET_TYPES_EXTENDED: ['pre', 'regular', 'post'],
};

export const CUSTOM_COLORS = 'pickerCustomColors';
export const WATERMARK = 'symbolWatermark';
export const CHART_PROPERTIES = 'chartproperties';
export const SOLID = 'solid';
export const SYMBOL_LOCK = 'symbolLock';
export const SIDEBAR_TOGGLE = 'sidebarToggle';
export const CHART_CURSOR = 'cursorPreference';
export const LAYOUT_CHANGED = 'layout_changed';
export const LOAD_LAYOUT = 'loadLayout';
export const DRAWING_TOOLBAR = 'drawingToolbarAction';
export const SCREENER_LAYOUT = 'screenerLayout';
export const OPEN_DATA_PANEL = 'openDataPanel';
export const ROW_STYLE = 'activeRowStyle';
export const FAVOURITE_INTERVALS = 'intervals';
export const FAVOURITE_INDICATORS = 'indicators';
export const FAVOURITE_STUDY_TEMPLATE = 'studyTemplates';
export const FAVOURITE_LAYOUTS = 'layouts';
export const FAVOURITE_DRAWING_TOOLS = 'drawingTools';
export const FAVOURITE_CHART_TYPE = 'chartTypes';
export const DATA_PANEL_GRID_TYPE = 'dataPanelGridType';
export const SELECTED_TAB_ID = 'selectedTabId';
export const CHART_FAVOURITES_MAIN = 'chartFavorites';
export const PANE_PROPERTIES_TYPE = 'paneProperties.backgroundType';
export const PANE_PROPERTIES_BACKGROUND = 'paneProperties.background';
export const DRAWINGS = 'drawings';
export const SCREENER_SORTING = 'screenerSorting';
export const LAST_SCREENER_COLUMNS_SET = 'lastScreenerColumnsSet';
export const LAST_RESOLUTION = 'lastResolution';
export const RIGHT_OFFSET = 'rightOffset';
export const RIGHT_OFFSET_BAR_SPACING = 'rightOffsetBarSpacing';
export const DEFINE_TIMESCALE_MARK = 'BarsMarksContainer.visibile';
export const PRICE_AXIS = 'PriceAxisAutoLogButtons.visibility';
export const DATE_FORMAT = 'date_format';
export const TIME_FORMAT = 'time_hours_format';
export const SETTINGS_PROPERTIES = 'chartproperties.mainSeriesProperties';

export const SESSIONS = 'sessions';
export const SYNC_CROSSHAIR = 'chart.syncCrosshair';

export const FAVORITES_KEYS = {
  INTERVALS: 'IntervalWidget.quicks',
  CHART_TYPES: 'StyleWidget.quicks',
  DRAWING_TOOLS: 'chart.favoriteDrawings',
  PRICE_AXIS: 'priceAxisProperties',
  INDICATORS: 'chart.favoriteLibraryIndicators',
  TEMPLATES: 'StudyTemplates.quicks',
  LAYOUTS: 'loadChartDialog.favorites'
};

export const BUTTONS_KEYS = {
  PANE_BUTTONS: 'PaneButtons.visibility',
  NAVIGATION_BUTTONS: 'NavigationButtons.visibility',
};

export const DEFAULT_FAVORITE_OBJ = {
  intervals: [],
  chartTypes: [],
  drawingTools: []
};

export const PLUS_BUTTON = 'add_plus_button';

export const CHART_TYPES_KEYS = {
  0: 'Bars',
  1: 'Candles',
  2: 'Line',
  3: 'Area',
  4: 'Renko',
  5: 'Kagi',
  6: 'Point & figure',
  7: 'Line Break',
  8: 'Heiken Ashi',
  9: 'Hollow Candles',
  10: 'Baseline',
  12: 'High-low',
  13: 'Columns',
  14: 'LineWithMarkers',
  15: 'Stepline',
  16: 'HLC area'
};

export const MARK_MARKER_KEYS = {
  SPLIT_1: 'splitFactor',
  SPLIT_2: 'splitRatio',
  DIV_1: 'dividendType',
  DIV_2: 'currency',
  ERN_1: 'eps',
  ERN_2: 'sales'
};

export const MARK_TYPES = {
  DIVIDEND: 'dividend',
  SPLIT: 'split',
  EARNINGS: 'earnings',
};

export const ANNUAL_RESOLUTIONS = ['1W', '1M', '3M', '6M', '12M'];

export const AUTO_SETS_TOOLS = [
  DRAWINGS,
  PRICE_AXIS,
  DATE_FORMAT,
  TIME_FORMAT,
  DEFINE_TIMESCALE_MARK,
];

export const SUBSESSIONS_ARRAY = [
  {
    description: 'Regular Trading Hours',
    id: 'regular',
    session: '0930-1600'
  },
  {
    description: 'Extended Trading Hours',
    id: 'extended',
    session: '0400-2000'
  },
  {
    description: 'Premarket',
    id: 'premarket',
    session: '0400-0930'
  },
  {
    description: 'Postmarket',
    id: 'postmarket',
    session: '1600-2000'
  }
];

export const SUBSESSIONS = {
  REGULAR: 'regular',
  EXTENDED: 'extended',
};

export const EXTENDED_SESSION_SETTINGS = {
  graphics: {
    backgrounds:
      {
        outOfSession: { color: '#2962FF', transparency: 92, visible: true },
        preMarket: { color: '#FF9800', transparency: 92, visible: true },
        postMarket: { color: '#2962FF', transparency: 92, visible: true }
      },
    vertlines: {
      sessBreaks: {
        color: '#4985e7', style: 2, visible: false, width: 1
      }
    }
  }
};

export const REGULAR_SESSION_SETTINGS = {
  graphics: {
    backgrounds:
      {
        outOfSession: { color: '#2962FF', transparency: 92, visible: false },
        preMarket: { color: '#FF9800', transparency: 92, visible: false },
        postMarket: { color: '#2962FF', transparency: 92, visible: false }
      },
    vertlines: {
      sessBreaks: {
        color: '#4985e7', style: 2, visible: false, width: 1
      }
    }
  }
};
