import { CSSProperties } from 'react';
import { TDashboardItemsData } from '../../../utils/Types';

export const subitemHeight = 52;

const getDeltaWidth = (
  anchorRect: DOMRect, itemList: TDashboardItemsData[],
): number => {
  const { innerWidth } = window;
  const isNeedBiggerRight = itemList.length < 6;

  if (anchorRect?.right + anchorRect.width + 47 > innerWidth) {
    return -(anchorRect.width + 47 - (isNeedBiggerRight ? 6 : 0));
  }
  return anchorRect.width + (isNeedBiggerRight ? 0 : 6);
};

export const getPositionForSubitems = (
  anchor: HTMLElement,
  itemsNum: number,
  itemList: TDashboardItemsData[],
): CSSProperties => {
  const anchorRect = anchor.getBoundingClientRect();
  const numHeight = itemsNum <= 4 ? itemsNum : 4;
  const position = {
    top: anchorRect.top,
    left: anchorRect.left + getDeltaWidth(anchorRect as DOMRect, itemList),
    minHeight: subitemHeight * numHeight,
  };
  return position;
};
