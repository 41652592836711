import { IContext, PineJSStd } from "../../../charting_library/charting_library";

export function computeTLTechnicals(open: number, high: number, low: number, close: number, mPineJSStd: PineJSStd, mcontext: IContext) {
  const closeSeries = mcontext.new_unlimited_var(close);
  const highSeries = mcontext.new_unlimited_var(high);
  const lowSeries = mcontext.new_unlimited_var(low);
  const ssEmaSeries = mcontext.new_unlimited_var(mPineJSStd.ema(mcontext.new_unlimited_var(high), 4, mcontext))

  const oopsReversal = open < lowSeries.get(1) && close > lowSeries.get(1);
  const outsideBullishDay = open < lowSeries.get(1) && close > highSeries.get(1);
  const outsideBearishDay = open > highSeries.get(1) && close < lowSeries.get(1);
  const upsideReversal = (low < lowSeries.get(1)) && (close > (high + low) / 2);
  const slingShot = closeSeries.get(0) > ssEmaSeries.get(0) && closeSeries.get(1) < ssEmaSeries.get(1) && closeSeries.get(2) < ssEmaSeries.get(2) && closeSeries.get(3) < ssEmaSeries.get(3);

  const insideDay = lowSeries.get(0) > lowSeries.get(1) && highSeries.get(0) < highSeries.get(1);

  return {
    oopsReversal,
    outsideBullishDay,
    outsideBearishDay,
    upsideReversal,
    slingShot,
    insideDay
  };
}

export function checkThreeWeeksTight(close: number, mcontext: IContext) {
  const closeSeries = mcontext.new_unlimited_var(close);
  return (
    Math.abs(close / closeSeries.get(1) - 1) <= 0.015
    && Math.abs(closeSeries.get(1) / closeSeries.get(2) - 1) <= 0.015
    && Math.abs(closeSeries.get(2) / closeSeries.get(3) - 1) <= 0.015
  );
}

export function calculateRMV(lookback_period: number, mPineJSStd: PineJSStd, mcontext: IContext) {
  // lookback_period = 2 // lookback period to calculate the volatility of the past 2 bars
  // Calculate the highest and lowest of the past three bar highs,closes,lows
  const highest_high = mPineJSStd.highest(mcontext.new_var(mPineJSStd.high(mcontext)), 2, mcontext);
  const lowest_high = mPineJSStd.lowest(mcontext.new_var(mPineJSStd.high(mcontext)), 2, mcontext);

  const highest_close = mPineJSStd.highest(mcontext.new_var(mPineJSStd.close(mcontext)), 2, mcontext);
  const lowest_close = mPineJSStd.lowest(mcontext.new_var(mPineJSStd.close(mcontext)), 2, mcontext);

  const highest_low = mPineJSStd.highest(mcontext.new_var(mPineJSStd.low(mcontext)), 2, mcontext);
  const lowest_low = mPineJSStd.lowest(mcontext.new_var(mPineJSStd.low(mcontext)), 2, mcontext);

  // Calculate the percent change between each of these highs and lows
  const percent_changeH = ((highest_high - lowest_high) / lowest_close) * 100 // percent change between highs
  const percent_changeC = ((highest_close - lowest_close) / lowest_close) * 100 // percent changes between closes
  const percent_changeL = ((highest_low - lowest_low) / lowest_low) * 100 // percent changes between lows

  const RMV1 = (percent_changeH + (1.5 * percent_changeC) + percent_changeL) / 3 // average percent changes

  const highest_high1 = mPineJSStd.highest(mcontext.new_var(mPineJSStd.high(mcontext)), 3, mcontext);
  const lowest_high1 = mPineJSStd.lowest(mcontext.new_var(mPineJSStd.high(mcontext)), 3, mcontext);

  const highest_close1 = mPineJSStd.highest(mcontext.new_var(mPineJSStd.close(mcontext)), 3, mcontext);
  const lowest_close1 = mPineJSStd.lowest(mcontext.new_var(mPineJSStd.close(mcontext)), 3, mcontext);

  // Calculate the percent change
  const percent_changeH1 = ((highest_high1 - lowest_high1) / lowest_close1) * 100;
  const percent_changeC1 = 1.5 * ((highest_close1 - lowest_close1) / lowest_close1) * 100;

  const RMV2 = (percent_changeH1 + percent_changeC1) / 2
  const RMVt = (3 * RMV1 + RMV2) / 4 //calculate the overall volatillity and weight the first higher

  const hs = mPineJSStd.highest(mcontext.new_var(RMVt), lookback_period, mcontext); //get highest volatility in the period
  const ls = mPineJSStd.lowest(mcontext.new_var(RMVt), lookback_period, mcontext); // get lowest volatility in the period

  const RMVstoc = ((RMVt - ls) / (hs - ls)) * 100 // calculate the stochastic of the volatility ove rthe range - this is the final plotted value
  return RMVstoc;

}